import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import axios from "./AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import "../assets/styles/App.css";
import { CSSTransition } from "react-transition-group";

function Profile() {
    const { axiosConfigured, setAlert } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        userName: "",
        userEmail: "",
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });
    const [loading, setLoading] = useState(false);
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const navigate = useNavigate();
    const [showButtons, setShowButtons] = useState(false);
    const hasFetchedData = useRef(false);
    const [inProp, setInProp] = useState(false);

    const loadProfile = useCallback(async () => {
        try {
            const response = await axios.get(`/api/users/profile`);
            setFormData({
                userName: response.data.userName,
                userEmail: response.data.userEmail,
                currentPassword: "",
                newPassword: "",
                confirmNewPassword: "",
            });
        } catch (error) {
            console.error("Erro ao carregar dados do perfil:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current) {
            setLoading(true);
            loadProfile();
            hasFetchedData.current = true;
        }
    }, [axiosConfigured, loadProfile]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            return { ...prevData, [name]: value };
        });
    };

    const handleChangePassword = (e) => {
      setShowPasswordFields(!showPasswordFields);

      if (!showPasswordFields) {
        setFormData(prevData => ({
          ...prevData,
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        }));
      }
    }

    const handleSave = async (e) => {
        e.preventDefault();

        // Validade name
        if (formData.userName?.trim() === '') {
          setAlert({ type: 'danger', message: 'Informe seu nome' });
          return;
        }

        // Validate passwords
        if (showPasswordFields) {
            if (formData.newPassword.length < 6) {
                setAlert({ type: 'danger', message: 'A nova senha precisa ter pelo menos 6 caracteres' });
                return;
            }
            if (!formData.currentPassword || !formData.newPassword || !formData.confirmNewPassword) {
              setAlert({ type: 'danger', message: 'Preencha todos os campos de senha' });
              return;
            }
            if (formData.newPassword !== formData.confirmNewPassword) {
                setAlert({ type: 'danger', message: 'As senhas não coincidem' });
                return;
            }
        }

        const payload = {
            userName: formData.userName,
            userEmail: formData.userEmail,
            ...(showPasswordFields && {
                currentPassword: formData.currentPassword,
                newPassword: formData.newPassword,
                confirmNewPassword: formData.confirmNewPassword
            }),
        };

        try {
            await axios.put("/api/users/profile", payload);
            setAlert({ type: "success", message: "Dados atualizados com sucesso!" });
            setShowPasswordFields(false);
        } catch (error) {
            //setAlert({ type: "danger", message: "Erro ao atualizar seus dados!" });
            //console.error(error);
        }
    };

    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate("/home");
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setShowButtons(true)}>
            <div>
                <div className="form-limited-width mt-3">
                    {loading ? (
                        <div className="spinner-container">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={handleSave}>
                            <h3 className="mb-3">Editar Perfil</h3>
                            <div className="mb-3 p-3 border rounded">
                                <h5>Informações do Perfil</h5>
                                <div className="mb-3">
                                    <label className="form-label">E-mail</label>
                                    <input
                                        type="text"
                                        className="form-control readonly-style"
                                        name="userEmail"
                                        value={formData.userEmail || ""}
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Nome <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="userName"
                                        value={formData.userName || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-">
                                    <Link onClick={handleChangePassword}>{!showPasswordFields? 'Alterar senha': 'Cancelar alteração de senha'}</Link>
                                </div>
                            </div>

                            {showPasswordFields && (
                                <div className="mb-3 p-3 border rounded">
                                    <h5>Alterar Senha</h5>
                                    <div className="mb-3">
                                        <label className="form-label">Senha Atual <span className="text-danger">*</span></label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="currentPassword"
                                            value={formData.currentPassword || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Nova Senha <span className="text-danger">*</span></label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="newPassword"
                                            value={formData.newPassword || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Confirmar Nova Senha <span className="text-danger">*</span></label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="confirmNewPassword"
                                            value={formData.confirmNewPassword || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            )}
                        </form>
                    )}
                </div>

                <div className={`button-bar ${showButtons? 'show' : ''}`}>
                    <button
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={handleSave}>
                        <FontAwesomeIcon icon={faSave} /> Salvar
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                        Cancelar
                    </button>
                </div>
            </div>
        </CSSTransition>
    );
}

export default Profile;
