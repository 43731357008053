import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from './AxiosInstance';
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from './AuthContext';
import '../assets/styles/App.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import loginBackground from '../assets/images/register.webp';
import logo from '../assets/images/logo-white.webp';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const { logout, auth, setAuth, axiosConfigured } = useContext(AuthContext);
  const navigate = useNavigate();
  const hasFetched = useRef(false);

  const verifySessionAndNavigateToHome = useCallback(async () => {
    try {
      await axios.get('/api/users/verify');
      navigate('/home');
    } catch (error) {
      logout(false, false);
    }
  }, [logout, navigate]);

  useEffect(() => {
    if (axiosConfigured && !hasFetched.current) {
      hasFetched.current = true;
      if (auth.token) {
        verifySessionAndNavigateToHome();
      }
    }
  }, [verifySessionAndNavigateToHome, axiosConfigured, hasFetched, auth.token]);

  useEffect(() => {
    const storedEmail = localStorage.getItem('rememberedEmail');
    const sessionExpired = localStorage.getItem('sessionExpired');
    const offline = localStorage.getItem('offline');

    // Verifica se há um e-mail armazenado e preenche o campo
    if (storedEmail) {
      setEmail(storedEmail);
      setRememberMe(true);
    }

    // Verifica se a sessão expirou e exibe a mensagem uma única vez
    if (sessionExpired === 'true') {
      setErrorMessage('Sessão expirada. Por favor, faça login novamente.');
      localStorage.removeItem('sessionExpired');
    }

    if (offline === 'true') {
      setErrorMessage('Serviço indisponível. Por favor, tente novamente mais tarde.');
      localStorage.removeItem('offline');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Limpa mensagem de erro ao tentar login
    setLoading(true); // Define estado de carregamento

    try {
      // Armazena o e-mail se a opção "lembrar-me" estiver marcada
      if (rememberMe) {
        localStorage.setItem('rememberedEmail', email);
      } else {
        localStorage.removeItem('rememberedEmail');
      }

      // Faz a requisição de login
      const response = await axios.post('/api/users/login', { email, password });
      const token = response.data.token;
      const navigateUrl = response.data.navigateUrl;

      localStorage.setItem('token', token); // Armazena o token

      // Decodifica o token JWT
      const decodedToken = jwtDecode(token);
      setAuth({
        token,
        roles: decodedToken.roles || [],
        loginUser: decodedToken.loginUser || '',
        cellPhone: decodedToken.cellPhone || '',
        userName: decodedToken.userName || '',
        companyId: decodedToken.companyId || '',
        companyName: decodedToken.companyName || '',
        expired: false,
        intentionalLogout: false,
      });

      // Redireciona para a URL de navegação após o login
      navigate(navigateUrl);

    } catch (error) {
      setAuth((prevAuth) => ({ ...prevAuth, expired: false }));
      // Verifica os erros da requisição e define a mensagem de erro apropriada
      if (error.response) {
        if (error.response.status === 400 && error.response.headers['x-error-message']) {
          setErrorMessage(error.response.headers['x-error-message']);
        } else if (error.response.status === 500) {
          setErrorMessage('Erro interno do servidor. Por favor, tente novamente mais tarde.');
        } else if (error.response.status === 503) {
          setErrorMessage('Serviço indisponível. Por favor, tente novamente mais tarde.');
        } else {
          setErrorMessage('Falha no login. Por favor, verifique suas credenciais.');
        }
      } else if (error.request) {
        setErrorMessage(JSON.stringify(error));
        //setErrorMessage('Serviço indisponível. Por favor, tente novamente mais tarde.');
      } else {
        setErrorMessage('Erro desconhecido. Por favor, tente novamente.');
      }
    } finally {
      setLoading(false); // Termina o estado de carregamento
    }
  };

  const isLoginDisabled = !email || !password;

  return (
    <section className="vh-100">
      <div className="container-login py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: '1rem', height: '100%' }}>
              <div className="row g-0 h-100">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src={loginBackground}
                    alt="login form"
                    className="img-fluid h-100"
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    {loading ? (
                      <div className="spinner-container">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="centered-logo-container">
                            <img
                              src={logo}
                              alt="Simpletize"
                              className="img-fluid"
                              style={{ width: "44px", height: "auto" }}
                            />Simpletize
                        </div>

                        <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: '1px' }}>Entre na sua conta</h5>

                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="txtEmail">E-mail</label>
                          <input
                            type="email"
                            id="txtEmail"
                            className="form-control form-control-lg"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off"
                            required
                            maxLength={60}
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="txtPassword">Senha</label>
                          <input
                            type="password"
                            id="txtPassword"
                            className="form-control form-control-lg"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="new-password"
                            required
                            maxLength={20}
                          />
                        </div>

                        <div className="form-check mb-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rememberMe"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor="rememberMe">Lembrar meu usuário</label>
                        </div>

                        <div className="pt-1 mb-4">
                          <button id="btnLogin" className="btn btn-dark btn-lg btn-block" type="submit" disabled={isLoginDisabled}>Entrar</button>
                        </div>

                        <Link to="/recovery" className="small text-muted">Esqueceu sua senha?</Link>
                        <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>Não tem uma conta? 
                          <Link to="/register" style={{ color: '#393f81', marginLeft: '2px' }}>Registre aqui</Link>
                        </p>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
