import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { AuthContext } from './AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import { Paginator } from 'primereact/paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CSSTransition } from 'react-transition-group';

function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
        setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        // Limpa o listener quando o componente for desmontado
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
}

function SupplierInvoice() {
    const { axiosConfigured } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const { supplier, searchTerm: searchTermFromEdit, page, rows, sortField, sortOrder } = location.state || {};

    const [invoices, setInvoices] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(page || 0);
    const [currentRows, setCurrentRows] = useState(rows || 10);
    const [searchTerm, setSearchTerm] = useState('');
    const hasFetched = useRef(false);
    const [inProp, setInProp] = useState(false);
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;

    const fetchInvoices = useCallback(async (page, rows, searchTerm) => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/suppliers/${supplier.id}/invoices`, {
                params: {
                    page: page,
                    size: rows,
                    search: searchTerm,
                },
            });

            const invoicesData = response.data.content || [];

            if (Array.isArray(invoicesData)) {
                const formattedInvoices = invoicesData.map((invoice) => ({
                    ...invoice,
                    issueDate: invoice.issueDate ? new Date(invoice.issueDate).toLocaleDateString('pt-BR') : 'N/A',
                    invoiceAmount: invoice.invoiceAmount ? invoice.invoiceAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A',
                    items: Array.isArray(invoice.items) ? invoice.items.map((item) => ({
                        ...item,
                        produto: item.produto || 'N/A',
                        quantity: item.quantity || 'N/A',
                        value: item.value ? item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A',
                        unitMeasurement: item.unitMeasurement ? item.unitMeasurement.initials : 'N/A'
                    })) : [],
                    billingInstallments: Array.isArray(invoice.billingInstallments) ? invoice.billingInstallments.map((installment) => ({
                        ...installment,
                        dueDate: installment.dueDate ? new Date(installment.dueDate).toLocaleDateString('pt-BR') : 'N/A',
                        amount: installment.amount ? installment.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A',
                    })) : [],
                    paymentMethods: Array.isArray(invoice.paymentMethods) ? invoice.paymentMethods.map((paymentMethod) => ({
                        ...paymentMethod,
                        paymentType: paymentMethod.paymentType || '',
                        paymentMethod: paymentMethod.paymentMethod || '',
                        paymentDate: paymentMethod.paymentDate ? new Date(paymentMethod.paymentDate).toLocaleDateString('pt-BR') : '',
                        amount: paymentMethod.amount ? paymentMethod.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A',
                    })) : [],
                }));
                setInvoices(formattedInvoices);
            } else {
                setInvoices([]);
            }
            setTotalRecords(response.data.totalElements || 0);
        } catch (error) {
            console.error('Erro ao buscar notas fiscais', error);
        } finally {
            setLoading(false);
        }
    }, [supplier.id]);

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            setLoading(true);
            fetchInvoices(currentPage, currentRows, searchTerm);
            hasFetched.current = true;
        }
    }, [axiosConfigured, currentPage, currentRows, searchTerm, fetchInvoices]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const toggleRowExpansion = (invoiceId) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(invoiceId)
                ? prevExpandedRows.filter((id) => id !== invoiceId)
                : [...prevExpandedRows, invoiceId]
        );
    };

    const isRowExpanded = (invoiceId) => expandedRows.includes(invoiceId);

    const handlePageChange = (event) => {
        setCurrentPage(event.page);
        setCurrentRows(event.rows);
        fetchInvoices(event.page, event.rows, searchTerm);
    };

    const handleSearch = () => {
        setCurrentPage(0);
        fetchInvoices(0, currentRows, searchTerm);
    };

    const handleBackToList = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/suppliers', {
                state: {
                    searchTerm: searchTermFromEdit,
                    page: page,
                    rows: rows,
                    sortField: sortField,
                    sortOrder: sortOrder,
                },
            });
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear>
            <div className='container'>
                <h3 className="mb-4">Notas Fiscais de {supplier?.legalName}</h3>

                <div className="d-flex justify-content-end mb-4">
                    <div className="input-group search-size" style={{ maxWidth: '300px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                        />
                        <button className="btn btn-outline-secondary btn-search" onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>

                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        {invoices.length > 0 ? (
                            <>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Número</th>
                                            <th>Data</th>
                                            <th>Valor</th>
                                            <th className='text-end'>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoices.map((invoice) => (
                                            <React.Fragment key={invoice.id}>
                                                <tr>
                                                    <td>{invoice.number || 'N/A'}</td>
                                                    <td>{invoice.issueDate}</td>
                                                    <td>{invoice.invoiceAmount}</td>
                                                    <td className='text-end'>
                                                        <button className="btn btn-sm border-secondary custom-btn" title="Ver Notas Fiscais" onClick={() => toggleRowExpansion(invoice.id)}>
                                                            <FontAwesomeIcon icon={isRowExpanded(invoice.id) ? faChevronUp : faChevronDown} />
                                                        </button>
                                                    </td>
                                                </tr>
                                                {isRowExpanded(invoice.id) && (
                                                    <tr>
                                                        <td colSpan="4">
                                                            <div className="p-3 border rounded bg-light">
                                                                <h5>Itens da Nota</h5>
                                                                <ul>
                                                                    {invoice.items && invoice.items.length > 0 ? (
                                                                        invoice.items.map((item, index) => (
                                                                            <li key={index}>
                                                                                {item.produto} - {item.quantity} {item.unitMeasurement} x {item.value}
                                                                            </li>
                                                                        ))
                                                                    ) : (
                                                                        <li>Sem itens</li>
                                                                    )}
                                                                </ul>

                                                                <h5 className="mt-3">Forma de Pagamento</h5>
                                                                <ul>
                                                                    {invoice.paymentMethods && invoice.paymentMethods.length > 0 ? (
                                                                        invoice.paymentMethods.map((payment, index) => (
                                                                            <li key={index}>
                                                                                {payment.paymentType && `Tipo: ${payment.paymentType}`}
                                                                                {payment.paymentMethod && payment.paymentType && ' - '}
                                                                                {payment.paymentMethod && `Método: ${payment.paymentMethod}`}
                                                                                {payment.amount && (payment.paymentType || payment.paymentMethod) && ' - '}
                                                                                {payment.amount && `Valor: ${payment.amount}`}
                                                                            </li>
                                                                        ))
                                                                    ) : (
                                                                        <li>Sem métodos de pagamento</li>
                                                                    )}
                                                                </ul>

                                                                {invoice.billingInstallments && invoice.billingInstallments.length > 0 && (
                                                                    <>
                                                                        <h5 className="mt-3">Parcelas</h5>
                                                                        <ul>
                                                                            {invoice.billingInstallments.map((installment, index) => (
                                                                                <li key={index}>
                                                                                    Parcela {installment.installmentNumber}:<br />
                                                                                    {installment.dueDate ? `Vencimento: ${installment.dueDate}` : 'Sem data'} - 
                                                                                    {installment.amount}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>

                                <Paginator
                                    first={currentPage * currentRows}
                                    rows={currentRows}
                                    totalRecords={totalRecords}
                                    onPageChange={handlePageChange}
                                    pageLinkSize={isMobile ? 2 : 5}
                                    {...(!isMobile && { rowsPerPageOptions: [10, 20, 50] })}
                                />
                            </>
                        ) : (
                            <div className="no-data">
                                <p>Ainda não há notas fiscais para este fornecedor, ou sua pesquisa não retornou resultados.</p>
                            </div>
                        )}
                    </>
                )}

                <button className="btn btn-secondary mt-4" onClick={handleBackToList}>
                    Voltar para Lista de Fornecedores
                </button>
            </div>
        </CSSTransition>
    );
}

export default SupplierInvoice;
