import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import AsyncSelect2 from './AsyncSelect2'; // Importa o componente AsyncSelect2
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/App.css';
import { CSSTransition } from 'react-transition-group';

function ItemGroupEdit() {
    const { setAlert, axiosConfigured, auth, hasRequiredRoleDfe, hasRequiredRoleEstoque } = useContext(AuthContext);
    const havePermission = (hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_USER') || hasRequiredRoleEstoque(auth.roles, 'ROLE_ESTOQUE_USER')) && !auth.readOnlyMode;
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        active: true,
        parentGroup: null,
    });
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [selectedParentGroup, setSelectedParentGroup] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { itemGroup: initialItemGroup, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    const hasFetchedData = useRef(false);
    const [itemGroup, setItemGroup] = useState(initialItemGroup || {});
    const [inProp, setInProp] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const loadingTimeout = useRef(null);

    const loadItemGroup = useCallback(async () => {
        try {
            const response = await axios.get(`/api/items/groups/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar grupo de item:', error);
            if (error.response && error.response.status === 404) {
                navigate('/items/groups', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, page, rows, searchTerm, sortField, sortOrder]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadItemGroup();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setShowForm(true); // Mostrar o formulário para novos itens
        }
    }, [axiosConfigured, id, loadItemGroup]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const initializeForm = (itemGroup) => {
        setItemGroup(itemGroup);
        setFormData({
            name: itemGroup.name,
            description: itemGroup.description,
            active: itemGroup.active,
            parentGroup: itemGroup.parentGroup ? itemGroup.parentGroup.id : null,
        });
        setSelectedParentGroup(itemGroup.parentGroup ? {
            label: itemGroup.parentGroup.name,
            value: itemGroup.parentGroup.id,
        } : null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            return { ...prevData, [name]: value };
        });
    };

    const handleParentGroupChange = (selectedOption) => {
        const selectedGroup = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.label,
        } : null;
        
        setSelectedParentGroup(selectedOption);
        setFormData((prevData) => {
            return { ...prevData, parentGroup: selectedGroup?.id || null };
        });
    };

    const validateRequiredFields = () => {
        const errors = {};

        if (!formData.name) errors.name = true;

        const countErros = Object.keys(errors).length;

        if (countErros > 0) {
            setAlert({ type: 'danger', message: 'Campos obrigatórios não preenchidos' });
        }

        //console.log(errors);
        
        return errors;
    };

    const handleSave = async () => {
        setIsSaving(true);

        try {
            const errors = validateRequiredFields();
    
            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
                return;
            }
        
            setFormErrors({});

            const dataToSend = {
                ...formData,
                parentGroup: selectedParentGroup ? {
                    id: selectedParentGroup.value,
                } : null
            };
    
            if (itemGroup.id) {
                await axios.put(`/api/items/groups/${itemGroup.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Grupo de item editado com sucesso.' });
            } else {
                await axios.post('/api/items/groups', dataToSend);
                setAlert({ type: 'success', message: 'Grupo de item adicionado com sucesso.' });
            }
            setInProp(false);
            setTimeout(() => {
                navigate('/items/groups', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            console.error('Erro ao salvar grupo de item', error);
        } finally {
            setTimeout(() => {
                setIsSaving(false);
            }, 1000);
        }
    };
    
    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/items/groups', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setShowButtons(true)}>
            <div>
                <div className="form-limited-width mt-3">
                    {loading ? (
                        <div className="spinner-container">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : showForm ? (
                        <div>
                            <h3 className="mb-3">{itemGroup.id ? (havePermission? 'Editar Grupo de Item': 'Grupo de Item') : 'Novo Grupo de Item'}</h3>
                            <form>
                                <div className="mb-3 p-3 border rounded">
                                    <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                                    <div className={formErrors.name? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Nome <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            value={formData.name || ''}
                                            onChange={handleInputChange}
                                            required
                                            maxLength={60}
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Grupo Pai</label>
                                        <AsyncSelect2
                                            url="/api/items/groups"
                                            value={selectedParentGroup}
                                            onChange={handleParentGroupChange}
                                            valueField="id"
                                            labelField="name"
                                            placeholder="Selecione o Grupo Pai"
                                            initialOptions={selectedParentGroup ? [selectedParentGroup] : []}
                                            labelFormatter={(item) => `${item.name}`}
                                            showClear
                                            onlyActive={true}
                                            isDisabled={!havePermission}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Descrição</label>
                                        <textarea
                                            className="form-control"
                                            name="description"
                                            value={formData.description || ''}
                                            onChange={handleInputChange}
                                            rows="4"
                                            maxLength={255}
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                    <div className="mb-0">
                                        <label className="form-label">Ativo <span className="text-danger">*</span></label>
                                        <select 
                                            className="form-select"
                                            name="active"
                                            value={formData.active}
                                            onChange={handleInputChange}
                                            disabled={!havePermission}>
                                            <option value={true}>Sim</option>
                                            <option value={false}>Não</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    ) : null}
                </div>

                <div className={`button-bar ${showButtons && showForm? 'show' : ''}`} title={!havePermission ? "Sem permissão": ""}>
                    <div className="button-bar-container">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={handleSave}
                            disabled={!havePermission || isSaving}>
                            {isSaving && (<FontAwesomeIcon icon={faSpinner} spin className='me-2' />)}<FontAwesomeIcon icon={faSave} /> Salvar
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}

export default ItemGroupEdit;
