import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faRefresh, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Paginator } from 'primereact/paginator';
import { CSSTransition } from 'react-transition-group';
import AlertModal from './AlertModal';
import '../assets/styles/InvoiceStatus.css';

function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
        setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        // Limpa o listener quando o componente for desmontado
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
}

function Billing() {
    const {setAlert, logout, axiosConfigured } = useContext(AuthContext);
    const [billings, setBillings] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [sortField] = useState('subscriptionEndDate');
    const [sortOrder] = useState(2);
    const [loading, setLoading] = useState(false);
    const hasFetched = useRef(false);
    const location = useLocation();
    const [inProp, setInProp] = useState(false);
    const [showInfoErrorModal, setShowInfoErrorModal] = useState(null);
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;
    
    const formatStatus = (status) => {
        const statusEnumMap = {
            "PAGO": { label: "Pagamento recebido", className: "status-autorizada" },
            "ATRASADO": { label: "Pagamento em atraso", className: "status-com-erro" },
            "PENDENTE": { label: "Pagamento pendente", className: "status-cancelada" }
        };
    
        return statusEnumMap[status] || { label: "STATUS DESCONHECIDO", className: "status-desconhecido" };
    };

    const fetchBillings = useCallback(async (page, rows, sortField, sortOrder, searchTerm) => {
        setLoading(true);
        try {
            const response = await axios.get('/api/billings', {
                params: {
                    search: searchTerm,
                    page: page,
                    size: rows,
                    sort: `${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`,
                },
            });
            const billingData = response.data.content || [];
            setBillings(billingData);
            setTotalRecords(response.data.totalElements || 0);
        } catch (error) {
            if (error.code === 'ERR_NETWORK') {
                logout(false, true);
            }
            console.error('Erro ao buscar cobranças', error);
        } finally {
            setLoading(false);
        }
    }, [logout]);

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            fetchBillings(page, rows, sortField, sortOrder, '');
            hasFetched.current = true;
        }
    }, [fetchBillings, axiosConfigured, location.state, page, rows, sortField, sortOrder]);

    const handleRefresh = () => {
        fetchBillings(page, rows, sortField, sortOrder, '');
    };

    const onPageChange = (event) => {
        setPage(event.page);
        setRows(event.rows);
        fetchBillings(event.page, event.rows, sortField, sortOrder, '');
    };

    const viewInvoice = (billing) => {
        if (billing && billing.invoiceUrl) {
            window.open(billing.invoiceUrl, '_blank');
        }
    };

    const viewBoleto = async (billing) => {
        if (billing && billing.id) {
            try {
                const response = await axios.get(`/api/billings/${billing.id}/boleto`, {
                    responseType: 'blob',
                });
    
                // Abre o PDF em uma nova aba
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                
                // Abre a nova aba
                const newWindow = window.open();
                if (newWindow) {
                    // Escreve um HTML básico na nova aba com um botão para download
                    newWindow.document.write(`
                        <!DOCTYPE html>
                        <html lang="en">
                        <head>
                            <title>BOLETO</title>
                            <style>
                                body {
                                    margin: 0;
                                    font-family: Arial, sans-serif;
                                    display: flex;
                                    flex-direction: column;
                                    height: 100vh;
                                    overflow: hidden;
                                }
                                iframe {
                                    flex-grow: 1;
                                    width: 100%;
                                    border: none;
                                }
                                .download-bar {
                                    background: #f8f9fa;
                                    padding: 10px;
                                    text-align: right;
                                    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
                                }
                                .download-button {
                                    background: #007BFF;
                                    color: white;
                                    padding: 10px 15px;
                                    text-decoration: none;
                                    border-radius: 5px;
                                    font-size: 14px;
                                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                                    transition: background-color 0.3s ease;
                                }
                                .download-button:hover {
                                    background: #0056b3;
                                }
                            </style>
                        </head>
                        <body>
                            <iframe src="${url}"></iframe>
                            <div class="download-bar">
                                <a href="${url}" download="boleto.pdf" class="download-button">Download</a>
                            </div>
                        </body>
                        </html>
                    `);
                    newWindow.document.close();                    
                } else {
                    alert('Permita pop-ups para visualizar o boleto.');
                }
    
                // Revoga a URL após um tempo para liberar memória
                // COMENTADO, POIS O NAVEGADOR LIBERARÁ A MEMÓRIA AO FECHAR A ABA
                /*setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                }, 10000);*/
            } catch (error) {
                console.error('Erro ao obter o boleto:', error);
                alert('Não foi possível obter o boleto. Tente novamente mais tarde.');
            }
        }
    };
    
    const reprocessCreditCardPayment = async (billing) => {
        if (billing && billing.id) {
            try {
                await axios.post(`/api/billings/${billing.id}/reprocess`, {});
                fetchBillings(page, rows, sortField, sortOrder, '');
                setAlert({ type: 'success', message: 'Pagamento processado com sucesso' });
            } catch (error) {
                setAlert({ type: 'danger', message: 'Pagamento processado com erro' });
            }
        }
    }

    const handleShowInfoErrorModal = (billing) => {
        const error = {
            reason: billing.reason
        }

        setShowInfoErrorModal(error);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setInProp(true)}>
            <div className='container'>
                <h3 className="mb-3 mt-3">Pagamentos e Faturas</h3>

                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div></div>
                    <div className="text-end" style={{ maxWidth: '300px' }}>
                        <button className="btn btn-outline-secondary btn-search" onClick={handleRefresh}>
                            <FontAwesomeIcon icon={faRefresh} />
                        </button>
                    </div>
                </div>

                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : billings.length > 0 ? (
                    <>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Período</th>
                                    <th className='d-none d-lg-table-cell'>Forma de pagamento</th>
                                    <th className='d-none d-lg-table-cell'>Vencimento</th>
                                    <th className='d-none d-lg-table-cell'>Data do pagamento</th>
                                    <th className='d-none d-md-table-cell text-end me-20'>Valor R$</th>
                                    <th className='text-end me-20'>Status</th>
                                    <th className='text-end'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                            {billings.map((billing) => (
                                    <tr key={billing.id}>
                                        <td>{`${new Date(billing.subscriptionStartDate).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}`} até {`${new Date(billing.subscriptionEndDate).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}`}</td>
                                        <td className="d-none d-lg-table-cell">{billing.paymentMethod === 'BOLETO'? 'Boleto': (billing.paymentMethod === 'CREDIT_CARD'? 'Cartão de crédito': 'PIX')}</td>
                                        <td className="d-none d-lg-table-cell">{new Date(billing.dueDate).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</td>
                                        <td className="d-none d-lg-table-cell">{billing.paymentDate? new Date(billing.paymentDate).toLocaleDateString('pt-BR', { timeZone: 'UTC' }): 'N/A'}</td>
                                        <td className="d-none d-md-table-cell text-end me-20">{billing.totalAmount ? billing.totalAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) : 'N/A'}</td>
                                        <td className="text-end me-20">
                                            <span className={formatStatus(billing.billingStatus).className} onClick={() => billing.reason && billing.billingStatus !== 'PAGO'? handleShowInfoErrorModal(billing): ''}>
                                                {formatStatus(billing.billingStatus).label}
                                                {billing.reason !== null  && billing.billingStatus !== 'PAGO' && (
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        className="ms-2 text-warning"
                                                        onClick={() => handleShowInfoErrorModal(billing)}
                                                    />
                                                )}
                                            </span>
                                        </td>
                                        <td className='text-end'>
                                            <div className="dropdown d-inline">
                                                <button className="btn btn-sm border-secondary custom-btn dropdown-toggle no-dropdown-icon" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <FontAwesomeIcon icon={faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end">
                                                    <li>
                                                        <button className="dropdown-item" onClick={() => viewBoleto(billing)} disabled={billing.paymentMethod !== 'BOLETO'}>Boleto</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item" onClick={() => viewInvoice(billing)} disabled={!billing.invoiceUrl}>Fatura</button>
                                                    </li>
                                                    {billing.paymentMethod === 'CREDIT_CARD' && billing.billingStatus !== 'PAGO' && (
                                                        <>
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => reprocessCreditCardPayment(billing)}>Tentar novamente</button>
                                                            </li>
                                                        </>
                                                    )}
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                        <Paginator
                            first={page * rows}
                            rows={rows}
                            totalRecords={totalRecords}
                            onPageChange={onPageChange}
                            pageLinkSize={isMobile ? 3 : 5}
                            {...(!isMobile && { rowsPerPageOptions: [10, 20, 50] })}
                        />
                    </>
                ) : (
                    <div className="no-data">
                        <p>Ainda não há dados por aqui.</p>
                    </div>
                )}

                <AlertModal
                    show={!!showInfoErrorModal}
                    onHide={() => setShowInfoErrorModal(null)}
                    title="Erro ao processar o pagamento"
                    message={
                        showInfoErrorModal && (
                            <>
                                <p><strong>Motivo:</strong> {showInfoErrorModal.reason}</p>
                                <p><strong>Instruções:</strong><br/><br/>Acesse o menu: <strong>{' [Administração >> Conta/Loja >> Informações de Pagamento] '}</strong><br/><br/>Confira os dados do seu cartão. Você também pode alterar a forma de pagamento para boleto.</p>
                            </>
                        )
                    }
                />
            </div>
        </CSSTransition>
    );
}

export default Billing;
