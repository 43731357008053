import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from './AxiosInstance';
import { InputMask } from 'primereact/inputmask';
import '../assets/styles/App.css';
import registerBackground from '../assets/images/register.webp';

function Register() {
  const [companyType, setCompanyType] = useState('PESSOA_JURIDICA');
  const [companyName] = useState('');
  const [companyLegalName, setCompanyLegalName] = useState('');
  const [companyCNPJ, setCompanyCNPJ] = useState('');
  const [cpf, setCpf] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userConfirmPassword, setUserConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const [stage, setStage] = useState('1');
  const [existsUser, setExistsUser] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]/g, '');
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
  
    let sum, rest;
    sum = 0;
    for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(cpf.substring(9, 10))) return false;
  
    sum = 0;
    for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) rest = 0;
    return rest === parseInt(cpf.substring(10, 11));
  };
  
  const validateCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]/g, '');
    if (cnpj.length !== 14) return false;
  
    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;
  
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
  
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) return false;
  
    length = length + 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;
  
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
  
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    return result === parseInt(digits.charAt(1));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage('');

    if (stage === '1') return;

    if (!existsUser && userPassword !== userConfirmPassword) {
      setErrorMessage('As senhas não coincidem.');
      return;
    }

    if (userPassword && userPassword.length < 6) {
      setErrorMessage('A senha precisa ter pelo menos 6 caracteres');
      return;
    }

    // Remove a formatação do CNPJ (pontos, barras, traços)
    const formattedCNPJ = companyCNPJ.replace(/[^\d]/g, '');
    const formattedCPF = cpf.replace(/[^\d]/g, '');

    if (companyType === 'PESSOA_FISICA' && !validateCPF(formattedCPF)) {
      setErrorMessage('CPF inválido. Por favor, corrija e tente novamente.');
      return;
    }
  
    if (companyType === 'PESSOA_JURIDICA' && !validateCNPJ(formattedCNPJ)) {
      setErrorMessage('CNPJ inválido. Por favor, corrija e tente novamente.');
      return;
    }

    try {
      const dataToSend = {
        companyType,
        companyName,
        companyLegalName,
        companyCNPJ: companyType === 'PESSOA_JURIDICA'? formattedCNPJ: '',
        cpf: companyType === 'PESSOA_FISICA'? formattedCPF: '',
        userName: companyType === 'PESSOA_FISICA'? companyLegalName: userName,
        userEmail,
        userPassword
      }

      await axios.post('/api/users/register', dataToSend);

      setSuccessMessage('Usuário criado com sucesso! Você já pode efetuar login.');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.headers['x-error-message']);
      } else if (error.request) {
        setErrorMessage('Serviço indisponível. Por favor, tente novamente mais tarde.');
      } else {
        setErrorMessage('Erro desconhecido. Por favor, tente novamente.');
      }
    }
  };

  const handleContinue = async (e) => {
    try {
      const response = await axios.get(`/api/users/check/${userEmail}`);
      setStage('2');

      if (response.data === true) {
        setExistsUser(true);
      } else {
        setExistsUser(false);
      }
    } catch (error) {
    }
  };

  return (
    <section className="vh-100">
      <div className="container-register py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: '1rem', height: '100%' }}>
              <div className="row g-0 h-100">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src={registerBackground}
                    alt="register form"
                    className="img-fluid h-100" style={{ borderRadius: '1rem 0 0 1rem', objectFit: 'cover' }}
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">

                    <form autoComplete='off' onSubmit={handleSubmit}>

                      <div className="d-flex align-items-center mb-3 pb-1">
                        <span className="h2 fw-bold mb-0">Nova Conta</span>
                      </div>

                      <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: '1px' }}>{existsUser && stage === '2'? 'Preencha os dados para criar sua nova conta e vincular ao seu usuário': (stage === '1'? 'Vamos começar, informe seu e-mail abaixo.': 'Preencha os dados para criar sua conta')}</h5>

                      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                      {successMessage && <div className="alert alert-success">{successMessage}</div>}

                      {stage === '1'? (
                        // PASSO 1
                        <>
                          <div className="form-outline mb-3">
                            <label className="form-label" htmlFor="email">E-mail</label>
                            <input
                              type="email"
                              id="txtEm"
                              className="form-control form-control-lg"
                              value={userEmail}
                              onChange={(e) => setUserEmail(e.target.value)}
                              required
                              maxLength={60}
                              autoComplete="off"
                              placeholder='Digite o e-mail que você usará para acessar sua conta'
                            />
                          </div>
                          <div className="pt-1 mb-4">
                            <button type='button' className="btn btn-dark btn-lg btn-block" onClick={handleContinue} disabled={!validateEmail(userEmail)}>Prosseguir</button>
                          </div>
                        </>
                      ) : (
                        // PASSO 2
                        <>
                          <div className="mb-3 p-3 border rounded">
                            <h6 className="fw-bold mb-3 pb-3" style={{ letterSpacing: '1px' }}>{companyType === 'PESSOA_JURIDICA'? 'Dados da Empresa': 'Dados do Usuário'}</h6>
                          
                            <div className="form-outline mb-3">
                                <label className="form-label">Tipo <span className="text-danger">*</span></label>
                                <div>
                                    <div className="form-check form-check-inline mt-1">
                                        <input
                                            className="form-check-input"
                                            id="pessoaJuridica"
                                            type="radio"
                                            name="companyType"
                                            value="PESSOA_JURIDICA"
                                            checked={companyType === 'PESSOA_JURIDICA'}
                                            onChange={(e) => setCompanyType(e.target.value)}
                                            autoComplete="off"
                                        />
                                        <label className="form-check-label" htmlFor="pessoaJuridica">Pessoa Jurídica</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            id="pessoaFisica"
                                            type="radio"
                                            name="companyType"
                                            value="PESSOA_FISICA"
                                            checked={companyType === 'PESSOA_FISICA'}
                                            onChange={(e) => setCompanyType(e.target.value)}
                                            autoComplete="off"
                                        />
                                        <label className="form-check-label" htmlFor="pessoaFisica">Pessoa Física</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-outline mb-3">
                              <label className="form-label" htmlFor="companyLegalName">{companyType === 'PESSOA_JURIDICA' ? 'Razão social' : 'Nome'} <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                id="companyLegalName"
                                className="form-control form-control-lg"
                                value={companyLegalName}
                                onChange={(e) => setCompanyLegalName(e.target.value)}
                                required
                                maxLength={150}
                                autoComplete="new-username"
                                placeholder={companyType === 'PESSOA_JURIDICA' ? 'Razão Social da Empresa' : 'Digite seu nome'}
                              />
                            </div>

                            {companyType === 'PESSOA_JURIDICA' ? (
                              <div className="form-outline mb-0">
                                <label className="form-label" htmlFor="companyCNPJ">CNPJ <span className="text-danger">*</span></label>
                                <InputMask
                                  mask="99.999.999/9999-99"
                                  id="companyCNPJ"
                                  value={companyCNPJ || ''}
                                  onChange={(e) => setCompanyCNPJ(e.target.value)}
                                  required
                                  className="form-control form-control-lg"
                                  autoComplete="off"
                                />
                              </div>
                            ) : (
                              <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="cpf">CPF <span className="text-danger">*</span></label>
                                <InputMask
                                  mask="999.999.999-99"
                                  id="cpf"
                                  value={cpf || ''}
                                  onChange={(e) => setCpf(e.target.value)}
                                  required
                                  className="form-control form-control-lg"
                                  autoComplete="off"
                                />
                              </div>
                            )}

                            {companyType === 'PESSOA_FISICA' && (
                              <>
                                <div className="form-outline mb-3">
                                  <label className="form-label" htmlFor="email">E-mail <span className="text-danger">*</span></label>
                                  <input
                                    type="email"
                                    id="txtUserEmail"
                                    className="form-control form-control-lg"
                                    defaultValue={userEmail}
                                    readOnly={true}
                                    required
                                    autoComplete="off"
                                  />
                                </div>

                                <div className="form-outline mb-3">
                                  <label className="form-label" htmlFor="password">Defina sua senha <span className="text-danger">*</span></label>
                                  <input
                                    type="password"
                                    id="userPassword"
                                    className="form-control form-control-lg"
                                    value={userPassword}
                                    onChange={(e) => setUserPassword(e.target.value)}
                                    required
                                    maxLength={20}
                                    autoComplete="new-password"
                                  />
                                </div>

                                <div className="form-outline mb-0">
                                  <label className="form-label" htmlFor="confirmPassword">Confirme sua senha <span className="text-danger">*</span></label>
                                  <input
                                    type="password"
                                    id="userConfirmPassword"
                                    className="form-control form-control-lg"
                                    value={userConfirmPassword}
                                    onChange={(e) => setUserConfirmPassword(e.target.value)}
                                    required
                                    maxLength={20}
                                    autoComplete="new-password"
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          {companyType === 'PESSOA_JURIDICA' && (
                            <div className="mb-3 p-3 border rounded">
                              <h6 className="fw-bold mb-3 pb-3" style={{ letterSpacing: '1px' }}>Dados do usuário</h6>

                              <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="txtNome">Nome <span className="text-danger">*</span></label>
                                <input
                                  type="text"
                                  id="txtNome"
                                  className="form-control form-control-lg"
                                  value={userName}
                                  onChange={(e) => setUserName(e.target.value)}
                                  required
                                  maxLength={60}
                                  autoComplete="off"
                                  placeholder='Digite seu nome'
                                />
                              </div>

                              <div className="form-outline mb-3">
                                  <label className="form-label" htmlFor="email">E-mail <span className="text-danger">*</span></label>
                                  <input
                                    type="email"
                                    id="txtUserEmail"
                                    className="form-control form-control-lg readonly-style"
                                    defaultValue={userEmail}
                                    readOnly={true}
                                    required
                                    autoComplete="off"
                                  />
                              </div>

                              <div className="form-outline mb-3">
                                <label className="form-label" htmlFor="password">{existsUser? 'Digite sua senha': 'Defina sua senha'} <span className="text-danger">*</span></label>
                                <input
                                  type="password"
                                  id="txtPw"
                                  className="form-control form-control-lg"
                                  value={userPassword}
                                  onChange={(e) => setUserPassword(e.target.value)}
                                  required
                                  maxLength={20}
                                  autoComplete="off"
                                  placeholder='Defina sua senha'
                                />
                              </div>

                              {!existsUser && (
                                <div className="form-outline mb-0">
                                  <label className="form-label" htmlFor="confirmPassword">Confirme sua senha <span className="text-danger">*</span></label>
                                  <input
                                    type="password"
                                    id="txtCPw"
                                    className="form-control form-control-lg"
                                    value={userConfirmPassword}
                                    onChange={(e) => setUserConfirmPassword(e.target.value)}
                                    required
                                    maxLength={20}
                                    autoComplete="off"
                                    placeholder='Confirme sua senha'
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          <div className="pt-1 mb-4">
                            <button className="btn btn-dark btn-lg btn-block" type="submit">Registrar</button>
                          </div>
                        </>
                      )}

                      <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>Já tem uma conta? <a href="/login" style={{ color: '#393f81' }}>Entre aqui</a></p>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Register;
