import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSort, faSortUp, faSortDown, faPencil, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Paginator } from 'primereact/paginator';
import ConfirmationModal from './ConfirmationModal';
import { CSSTransition } from 'react-transition-group';

function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
        setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        // Limpa o listener quando o componente for desmontado
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
}

function CarrierList() {
    const { setAlert, axiosConfigured, auth, hasRequiredRoleDfe, hasRequiredRoleEstoque } = useContext(AuthContext);
    const havePermission = (hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_USER') || hasRequiredRoleEstoque(auth.roles, 'ROLE_ESTOQUE_USER')) && !auth.readOnlyMode;
    const [carriers, setCarriers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [sortField, setSortField] = useState('legalName');
    const [sortOrder, setSortOrder] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [carrierToDelete, setCarrierToDelete] = useState(null);
    const hasFetched = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [inProp, setInProp] = useState(false);
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            const state = location.state;
            if (state) {
                setSearchTerm(state.searchTerm || '');
                setPage(state.page || 0);
                setRows(state.rows || 10);
                setSortField(state.sortField || 'legalName');
                setSortOrder(state.sortOrder || 1);
            }
            fetchCarriers(state?.page || page, state?.rows || rows, state?.sortField || sortField, state?.sortOrder || sortOrder, state?.searchTerm || searchTerm);
            hasFetched.current = true;
        }
    }, [axiosConfigured, location.state, page, rows, searchTerm, sortField, sortOrder]);

    const fetchCarriers = async (page, rows, sortField, sortOrder, searchTerm) => {
        setLoading(true);
        try {
            const response = await axios.get('/api/carriers', {
                params: {
                    search: searchTerm,
                    page: page,
                    size: rows,
                    sort: `${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`,
                },
            });
            const carrierData = response.data.content || [];
            setCarriers(carrierData);
            setTotalRecords(response.data.totalElements || 0);
        } catch (error) {
            console.error('Erro ao buscar transportadoras', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        fetchCarriers(page, rows, sortField, sortOrder, searchTerm);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 1 ? -1 : 1;
        setSortField(field);
        setSortOrder(order);
        fetchCarriers(page, rows, field, order, searchTerm);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 1 ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const onPageChange = (event) => {
        setPage(event.page);
        setRows(event.rows);
        fetchCarriers(event.page, event.rows, sortField, sortOrder, searchTerm);
    };

    const handleEditCarrier = async (carrier) => {
        try {
            setInProp(false);
            setTimeout(() => {
                navigate(`/carriers/${carrier.id}`, {
                    state: { carrier, searchTerm, page, rows, sortField, sortOrder },
                });
            }, 300);
        } catch (error) {
            console.error('Erro ao buscar transportadora atualizada', error);
            setAlert({ type: 'danger', message: 'Erro ao carregar a transportadora atualizada' });
        }
    };

    const handleDeleteCarrier = async () => {
        try {
            await axios.delete(`/api/carriers/${carrierToDelete.id}`);
            setAlert({ type: 'success', message: 'Transportadora excluída com sucesso' });
            setShowConfirmModal(false);
            fetchCarriers(page, rows, sortField, sortOrder, searchTerm);
        } catch (error) {
            console.error('Erro ao excluir transportadora', error);
            setShowConfirmModal(false);
        }
    };

    const confirmDeleteCarrier = (carrier) => {
        setCarrierToDelete(carrier);
        setShowConfirmModal(true);
    };

    const formatCNPJ = (cnpj) => {
        if (!cnpj) return '';
        return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    };

    const formatCPF = (cpf) => {
        if (!cpf) return '';
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setInProp(true)}>
            <div className='container'>
                <h3 className="mb-3 mt-3">Transportadoras</h3>

                <div className="d-flex justify-content-between mb-3">
                    <div title={!havePermission? "Sem permissão": ""} style={{ display: "inline-block" }}>
                        <button className="btn btn-primary" onClick={() => navigate('/carriers/new', { state: { searchTerm, page, rows, sortField, sortOrder } })} disabled={!havePermission}>
                            <span className="d-none d-md-inline">Nova Transportadora</span>
                            <span className="d-inline d-md-none">Nova</span>
                        </button>
                    </div>
                    <div className="input-group search-size" style={{ maxWidth: '300px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                        />
                        <button className="btn btn-outline-secondary btn-search" onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>

                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : carriers.length > 0 ? (
                    <>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('legalName')} className='sortable-column'>Nome {getSortIcon('legalName')}</th>
                                    <th className='sortable-column d-none d-xl-table-cell'>CNPJ/CPF</th>
                                    <th onClick={() => handleSort('city.name')} className='sortable-column d-none d-xxl-table-cell'>Cidade/UF {getSortIcon('city.name')}</th>
                                    <th onClick={() => handleSort('active')} className='sortable-column d-none d-md-table-cell'>Ativo {getSortIcon('active')}</th>
                                    <th className='text-end'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                            {carriers.map((carrier) => (
                                    <tr key={carrier.id}>
                                        <td className="ellipsis">{carrier.legalName.toUpperCase()}</td>
                                        <td className="d-none d-xl-table-cell">{carrier.carrierType === 'PESSOA_FISICA' ? formatCPF(carrier.cpf) : formatCNPJ(carrier.cnpj)}</td>
                                        <td className="d-none d-xxl-table-cell">{carrier.city ? `${carrier.city.name.toUpperCase()} / ${carrier.city.uf.toUpperCase()}` : 'N/A'}</td>
                                        <td className="d-none d-md-table-cell">{carrier.active ? 'Sim' : 'Não'}</td>
                                        <td className='text-end' style={{width: "100px"}}>
                                            <button className="btn btn-sm border-secondary custom-btn me-2" title={havePermission? "Editar": "Visualizar"} onClick={() => handleEditCarrier(carrier)}>
                                                <FontAwesomeIcon icon={havePermission? faPencil: faEye} />
                                            </button>
                                            <div title={!havePermission? "Sem permissão": ""} style={{ display: "inline-block" }}>
                                                <button className="btn btn-sm border-secondary custom-btn me-2" title="Excluir" onClick={() => confirmDeleteCarrier(carrier)} disabled={!havePermission}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                        <Paginator
                            first={page * rows}
                            rows={rows}
                            totalRecords={totalRecords}
                            onPageChange={onPageChange}
                            pageLinkSize={isMobile ? 2 : 5}
                            {...(!isMobile && { rowsPerPageOptions: [10, 20, 50] })}
                        />
                    </>
                ) : (
                    <div className="no-data">
                        <p>Ainda não há dados por aqui, ou sua pesquisa não retornou resultados.</p>
                    </div>
                )}

                <ConfirmationModal
                    show={showConfirmModal}
                    onHide={() => setShowConfirmModal(false)}
                    onConfirm={handleDeleteCarrier}
                    title="Confirmar Exclusão"
                    message={`Tem certeza que deseja excluir a transportadora ${carrierToDelete?.legalName}?`}
                />
            </div>
        </CSSTransition>
    );
}

export default CarrierList;
