import React, { useRef, useState, useEffect, useContext } from 'react';
import { Tab, Tabs, Modal, Button, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faTrash, faEye, faEyeSlash, faCreditCard, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCcVisa, faCcMastercard, faCcAmex, faCcDiscover } from '@fortawesome/free-brands-svg-icons';
import axios from './AxiosInstance';
import { AuthContext } from './AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/styles/App.css';
import sslImage from '../assets/images/lets-encrypt.png';
import AsyncSelect2 from './AsyncSelect2';
import ReactInputMask from 'react-input-mask';
import InfoPopover from './InfoPopover';

function ConfirmationModal({ show, onHide, onConfirm, title, message }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancelar</Button>
                <Button variant="danger" onClick={onConfirm}>Excluir</Button>
            </Modal.Footer>
        </Modal>
    );
}

function CompanySettings() {
    const navigate = useNavigate();
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        companyType: '',
        name: '',
        legalName: '',
        cnpj: '',
        cpf: '',
        stateRegistration: '',
        street: '',
        complement: '',
        number: '',
        neighborhood: '',
        zipCode: '',
        phone: '',
        cellPhone: '',
        email: '',
        website: '',
        active: true,
        taxpayerSecurityCode: '',
        taxpayerSecurityCodeID: '',
        city: '',
        cnaes: [],
        certificateA1Password: '',
        certificateFileName: '',
        certificateFileSize: '',
        certificateFileDeleted: false,
        logoDeleted: false,
        taxRegime: '1', // Inicializa com 'Simples Nacional'
        monthlyFiscalEmail: '',
        nfeEnvironment: '',
        nfeSeries: '',
        nfeNextNumber: '',
        nfcEnvironment: '',
        nfceSeries: '',
        nfceNextNumber: '',
        automaticManifestation: false,
        paymentMethod: '',
        cardInfo: '',
        cardHolderName: '',
        cardHolderEmail: '',
        cardHolderCpfCnpj: '',
        cardHolderPostalCode: '',
        cardHolderAddressNumber: '',
        cardHolderAddressComplement: '',
        cardHolderPhone: '',
        cardHolderMobilePhone: '',
        cardNumber: '',
        expiryDate: '',
        expiryMonth: '',
        expiryYear: '',
        ccv: ''
    });
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCnae, setSelectedCnae] = useState(null);
    const [cnaesList, setCnaesList] = useState([]);
    const [cnaesToAdd, setCnaesToAdd] = useState([]);
    const [cnaesToRemove, setCnaesToRemove] = useState([]);
    const [certificateFile, setCertificateFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [key, setKey] = useState('general');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showCertificateConfirmationModal, setShowCertificateConfirmationModal] = useState(false);
    const [cnaeToDelete, setCnaeToDelete] = useState(null);
    const [defaultCnae, setDefaultCnae] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isAutomaticManifestation, setIsAutomaticManifestation] = useState(false);
    const hasFetched = useRef(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [logo, setLogo] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const handleShowCertificateConfirmationModal = () => {
        setShowCertificateConfirmationModal(true);
    };

    const handleConfirmDeleteCertificate = () => {
        setShowCertificateConfirmationModal(false);
        setFormData(prevData => ({
            ...prevData,
            certificateFileDeleted: true,
            certificateA1Password: ''
        }));
    };

    const handleShowConfirmationModal = (cnaeId) => {
        setCnaeToDelete(cnaeId);
        setShowConfirmationModal(true);
    };

    const handleConfirmDelete = () => {
        if (cnaeToDelete) {
            setCnaesList(prevList => prevList.filter(cnae => cnae.id !== cnaeToDelete));
            setCnaesToRemove([...cnaesToRemove, cnaeToDelete]);
            setShowConfirmationModal(false);
        }
    };

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            fetchCompanyData();
            hasFetched.current = true;
        }
    }, [axiosConfigured]);

    const fetchCompanyData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/company');
            const company = response.data;

            const city = company.city
                ? { label: `${company.city.name} / ${company.city.uf}`, value: company.city.id, name: company.city.name, uf: company.city.uf }
                : null;

            const defaultCnae = company.defaultCnae ? {
                id: company.defaultCnae.id,
                code: company.defaultCnae.code,
                name: company.defaultCnae.name
            } : null;

            setDefaultCnae(defaultCnae);

            const cnaes = company.cnaes.map(cnae => ({
                id: cnae.id,
                code: cnae.code,
                name: cnae.name
            }));

            setFormData({
                ...company,
                companyType: company.companyType || '',
                city: city ? { id: city.value, name: city.name, uf: city.uf } : null,
                cnaes: company.cnaes.map(cnae => cnae.id),
                certificateA1Password: company.certificateA1Password || '',
                certificateFileName: company.certificateFileName,
                certificateFileSize: company.certificateFileSize,
                certificateFileDeleted: false,
                logoDeleted: false,
                taxRegime: company.taxRegime || '1',
                monthlyFiscalEmail: company.monthlyFiscalEmail || '',
                nfeEnvironment: company.nfeEnvironment || '',
                nfeSeries: company.nfeSeries || '',
                nfeNextNumber: company.nfeNextNumber || '',
                nfcEnvironment: company.nfcEnvironment || '',
                nfceSeries: company.nfceSeries || '',
                nfceNextNumber: company.nfceNextNumber || '',
                stateRegistration: company.stateRegistration || '',
                automaticManifestation: company.automaticManifestation || '',
                paymentMethod: company.paymentMethod || '',
                cardInfo: company.cardInfo || '',
                cardHolderName: company.cardInfo?.holderName || '',
                cardHolderEmail: company.cardInfo?.holderEmail || '',
                cardHolderCpfCnpj: company.cardInfo?.holderCpfCnpj || '',
                cardHolderPostalCode: company.cardInfo?.holderPostalCode || '',
                cardHolderAddressNumber: company.cardInfo?.holderAddressNumber || '',
                cardHolderAddressComplement: company.cardInfo?.holderAddressComplement || '',
                cardHolderPhone: company.cardInfo?.holderPhone || '',
                cardHolderMobilePhone: company.cardInfo?.holderMobilePhone || '',
                cardNumber: company.cardInfo?.number || '',
                expiryDate: company.cardInfo?.expiryMonth + '/' + company.cardInfo?.expiryYear || '',
                expiryMonth: company.cardInfo?.expiryMonth || '',
                expiryYear: company.cardInfo?.expiryYear || '',
                ccv: company.cardInfo?.ccv || ''
            });

            setLogo(null);
            setImagePreview(company.base64Logo? company.base64Logo: null);
            setSelectedCity(city);
            setCnaesList(cnaes);
            setIsAutomaticManifestation(company.automaticManifestation === true);
        } catch (error) {
            console.error('Erro ao buscar dados da conta/loja', error);
        } finally {
            setLoading(false);
        }
    };

    const validateRequiredFields = () => {
        const errors = {};
        let errorsGeneral = 0;
        let errorsAddress = 0;
        let errorsFiscal = 0;
        let errorsPayment = 0;
        let errorsPhone = 0;

        if (formData.companyType === 'PESSOA_JURIDICA') {
            if (!formData.cnpj) errors.cnpj = true;
        } else {
            if (!formData.cpf) errors.cpf = true;
        }

        if (!formData.legalName) {
            errorsGeneral++;
            errors.legalName = true;
        }

        if (Object.keys(errors).length > 0 && errorsGeneral > 0) {
            setKey("general");
        }

        if (!formData.street || !formData.neighborhood || !formData.number || !formData.zipCode || !formData.city) {
            if (!formData.street) errors.street = true;
            if (!formData.neighborhood) errors.neighborhood = true;
            if (!formData.number) errors.number = true;
            if (!formData.zipCode) errors.zipCode = true;
            if (!formData.city) errors.city = true;

            errorsAddress++;
        }

        if (Object.keys(errors).length > 0 && errorsAddress > 0) {
            setKey("address");
        }

        if (!formData.stateRegistration && formData.companyType === 'PESSOA_JURIDICA') {
            errors.stateRegistration = true;
            errorsFiscal++;
        }

        if (Object.keys(errors).length > 0 && errorsFiscal > 0) {
            setKey("fiscal");
        }

        if (formData.paymentMethod && formData.paymentMethod === 'CREDIT_CARD' && !formData.cardInfo) {
            /*        cardHolderName: '',
                      cardHolderEmail: '',
                      cardHolderCpfCnpj: '',
                      cardHolderPostalCode: '',
                      cardHolderAddressNumber: '',
                      cardHolderAddressComplement: '',
                      cardHolderPhone: '',
                      cardHolderMobilePhone: '',
                      cardNumber: '',
                      expiryDate: '',
                      expiryMonth: '',
                      expiryYear: '',
                      ccv: ''
            */

            if (!formData.cardHolderName || !formData.cardNumber || !formData.expiryDate || !formData.ccv || !formData.cardHolderEmail || !formData.cardHolderCpfCnpj || !formData.cardHolderAddressNumber || !formData.cardHolderPostalCode) {
                if (!formData.cardHolderName) errors.cardHolderName = true;
                if (!formData.cardNumber) errors.cardNumber = true;
                if (!formData.expiryDate) errors.expiryDate = true;
                if (!formData.ccv) errors.ccv = true;
                if (!formData.cardHolderEmail) errors.cardHolderEmail = true;
                if (!formData.cardHolderCpfCnpj) errors.cardHolderCpfCnpj = true;
                if (!formData.cardHolderAddressNumber) errors.cardHolderAddressNumber = true;
                if (!formData.cardHolderPostalCode) errors.cardHolderPostalCode = true;

                errorsPayment++;
            }

            if ((!formData.cardHolderPhone && !formData.cardHolderMobilePhone) || (formData.cardHolderPhone.length === 0 && formData.cardHolderMobilePhone.length === 0)) {
                errorsPhone++;
                errorsPayment++;
                errors.phone = true;
            }
        } else {
            if (formData.paymentMethod === null || formData.paymentMethod === '' || formData.paymentMethod === undefined) {
                errors.paymentMethod = true;
                errorsPayment++;
            }
        }

        if (Object.keys(errors).length > 0 && errorsPayment > 0) {
            setKey("pagamento");
        }

        const countErros = Object.keys(errors).length;

        if (countErros === 1 && errorsPhone > 0) {
            setAlert({ type: 'danger', message: 'Informe o telefone e(ou) celular do titular do cartão' });
        } else if (countErros > 0) {
            console.log(errors);
            setAlert({ type: 'danger', message: 'Campos obrigatórios não preenchidos' });
        }

        //console.log(errors);
        
        return errors;
    };

    const handleSave = async () => {
        setIsSaving(true);

        try {
            const errors = validateRequiredFields();
    
            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
                return;
            }
        
            setFormErrors({});
    
            const {
                certificateA1Password,
                certificateFileName,
                certificateFileSize,
                certificateFileDeleted,
                logoDeleted,
                ...companyData
            } = formData;
    
            // Remover formatação
            companyData.cnpj = companyData.cnpj ? companyData.cnpj.replace(/[^\d]/g, '') : '';
            companyData.cpf = companyData.cpf ? companyData.cpf.replace(/[^\d]/g, '') : '';
            companyData.zipCode = companyData.zipCode ? companyData.zipCode.replace(/[^\d]/g, '') : '';
            companyData.phone = companyData.phone ? companyData.phone.replace(/[^\d]/g, '') : '';
            companyData.cellPhone = companyData.cellPhone ? companyData.cellPhone.replace(/[^\d]/g, '') : '';
    
            // Incluindo cardInfo no payload apenas se paymentMethod for CREDIT_CARD
            if (companyData.paymentMethod === "CREDIT_CARD" && companyData.ccv) {
                // Separar o expiryDate em expiryMonth e expiryYear
                const [expiryMonth, expiryYear] = companyData.expiryDate
                ? companyData.expiryDate.split('/')
                : [null, null];
    
                companyData.cardInfo = {
                    holderName: companyData.cardHolderName,
                    holderEmail: companyData.cardHolderEmail,
                    holderCpfCnpj: companyData.cardHolderCpfCnpj? companyData.cardHolderCpfCnpj.replace(/[^\d]/g, ''): '',
                    holderPostalCode: companyData.cardHolderPostalCode? companyData.cardHolderPostalCode.replace(/[^\d]/g, ''): '',
                    holderAddressNumber: companyData.cardHolderAddressNumber,
                    holderAddressComplement: companyData.cardHolderAddressComplement,
                    holderPhone: companyData.cardHolderPhone? companyData.cardHolderPhone.replace(/[^\d]/g, ''): '',
                    holderMobilePhone: companyData.cardHolderMobilePhone? companyData.cardHolderMobilePhone.replace(/[^\d]/g, ''): '',
                    number: companyData.cardNumber ? companyData.cardNumber.replace(/[^\d]/g, '') : '',
                    expiryMonth,
                    expiryYear,
                    ccv: companyData.ccv,
                };
            } else {
                companyData.cardInfo = null;  // Remover cardInfo caso a forma de pagamento não seja cartão
            }
    
            // Remover os dados do cartão do nível superior de companyData
            delete companyData.cardHolderName;
            delete companyData.cardHolderEmail;
            delete companyData.cardHolderCpfCnpj;
            delete companyData.cardHolderPostalCode;
            delete companyData.cardHolderAddressNumber;
            delete companyData.cardHolderAddressComplement;
            delete companyData.cardHolderPhone;
            delete companyData.cardHolderMobilePhone;
            delete companyData.cardNumber;
            delete companyData.expiryDate;
            delete companyData.expiryMonth;
            delete companyData.expiryYear;
            delete companyData.ccv;
    
            const formDataToSend = new FormData();
    
            formDataToSend.append('company', JSON.stringify({
                ...companyData,
                defaultCnae: defaultCnae ? { id: defaultCnae.id } : null,
                cnaes: cnaesList.map(cnae => cnae.id),
                cnaesToAdd: cnaesToAdd || [],
                cnaesToRemove: cnaesToRemove || [],
                certificateFileDeleted,
                logoDeleted,
                certificateA1Password: certificateA1Password || '',
                automaticManifestation: isAutomaticManifestation || false,
                cardInfo: companyData.cardInfo?.ccv? companyData.cardInfo: null
            }));
    
            if (certificateFile) {
                formDataToSend.append('certificateA1', certificateFile);
            }
    
            if (logo) {
                formDataToSend.append('logo', logo);
            }

            await axios.put('/api/company', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Atualiza os dados após o salvamento, para garantir que o estado está atualizado.
            //await fetchCompanyData();

            setAlert({ type: 'success', message: 'Dados da conta/loja salvo com sucesso' });
            setCnaesToAdd([]);
            setCnaesToRemove([]);

            navigate('/home', { state: {} });
        } catch (error) {
            console.error('Erro ao salvar dados da conta/loja', error);

            if (!error.response?.headers['x-error-message'] &&
                !error.response?.headers['x-warn-message'] &&
                !error.response?.headers['x-info-message']) {
                setAlert({ type: 'danger', message: 'Erro ao salvar dados da conta/loja' });
            }
        } finally {
            setTimeout(() => {
                setIsSaving(false);
            }, 400);
        }
    };

    const handleCancel = () => {
        navigate('/home', { state: {} });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'stateRegistration' && value.length > 14) {
            return;
        }

        setFormData((prevData) => {
            const isChanged = prevData[name] !== value;
            if (isChanged) {
            }
            return { ...prevData, [name]: value };
        });
    };

    const handleCityChange = (selectedOption) => {
        const selectedCity = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.name,
            uf: selectedOption.province
        } : null;

        setSelectedCity(selectedOption);
        setFormData((prevData) => {
            return { ...prevData, city: selectedCity };
        });
    };

    const handleCertificateUpload = (e) => {
        setCertificateFile(e.target.files[0]);
    };

    const handleSetDefaultCnae = (cnae) => {
        setDefaultCnae(cnae);
    };

    const handleAddCnae = () => {
        if (!selectedCnae) {
            setAlert({ type: 'warning', message: 'Selecione um CNAE para adicionar' });
            return;
        }

        const [code, ...nameParts] = selectedCnae.label.split(' - ');
        const name = nameParts.join(' - ');

        if (!code || !name) {
            setAlert({ type: 'warning', message: 'Informações de CNAE incompletas' });
            return;
        }

        const newCnae = {
            id: selectedCnae.value,
            code: code,
            name: name
        };

        setCnaesList(prevList => {
            const updatedList = [...prevList, newCnae];
    
            if (updatedList.length === 1) {
                handleSetDefaultCnae(newCnae);
            }
    
            return updatedList;
        });

        setCnaesToAdd([...cnaesToAdd, selectedCnae.value]);
        setSelectedCnae(null);
    };

    const handleAutomaticManifestation = () => {
        setIsAutomaticManifestation(!isAutomaticManifestation);
    };

    const formatCpfCnpj = (value) => {
        // Remove todos os caracteres não numéricos
        const numericValue = value.replace(/\D/g, "");

        // Aplica máscara de CPF ou CNPJ com base no comprimento
        if (numericValue.length <= 11) {
            return numericValue
                .replace(/(\d{3})(\d{3})(\d{3})(\d{0,2})/, "$1.$2.$3-$4")
                .replace(/[-.]$/, ""); // Evita caracteres extras no final
        } else {
            return numericValue
                .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/, "$1.$2.$3/$4-$5")
                .replace(/[-./]$/, ""); // Evita caracteres extras no final
        }
    };

    const handleCpfCnpjChange = (e) => {
        const value = e.target.value;

        setFormData({
            ...formData,
            cardHolderCpfCnpj: formatCpfCnpj(value),
        });
    };

    const getCardBrandIcon = (cardNumber) => {
        if (!cardNumber) return faCreditCard;
        
        const cleanedNumber = cardNumber.replace(/\D/g, '');
    
        if (/^4/.test(cleanedNumber)) {
            return faCcVisa;
        } else if (/^5[1-5]/.test(cleanedNumber)) {
            return faCcMastercard;
        } else if (/^3[47]/.test(cleanedNumber)) {
            return faCcAmex;
        } else if (/^6011|^65/.test(cleanedNumber)) {
            return faCcDiscover;
        } else {
            return faCreditCard; // Ícone genérico para outros tipos
        }
    };    

    const clearCardInfo = () => {
        setFormData(prevData => ({
            ...prevData,
            cardInfo: '',
            cardHolderName: '',
            cardHolderEmail: '',
            cardHolderCpfCnpj: '',
            cardHolderAddressNumber: '',
            cardHolderAddressComplement: '',
            cardHolderPostalCode: '',
            cardHolderPhone: '',
            cardHolderMobilePhone: '',
            cardNumber: '',
            expiryDate: '',
            expiryMonth: '',
            expiryYear: '',
            ccv: ''
        }));
    };    

    /*const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const img = new Image();

        img.onload = () => {
            setImagePreview(URL.createObjectURL(file));
            setLogo(event.target.files[0]);
        };
        img.onerror = () => {
          setErrorMessage("Arquivo inválido. Por favor, escolha uma imagem.");
        };
    
        img.src = URL.createObjectURL(file);
    };*/

    const handleImageUpload = (event) => {
        const MAX_FILE_SIZE = 1024 * 300; // 300 KB
        const file = event.target.files[0];
        if (!file) return;

        // Verifica o tamanho do arquivo
        if (file.size > MAX_FILE_SIZE) {
            setErrorMessage("O tamanho do arquivo excede o limite de 300 KB.");
            return;
        }
    
        const reader = new FileReader();
    
        reader.onload = (e) => {
            // O resultado inclui o prefixo "data:image/png;base64,"
            // Remove o prefixo para armazenar somente a string Base64
            const base64String = e.target.result.split(",")[1];
    
            setImagePreview(base64String);
            setLogo(file);
        };
    
        reader.onerror = () => {
            setErrorMessage("Erro ao processar o arquivo. Por favor, tente novamente.");
        };

        // Atualiza o logoDeleted para false
        setFormData(prevData => ({
            ...prevData,
            logoDeleted: false
        }));
    
        reader.readAsDataURL(file);
    };
    

    const handleRemoveLogo = (event) => {
        setImagePreview(null);
        setLogo(null);
        setFormData(prevData => ({
            ...prevData,
            logoDeleted: true
        }));
    };

    return (
        
            <div>
                <div className="form-limited-width mt-4">
                    <h3 className="mb-3">Configurações da Conta/Loja</h3>
                    {loading ? (
                        <div className="spinner-container">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mt-4">
                            <Tab eventKey="general" title="Dados Gerais">
                                <div className="form-limited-width mt-4">
                                    <form>
                                        {/* Seção: Informações Básicas */}
                                        <div className="mb-3 p-3 border rounded">
                                            <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                                            <div className="row mb-3">
                                                <div className="col-lg-3">
                                                    <div className="profile-image-selector">
                                                        <label
                                                            htmlFor="profile-image-input"
                                                            className="upload-container"
                                                            style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            width: "207px",
                                                            height: "207px",
                                                            border: "2px dashed #ccc",
                                                            borderRadius: "8px",
                                                            cursor: "pointer",
                                                            overflow: "hidden",
                                                            }}
                                                        >
                                                            {imagePreview ? (
                                                                    <img
                                                                        src={`data:image/png;base64,${imagePreview}`} // Prefixo correto para Base64
                                                                        alt="Preview"
                                                                        style={{
                                                                            maxWidth: "209px",
                                                                            maxHeight: "209px",
                                                                            borderRadius: "8px",
                                                                        }}
                                                                    />
                                                            ) : (
                                                            <span style={{ color: "#666", textAlign: "center" }}>
                                                                Clique para<br />selecionar uma<br /> logomarca
                                                            </span>
                                                            )}
                                                        </label>
                                                        <input
                                                            id="profile-image-input"
                                                            type="file"
                                                            accept="image/*"
                                                            style={{ display: "none" }}
                                                            onChange={handleImageUpload}
                                                        />
                                                        {errorMessage && (
                                                            <div style={{ color: "red", fontSize: "12px", marginTop: "8px" }}>
                                                            {errorMessage}
                                                            </div>
                                                        )}
                                                        <button
                                                            type="button"
                                                            className="btn btn-link"
                                                            onClick={handleRemoveLogo}
                                                            style={{display: imagePreview? '': 'none'}}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} /> Excluir logomarca
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9 mt-3 mt-lg-0">
                                                    <div className="mb-3">
                                                        <label className="form-label">Tipo</label>
                                                        <input
                                                            type="text"
                                                            name="companyType"
                                                            readOnly={true}
                                                            className="form-control readonly-style"
                                                            value={formData.companyType === 'PESSOA_JURIDICA'? 'Pessoa Jurídica': 'Pessoa Física'}
                                                            autoComplete="off"
                                                        />
                                                    </div>

                                                    <div className={formErrors.legalName? 'mb-3 has-error' : 'mb-3'}>
                                                        <label className="form-label">{formData.companyType === 'PESSOA_JURIDICA' ? 'Razão Social' : 'Nome Completo'} <span className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="legalName"
                                                            value={formData.legalName || ''}
                                                            onChange={handleInputChange}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>

                                                    {formData.companyType === 'PESSOA_JURIDICA' ? (
                                                        <div className="mb-3">
                                                            <label className="form-label">Nome</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                value={formData.name || ''}
                                                                onChange={handleInputChange}
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        </div>
                                                    ) : ''}
                                                </div>

                                                <div className={formErrors.cnpj || formErrors.cpf? 'mb-0 has-error' : 'mb-0'}>
                                                    <label className="form-label">{formData.companyType === 'PESSOA_JURIDICA'? 'CNPJ': 'CPF'} <span className="text-danger">*</span></label>
                                                    {formData.companyType === 'PESSOA_JURIDICA' ? (
                                                        <ReactInputMask mask="99.999.999/9999-99" value={formData.cnpj || ''} onChange={handleInputChange} autoComplete="off">
                                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cnpj" required autoComplete="off" />}
                                                        </ReactInputMask>
                                                    ) : (
                                                        <ReactInputMask mask="999.999.999-99" value={formData.cpf || ''} onChange={handleInputChange} autoComplete="off">
                                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cpf" required autoComplete="off" />}
                                                        </ReactInputMask>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Seção: Contato */}
                                        <div className="mb-3 p-3 border rounded">
                                            <h5 style={{marginBottom: 1 + 'em'}}>Contato</h5>
                                            <div className="row mb-3">
                                                <div className="col-md-6">
                                                    <label className="form-label">Telefone</label>
                                                    <ReactInputMask mask="(99) 9999-9999" value={formData.phone || ''} onChange={handleInputChange} autoComplete="off">
                                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" name="phone" autoComplete="off" />}
                                                    </ReactInputMask>
                                                </div>
                                                <div className="col-md-6 mt-3 mt-md-0">
                                                    <label className="form-label">Celular</label>
                                                    <ReactInputMask mask="(99) 99999-9999" value={formData.cellPhone || ''} onChange={handleInputChange} autoComplete="off">
                                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cellPhone" autoComplete="off" />}
                                                    </ReactInputMask>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    value={formData.email || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>

                                            <div className="mb-0">
                                                <label className="form-label">Website</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="website"
                                                    value={formData.website || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Tab>

                            <Tab eventKey="address" title="Endereço">
                                <div className="form-limited-width mt-4">
                                    <form>
                                        {/* Seção: Endereço */}
                                        <div className="mb-3 p-3 border rounded">
                                            <h5 style={{marginBottom: 1 + 'em'}}>Endereço</h5>
                                            <div className="row mb-3">
                                                <div className={formErrors.street? 'col-md-8 has-error' : 'col-md-8'}>
                                                    <label className="form-label">Logradouro <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="street"
                                                        value={formData.street || ''}
                                                        onChange={handleInputChange}
                                                        required
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className={formErrors.number? 'col-md-4 mt-3 mt-md-0 has-error' : 'col-md-4 mt-3 mt-md-0'}>
                                                    <label className="form-label">Número <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="number"
                                                        value={formData.number || ''}
                                                        onChange={handleInputChange}
                                                        required
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className={formErrors.neighborhood? 'col-md-8 has-error' : 'col-md-8'}>
                                                    <label className="form-label">Bairro <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="neighborhood"
                                                        value={formData.neighborhood || ''}
                                                        onChange={handleInputChange}
                                                        required
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className={formErrors.zipCode? 'col-md-4 mt-3 mt-md-0 has-error' : 'col-md-4 mt-3 mt-md-0'}>
                                                    <label className="form-label">CEP <span className="text-danger">*</span></label>
                                                    <ReactInputMask mask="99999-999" value={formData.zipCode || ''} onChange={handleInputChange} required autoComplete="off">
                                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" name="zipCode" required autoComplete="off" />}
                                                    </ReactInputMask>
                                                </div>
                                            </div>

                                            <div className="row mb-0">
                                                <div className={formErrors.city? 'col-md-8 has-error' : 'col-md-8'}>
                                                    <label className="form-label">Cidade <span className="text-danger">*</span></label>
                                                    <AsyncSelect2
                                                        url="/api/cities"
                                                        value={selectedCity || ''}
                                                        onChange={handleCityChange}
                                                        valueField="ibgeCode"
                                                        labelField="name"
                                                        placeholder="Selecione a cidade"
                                                        initialOptions={selectedCity ? [selectedCity] : []}
                                                        labelFormatter={(item) => `${item.name} / ${item.province.uf}`}
                                                        showClear
                                                    />
                                                </div>
                                                <div className="col-md-4 mt-3 mt-md-0">
                                                    <label className="form-label">Complemento</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="complement"
                                                        value={formData.complement || ''}
                                                        onChange={handleInputChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Tab>

                            <Tab eventKey="security" title="Segurança">
                                <div className="form-limited-width mt-4">
                                    <form autoComplete="off">
                                        {/* Seção Código de Segurança do Contribuinte */}
                                        <div className="mb-3 p-3 border rounded">
                                            <h5 style={{marginBottom: 1 + 'em'}}>Código de Segurança do Contribuinte</h5>
                                            <div className="mb-3">
                                                <label className="form-label">Código de Segurança do Contribuinte</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="taxpayerSecurityCode"
                                                    value={formData.taxpayerSecurityCode || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label">ID do Código de Segurança do Contribuinte</label>
                                                <input type="text" style={{ display: 'none' }} autoComplete="username" />
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="taxpayerSecurityCodeID"
                                                    value={formData.taxpayerSecurityCodeID || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>

                                            <div className="mb-0">
                                                <label className="form-label">Email para envio mensal dos cupons fiscais</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="monthlyFiscalEmail"
                                                    value={formData.monthlyFiscalEmail || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>

                                        {/* Seção Certificado A1 */}
                                        <div className="mb-3 p-3 border rounded">
                                            <h5 style={{marginBottom: 1 + 'em'}}>Configuração do Certificado</h5>
                                            <div className="mb-3">
                                                <label className="form-label">Certificado A1 (.pfx)</label>
                                                {formData.certificateFileName && !formData.certificateFileDeleted ? (
                                                    <div>
                                                        <p>Arquivo atual: {formData.certificateFileName}</p>
                                                        {formData.certificateValidity && (
                                                            <p>Validade: {new Date(formData.certificateValidity).toLocaleDateString()}</p>
                                                        )}
                                                        <button type="button" className="btn btn-danger" onClick={handleShowCertificateConfirmationModal}>
                                                            Excluir Certificado
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        accept=".pfx"
                                                        onChange={handleCertificateUpload}
                                                        autoComplete="off"
                                                    />
                                                )}
                                            </div>

                                            <div className="mb-0">
                                                <label className="form-label">Senha do Certificado A1</label>
                                                <div className="input-group">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        className="form-control"
                                                        name="certificateA1Password"
                                                        value={formData.certificateA1Password || ''}
                                                        onChange={handleInputChange}
                                                        autoComplete="new-password"
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary"
                                                        onClick={togglePasswordVisibility}
                                                        style={{ height: "36px", width: "36px", display: "flex", justifyContent: "center", alignItems: "center" }}
                                                    >
                                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Tab>

                            <Tab eventKey="cnaes" title="CNAEs">
                                <div className="form-limited-width mt-4">
                                    <form>
                                        <div className="mb-3 p-3 border rounded">
                                            <h5 style={{marginBottom: 1 + 'em'}}>Gerenciar CNAEs</h5>
                                            <div className="d-flex">
                                                <div style={{ flexGrow: 1 }}>
                                                    <AsyncSelect2
                                                        url="/api/cnae-subclasses"
                                                        value={selectedCnae || ''}
                                                        onChange={(cnae) => setSelectedCnae(cnae)}
                                                        valueField="id"
                                                        labelField="name"
                                                        labelFormatter={(item) => `${item.code} - ${item.name}`}
                                                        placeholder="Selecione o CNAE"
                                                        initialOptions={[]}
                                                    />
                                                </div>
                                                <Button className="ms-2" onClick={handleAddCnae}>
                                                    <FontAwesomeIcon icon={faPlus} /> Adicionar
                                                </Button>
                                            </div>
                                            {cnaesList.length > 0 && (
                                                <>
                                                    <h5 style={{marginBottom: 1 + 'em'}} className="mt-4">Registrados</h5>
                                                    <ListGroup>
                                                        {cnaesList.map((cnae) => (
                                                            <ListGroup.Item key={cnae.id} className="d-flex justify-content-between align-items-center">
                                                                {cnae.code ? `${cnae.code} - ${cnae.name}` : 'Informações não disponíveis'}
                                                                <div>
                                                                    <Button
                                                                        variant={defaultCnae && defaultCnae.id === cnae.id ? "success" : "outline-secondary"}
                                                                        size="sm"
                                                                        onClick={() => handleSetDefaultCnae(cnae)}
                                                                    >
                                                                        {defaultCnae && defaultCnae.id === cnae.id ? "Padrão" : "Definir como Padrão"}
                                                                    </Button>
                                                                    <Button variant="danger" size="sm" className="ms-2" onClick={() => handleShowConfirmationModal(cnae.id)}>
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </Button>
                                                                </div>
                                                            </ListGroup.Item>
                                                        ))}
                                                    </ListGroup>
                                                </>
                                            )}

                                            <ConfirmationModal
                                                show={showConfirmationModal}
                                                onHide={() => setShowConfirmationModal(false)}
                                                onConfirm={handleConfirmDelete}
                                                title="Confirmar Exclusão"
                                                message="Tem certeza de que deseja excluir este CNAE?"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </Tab>

                            <Tab eventKey="fiscal" title="Dados Fiscais">
                                <div className="form-limited-width mt-4">
                                    <form>
                                        {/* Seção Regime Tributário */}
                                        <div className="mb-3 p-3 border rounded">
                                            <h5 style={{marginBottom: 1 + 'em'}}>Informações fiscais</h5>
                                            <div className="mb-3">
                                                <label className="form-label">Regime tributário</label>
                                                <select
                                                    className="form-select"
                                                    name="taxRegime"
                                                    value={formData.taxRegime || ''}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="1">Simples Nacional</option>
                                                </select>
                                            </div>
                                            <div className={formErrors.stateRegistration? 'mb-0 has-error' : 'mb-0'}>
                                                <label className="form-label">Inscrição estadual {formData.companyType === 'PESSOA_JURIDICA' && (<><span className="text-danger">*</span></>)}</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="stateRegistration"
                                                    value={formData.stateRegistration || ''}
                                                    onChange={handleInputChange}
                                                    max="99999999999999"
                                                    required={formData.companyType === 'PESSOA_JURIDICA'? true: false}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>

                                        {/* Seção NF-e */}
                                        <div className="mb-3 p-3 border rounded">
                                            <h5 style={{marginBottom: 1 + 'em'}}>NF-e</h5>

                                            <div className="mb-3">
                                                <label className="form-label">Ambiente <span className="text-danger">*</span></label>
                                                <select 
                                                    className="form-select"
                                                    name="nfeEnvironment"
                                                    value={formData.nfeEnvironment}
                                                    onChange={handleInputChange}
                                                    required={true}
                                                >
                                                    <option value="HOMOLOGACAO">Homologação</option>
                                                    <option value="PRODUCAO">Produção</option>
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label">Série NFe</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="nfeSeries"
                                                    value={formData.nfeSeries || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label">Próxima numeração NFe</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="nfeNextNumber"
                                                    value={formData.nfeNextNumber}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>

                                            <div className='mb-0 d-flex justify-content-end'>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="automaticManifestation"
                                                        checked={isAutomaticManifestation}
                                                        onChange={handleAutomaticManifestation}
                                                    />
                                                    <label className="form-check-label" htmlFor="automaticManifestation">
                                                        Manifestar ciência da operação automáticamente nas notas fiscais de entrada
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Seção NFC-e */}
                                        <div className="mb-3 p-3 border rounded">
                                            <h5 style={{marginBottom: 1 + 'em'}}>NFC-e</h5>

                                            <div className="mb-3">
                                                <label className="form-label">Ambiente <span className="text-danger">*</span></label>
                                                <select 
                                                    className="form-select"
                                                    name="nfcEnvironment"
                                                    value={formData.nfcEnvironment}
                                                    onChange={handleInputChange}
                                                    required={true}
                                                >
                                                    <option value="HOMOLOGACAO">Homologação</option>
                                                    <option value="PRODUCAO">Produção</option>
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label">Série NFCe</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="nfceSeries"
                                                    value={formData.nfceSeries || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>

                                            <div className="mb-0">
                                                <label className="form-label">Próxima numeração NFCe</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="nfceNextNumber"
                                                    value={formData.nfceNextNumber || ''}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Tab>

                            <Tab eventKey="pagamento" title="Informações de pagamento">
                                <div className="form-limited-width mt-4">
                                    <form>
                                        {/* Seção Informações para Cobrança */}
                                        <div className="mb-3 p-3 border rounded">
                                            <h5 style={{ marginBottom: '1em' }}>Informações para cobrança da sua assinatura</h5>
                                            <div className={formErrors.paymentMethod ? 'mb-3 has-error' : 'mb-3'}>
                                                <label className="form-label d-flex align-items-center justify-content-between">
                                                    Forma de pagamento
                                                    <InfoPopover
                                                        content={
                                                            <div style={{ textAlign: 'justify' }}>
                                                                Mudanças na forma de pagamento de BOLETO para CARTÃO DE CRÉDITO serão aplicadas somente nas próximas faturas a serem geradas.
                                                            </div>
                                                        }
                                                    />
                                                    {'\u00A0'}<span className="text-danger">*</span>
                                                    <img src={sslImage} alt="SSL Secure" width="150" className="ms-auto" />
                                                </label>
                                                <select
                                                    className="form-select"
                                                    name="paymentMethod"
                                                    value={formData.paymentMethod || ''}
                                                    onChange={handleInputChange}
                                                    style={{maxWidth: '300px'}}
                                                >
                                                    <option value="">Selecione a forma de pagamento</option>
                                                    <option value="CREDIT_CARD">Cartão de crédito</option>
                                                    <option value="BOLETO">Boleto</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* Campos de informações do cartão de crédito */}
                                        {formData.paymentMethod === "CREDIT_CARD" && (
                                            <div className="mb-3">
                                                {formData.cardInfo && formData.cardInfo.number ? (
                                                    <>
                                                        <div className="mb-3 p-3 border rounded">
                                                            <div className="credit-card-layout">
                                                                <div className="credit-card-info">
                                                                    <FontAwesomeIcon
                                                                        icon={getCardBrandIcon(formData.cardInfo.number)}
                                                                        className="credit-card-icon credit-card-icon-size"
                                                                    />
                                                                    <div className="credit-card-number">
                                                                        {formData.cardInfo.number}
                                                                    </div>
                                                                </div>
                                                                <div className="credit-card-holder">
                                                                    <label>Nome no Cartão</label>
                                                                    <div>{formData.cardInfo.holderName}</div>
                                                                </div>
                                                                <div className="credit-card-expiry">
                                                                    <label>Expiração</label>
                                                                    <div>{formData.cardInfo.expiryMonth}/{formData.cardInfo.expiryYear}</div>
                                                                </div>
                                                            </div>
                                                            {/* Botão para Alterar Cartão */}
                                                            <button
                                                                type="button"
                                                                className="btn btn-link text-primary mt-3"
                                                                onClick={() => clearCardInfo()}
                                                            >
                                                                Alterar Cartão
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    {/* Exibir formulário para cadastrar novas informações do cartão*/}
                                                    <div className="mb-3 p-3 border rounded">
                                                        <h5 style={{ marginBottom: '1em' }}>Informações do Cartão de Crédito</h5>
                                                        
                                                        <div className={formErrors.cardHolderName? 'mb-3 has-error' : 'mb-3'}>
                                                            <label className="form-label">Nome no Cartão <span className="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="cardHolderName"
                                                                value={formData.cardHolderName || ''}
                                                                onChange={handleInputChange}
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        </div>
                                                        <div className={formErrors.cardNumber? 'mb-3 has-error' : 'mb-3'}>
                                                            <label className="form-label">Número do Cartão <span className="text-danger">*</span></label>
                                                            <ReactInputMask
                                                                mask="9999 9999 9999 9999"
                                                                className="form-control"
                                                                name="cardNumber"
                                                                value={formData.cardNumber || ''}
                                                                onChange={handleInputChange}
                                                                placeholder="____ ____ ____ ____"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="mb-3 d-flex">
                                                            <div style={{ flex: 1, marginRight: '1rem' }} className={formErrors.expiryDate? 'has-error' : ''}>
                                                                <label className="form-label">Expiração (MM/YY) <span className="text-danger">*</span></label>
                                                                <ReactInputMask
                                                                    mask="99/99"
                                                                    className="form-control"
                                                                    name="expiryDate"
                                                                    placeholder="MM/YY"
                                                                    value={formData.expiryDate || ''}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div style={{ width: '100px' }} className={formErrors.ccv? 'has-error' : ''}>
                                                                <label className="form-label">CCV <span className="text-danger">*</span></label>
                                                                <ReactInputMask
                                                                    mask="999"
                                                                    className="form-control"
                                                                    name="ccv"
                                                                    value={formData.ccv || ''}
                                                                    onChange={handleInputChange}
                                                                    autoComplete="off"
                                                                    placeholder="___"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        {/* Informações do titular do cartão */}
                                                        <div className="mb-0 p-3 border rounded">
                                                            <h5 style={{ marginBottom: '1em' }}>Informações do Titular do Cartão</h5>
                                                            <div className={formErrors.cardHolderEmail? 'mb-3 has-error' : 'mb-3'}>
                                                                <label className="form-label">E-mail <span className="text-danger">*</span></label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    name="cardHolderEmail"
                                                                    value={formData.cardHolderEmail || ''}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className={formErrors.cardHolderCpfCnpj? 'mb-3 has-error' : 'mb-3'}>
                                                                <label className="form-label">CPF ou CNPJ <span className="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="cardHolderCpfCnpj"
                                                                    value={formData.cardHolderCpfCnpj}
                                                                    onChange={handleCpfCnpjChange}
                                                                    placeholder="Digite o CPF ou CNPJ"
                                                                    maxLength={18}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className={formErrors.cardHolderPostalCode? 'mb-3 has-error' : 'mb-3'}>
                                                                <label className="form-label">CEP <span className="text-danger">*</span></label>
                                                                <ReactInputMask
                                                                    mask="99999-999"
                                                                    className="form-control"
                                                                    name="cardHolderPostalCode"
                                                                    value={formData.cardHolderPostalCode || ''}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className={formErrors.cardHolderAddressNumber? 'mb-3 has-error' : 'mb-3'}>
                                                                <label className="form-label">Número do Endereço <span className="text-danger">*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="cardHolderAddressNumber"
                                                                    value={formData.cardHolderAddressNumber || ''}
                                                                    onChange={handleInputChange}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-label">Complemento do Endereço</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="cardHolderAddressComplement"
                                                                    value={formData.cardHolderAddressComplement || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label className="form-label">Telefone</label>
                                                                <ReactInputMask
                                                                    mask="(99) 9999-9999"
                                                                    className="form-control"
                                                                    name="cardHolderPhone"
                                                                    value={formData.cardHolderPhone || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                            <div className="mb-0">
                                                                <label className="form-label">Celular</label>
                                                                <ReactInputMask
                                                                    mask="(99) 99999-9999"
                                                                    className="form-control"
                                                                    name="cardHolderMobilePhone"
                                                                    value={formData.cardHolderMobilePhone || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </Tab>
                        </Tabs>
                    )}
                    {/* Modal para confirmar exclusão de certificado */}
                    <ConfirmationModal
                        show={showCertificateConfirmationModal}
                        onHide={() => setShowCertificateConfirmationModal(false)}
                        onConfirm={handleConfirmDeleteCertificate}
                        title="Confirmar Exclusão"
                        message="Tem certeza de que deseja excluir o certificado A1?"
                    />
                </div>

                <div className={`button-bar show`}>
                    <div className="button-bar-container">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={handleSave}
                            disabled={isSaving}>
                            {isSaving && (<FontAwesomeIcon icon={faSpinner} spin className='me-2' />)}<FontAwesomeIcon icon={faSave} /> Salvar
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>

    );
}

export default CompanySettings;

