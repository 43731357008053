  import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
  import { Link } from 'react-router-dom';
  import axios from './AxiosInstance';
  import { AuthContext } from './AuthContext';
  import Chart from 'react-apexcharts';
  import { CSSTransition } from 'react-transition-group';
  import '../assets/styles/Home.css';
  
  // Função para gerar uma sequência de datas
  function generateDateSeries(count) {
    const dates = [];
    let currentDate = new Date();
    for (let i = 0; i < count; i++) {
      dates.unshift(
        new Date(currentDate).toLocaleDateString("en-US", { day: "numeric", month: "short" })
      );
      currentDate.setDate(currentDate.getDate() - 1);
    }
    return dates;
  }
  
  function Home() {
    const { auth, logout, axiosConfigured } = useContext(AuthContext);
    const hasFetched = useRef(false);
    const [inProp, setInProp] = useState(false);
    const [outgoingSummary, setOutgoingSummary] = useState({});
    const [incomingSummary, setIncomingSummary] = useState({});
    const [loadingOutgoingSummary, setLoadingOutgoingSummary] = useState(true);
    const [loadingIncomingSummary, setLoadingIncomingSummary] = useState(true);
    const [loadingSalesCategorySummary, setLoadingSalesCategorySummary] = useState(true);
    const [loadingBestSellersSummary, setLoadingBestSellersSummary] = useState(true);
    const [loadingTop10CustomersSummary, setLoadingTop10CustomersSummary] = useState(true);
    const [loadingTop10SuppliersSummary, setLoadingTop10SuppliersSummary] = useState(true);
    const [categorySalesOptions, setCategorySalesOptions] = useState({});
    const [categorySalesSeries, setCategorySalesSeries] = useState([]);
    const [bestSellersOptions, setBestSellersOptions] = useState({});
    const [bestSellersSeries, setBestSellersSeries] = useState([]);
    const [top10CustomersOptions, setTop10CustomersOptions] = useState({});
    const [top10CustomersSeries, setTop10CustomersSeries] = useState([]);
    const [top10SuppliersOptions, setTop10SuppliersOptions] = useState({});
    const [top10SuppliersSeries, setTop10SuppliersSeries] = useState([]);
  
    const verifySession = useCallback(async () => {
      try {
        await axios.get('/api/users/verify');
      } catch (error) {
        logout(false, false);
      }
    }, [logout]);

    const fetchInvoiceSummary = useCallback(async (typeOperation) => {
      try {
        const response = await axios.get(`/api/dashboards/invoices/summary/current-month/${typeOperation}`, {});
        return response;
      } catch (error) {
        console.error(`Erro ao buscar resumo de notas fiscais para ${typeOperation}`, error);
      }
    }, []);
  
    const fetchOutgoingInvoice = useCallback(async () => {
      setLoadingOutgoingSummary(true);
      try {
        let response = await fetchInvoiceSummary('SAIDA');

        if (!response || !response?.data) {
          if (!response) {
            response = {
              data: []
            }
          } else {
            response.data = [];
          }
        }
    
        const totalOutgoing = response.data && response.data.length > 0
          ? response.data.filter(item => item.status === 'NFE_AUTORIZADA')
                .reduce((acc, item) => acc + (item.totalValue || 0), 0)
          : 0;

        const totalCancelled = response.data && response.data.length > 0
        ? response.data
            .filter(item => item.status === 'CANCELADA')
            .reduce((acc, item) => acc + (item.totalValue || 0), 0)
        : 0;
    
        const groupedData = response.data.filter(item => item.status === 'NFE_AUTORIZADA').reduce((acc, item) => {
          const isoDate = new Date(item.issueDate).toISOString().split('T')[0]; // Formato ISO
          const formattedDate = new Date(item.issueDate).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
          acc[isoDate] = {
            value: (acc[isoDate]?.value || 0) + item.totalValue,
            formattedDate,
          };
          return acc;
        }, {});
        
        const outgoingData = Object.entries(groupedData).map(([isoDate, { value, formattedDate }]) => ({
          isoDate,
          formattedDate,
          value,
        }));
        
        setOutgoingSummary({
          total: totalOutgoing,
          totalCancelled: totalCancelled,
          data: outgoingData,
        });
      } catch (error) {
        setOutgoingSummary({
          total: 0,
          totalCancelled: 0,
          data: {},
        });
        console.error('Erro ao buscar notas de saída:', error);
      } finally {
        setLoadingOutgoingSummary(false);
      }
    }, [fetchInvoiceSummary]);
  
    const fetchIncomingInvoice = useCallback(async () => {
      setLoadingIncomingSummary(true);
      try {
        let response = await fetchInvoiceSummary('ENTRADA');

        if (!response || !response?.data) {
          if (!response) {
            response = {
              data: []
            }
          } else {
            response.data = [];
          }
        }
    
        const totalIncoming = response.data && response.data.length > 0
          ? response.data
                .filter(item => item.status === 'NFE_AUTORIZADA')
                .reduce((acc, item) => acc + (item.totalValue || 0), 0)
          : 0;

        const totalCancelled = response.data && response.data.length > 0
        ? response.data
            .filter(item => item.status === 'CANCELADA')
            .reduce((acc, item) => acc + (item.totalValue || 0), 0)
        : 0;
    
        const groupedData = response.data.filter(item => item.status === 'NFE_AUTORIZADA').reduce((acc, item) => {
          const isoDate = new Date(item.issueDate).toISOString().split('T')[0]; // Formato ISO
          const formattedDate = new Date(item.issueDate).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
          acc[isoDate] = {
            value: (acc[isoDate]?.value || 0) + item.totalValue,
            formattedDate,
          };
          return acc;
        }, {});
        
        const incomingData = Object.entries(groupedData).map(([isoDate, { value, formattedDate }]) => ({
          isoDate,
          formattedDate,
          value,
        }));
        
        setIncomingSummary({
          total: totalIncoming,
          totalCancelled: totalCancelled,
          data: incomingData,
        });
      } catch (error) {
        setIncomingSummary({
          total: 0,
          totalCancelled: 0,
          data: {},
        });
        console.error('Erro ao buscar notas de saída:', error);
      } finally {
        setLoadingIncomingSummary(false);
      }
    }, [fetchInvoiceSummary]);

    const fetchSalesCategorySummary = useCallback(async () => {
      setLoadingSalesCategorySummary(true);
      try {
        let response = await axios.get(`/api/dashboards/invoices/summary/current-month/sales-category`);

        if (!response || !response?.data) {
          if (!response) {
            response = {
              data: []
            }
          } else {
            response.data = [];
          }
        }

        const data = response.data;
  
        // Processando os dados da API
        const categories = data.map(item => item.category);
        const values = data.map(item => item.totalValue);
  
        // Atualizando as opções e os dados do gráfico
        setCategorySalesOptions({
          chart: {
            type: 'donut',
            height: 400,
            toolbar: {
              show: true,
              tools: {
                download: true,
              },
            },
          },
          labels: categories || [],
          tooltip: {
            y: {
              formatter: function(val) {
                return Number(val)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
              },
            },
            x: {
              formatter: function(_, opts) {
                // Usa o nome completo no tooltip
                return categories[opts.dataPointIndex];
              },
            },
          },
          colors: [
            '#FF8A80', '#FFAB91', '#FFD54F', '#AED581', '#64B5F6',
            '#9575CD', '#F06292', '#FFB74D', '#4FC3F7', '#BA68C8',
            '#81C784', '#F4A460', '#90CAF9', '#E57373', '#A5D6A7'
          ],
          title: {
            text: 'Vendas por Categoria',
            align: 'left',
            style: { fontSize: '18px', fontWeight: 'bold', color: '#6B6B6B' },
          },
          legend: {
            show: true,
            position: 'left',
            offsetY: 30,
            itemMargin: {
              vertical: 5,
            },
            formatter: function (label) {
              return label.length > 12 ? label.slice(0, 12) + '...' : label;
            },
          },
          responsive: [
            {
              breakpoint: 768,
              options: {
                legend: {
                  show: false,
                },
                chart: {
                  height: 380,
                },
              },
            },
          ],
        });
        setCategorySalesSeries(values || []);
      } catch (error) {
        setCategorySalesSeries([]);
        console.error('Erro ao buscar vendas por categoria:', error);
      } finally {
        setLoadingSalesCategorySummary(false);
      }
    }, []);

    const fetchBestSellersSummary = useCallback(async () => {
      setLoadingBestSellersSummary(true);

      try {
        let response = await axios.get(`/api/dashboards/invoices/summary/current-month/best-sellers`);

        if (!response || !response?.data) {
          if (!response) {
            response = {
              data: []
            }
          } else {
            response.data = [];
          }
        }

        const data = response.data || [];

        // Processando os dados da API
        const products = data.length? data.map(item => item.product || 'Produto não especificado'): [];
        const values = data.length? data.map(item => item.totalValue || 0): [];
        const units = data.map(item => item.unit || 'un');

        const base = 160;
        const decremento = 20;
        const funnelValues = values.map((val, index) => {
          return base - (index * decremento);
        });
    
        // Atualizando as opções e os dados do gráfico
        setBestSellersOptions({
          chart: {
            type: 'bar',
            height: 400,
            dropShadow: {
              enabled: true,
            },
            animations: {
              enabled: false,
              easing: 'easein', // Você pode testar outros valores: 'linear', 'easein', 'easeout', 'easeinout'.
              speed: 130, // Velocidade da animação em milissegundos
              animateGradually: {
                enabled: false/*,
                delay: 150*/ // Atraso entre a animação de um ponto para o outro
              },
              dynamicAnimation: {
                enabled: true,
                speed: 150
              }
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
              barHeight: '80%',
              isFunnel: true,
            },
          },
          funnel: {
            shape: 'linear',
          },
          colors: [
            '#F44F5E', '#E55A89', '#D863B1', '#CA6CD8',
            '#B57BED', '#8D95EB', '#62ACEA', '#4BC3E6'
          ],
          dataLabels: {
            enabled: true,
            dropShadow: {
              enabled: true,
            },
            formatter: function (val, opt) {
              const label = opt.w.globals.labels[opt.dataPointIndex];
              if (!label) return 'N/A';
          
              // Array de tamanhos máximos para cada índice (até 8 barras)
              const maxLengths = [41, 37, 32, 22, 19, 11, 7, 4];
          
              // Caso haja menos de 8 barras, garantindo que não ocorram erros.
              // Se o índice ultrapassar o tamanho do array, use o último valor do array:
              const index = Math.min(opt.dataPointIndex, maxLengths.length - 1);
              const maxLength = maxLengths[index];
          
              const truncatedLabel = label.length > maxLength ? label.slice(0, maxLength) + '...' : label;
              return truncatedLabel;
            }
          },
          title: {
            text: 'Produtos Mais Vendidos',
            align: 'left',
            style: { fontSize: '18px', fontWeight: 'bold', color: '#6B6B6B' },
          },
          xaxis: {
            categories: products || [],
          },
          tooltip: {
            y: {
              formatter: (val, { w, dataPointIndex }) => {
                const unidade = units[dataPointIndex] || 'un';
                const valorOriginal = values[dataPointIndex] || 0;
                return `${valorOriginal} ${unidade}`;
              },
            },
          },
          legend: {
            show: false,
          },
        });

        // Primeiro, defina o chart com dados vazios
        setBestSellersSeries([{ name: 'Quantidade', data: [] }]);

        // Depois de um pequeno atraso, atualize com os dados reais
        setTimeout(() => {
          setBestSellersSeries([
            {
              name: 'Quantidade',
              data: funnelValues || [],
            },
          ]);
        }, 100);
      } catch (error) {
        setBestSellersSeries([]);
        console.error('Erro ao buscar produtos mais vendidos:', error);
      } finally {
        setLoadingBestSellersSummary(false);
      }
    }, []);

    const fetchTop10CustomersSummary = useCallback(async () => {
      setLoadingTop10CustomersSummary(true);
      try {
        let response = await axios.get(`/api/dashboards/invoices/summary/current-month/top-customers`);

        if (!response || !response?.data) {
          if (!response) {
            response = {
              data: []
            }
          } else {
            response.data = [];
          }
        }
        
        const data = response.data;
  
        // Processando os dados da API
        const customers = data.map(item => item.customer?.toUpperCase());
        const values = data.map(item => item.totalValue);
  
        // Atualizando as opções e os dados do gráfico
        setTop10CustomersOptions({
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '20px'
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: customers,
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return Number(val)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
              },
            },
            x: {
              formatter: function(_, opts) {
                // Usa o nome completo no tooltip
                return customers[opts.dataPointIndex]?.toUpperCase();
              },
            },
          },
          colors: ['#4BC3E6'],
          title: {
            text: 'Top 10 Clientes',
            style: { fontSize: '18px', fontWeight: 'bold', color: '#6B6B6B' },
          },
        });
  
        setTop10CustomersSeries([{
          name: 'Total',
          data: values
        }]);
      } catch (error) {
        setTop10CustomersSeries([]);
        console.error('Erro ao buscar os clientes top 10:', error);
      } finally {
        setLoadingTop10CustomersSummary(false);
      }
    }, []);

    const fetchTop10SuppliersSummary = useCallback(async () => {
      setLoadingTop10SuppliersSummary(true);
      try {
        let response = await axios.get(`/api/dashboards/invoices/summary/current-month/top-suppliers`);

        if (!response || !response?.data) {
          if (!response) {
            response = {
              data: []
            }
          } else {
            response.data = [];
          }
        }

        const data = response.data;
  
        // Processando os dados da API
        const suppliers = data.map(item => item.supplier?.toUpperCase());
        const values = data.map(item => item.totalValue);
  
        // Atualizando as opções e os dados do gráfico
        setTop10SuppliersOptions({
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '20px'
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: suppliers,
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return Number(val)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
              },
            },
            x: {
              formatter: function(_, opts) {
                // Usa o nome completo no tooltip
                return suppliers[opts.dataPointIndex]?.toUpperCase();
              },
            },
          },
          colors: ['#4BC3E6'],
          title: {
            text: 'Top 10 Fornecedores',
            style: { fontSize: '18px', fontWeight: 'bold', color: '#6B6B6B' },
          },
        });
  
        setTop10SuppliersSeries([{
          name: 'Total',
          data: values
        }]);
      } catch (error) {
        setTop10SuppliersSeries([]);
        console.error('Erro ao buscar os clientes top 10:', error);
      } finally {
        setLoadingTop10SuppliersSummary(false);
      }
    }, []);
   
    useEffect(() => {
      if (axiosConfigured && !hasFetched.current) {
        verifySession();
        fetchOutgoingInvoice();
        fetchIncomingInvoice();
        fetchSalesCategorySummary();
        fetchBestSellersSummary();
        fetchTop10CustomersSummary();
        fetchTop10SuppliersSummary();
        hasFetched.current = true;
        window.dispatchEvent(new Event('resize'));
      }
    }, [axiosConfigured, fetchOutgoingInvoice, fetchIncomingInvoice, fetchSalesCategorySummary, fetchBestSellersSummary, fetchTop10CustomersSummary, fetchTop10SuppliersSummary, verifySession]);
    
  
    const dateSeries = generateDateSeries(24);
  
    const sparkOptions = {
      chart: {
        type: 'area',
        height: 160,
        sparkline: { enabled: true },
      },
      stroke: { curve: 'straight' },
      fill: { opacity: 1 },
      xaxis: { 
        type: 'datetime', 
        categories: dateSeries,
        labels: { show: false }
      },
      tooltip: {
        x: {
          format: 'dd MMM'
        }
      },
      colors: ['#DCE6EC'],
    };
  
    return (
      <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setInProp(true)}>
      <div id="wrapper">
        <div className="content-area">
          <div className="container-fluid">
            <h3 className="text-right mt-3 mb-3">Resumo do Mês Atual</h3>
            {auth.readOnlyMode && (
              <div className="alert alert-danger"><strong>ATENÇÃO:</strong> Não identificamos o pagamento da sua assinatura. Regularize a situação para usar o sistema normalmente, por enquanto, o acesso será somente para leitura.<Link className='ms-2' to="/billings">Clique aqui</Link> e acesse suas faturas.</div>
            )}
            <div className="main">
              <div className="row sparkboxes mt-4 mb-0">
                <div className="col-md-6">
                  <div className="box box1">
                      {loadingOutgoingSummary ? (
                        <div className="spinner-container-dash-invoice-header">
                          <div className="spinner-dash-invoice-header"></div>
                          <p>Carregando dados...</p>
                        </div>
                      ) : (
                        <div style={{ position: 'relative', width: '100%' }}>
                          {/* Título e subtítulo no lado esquerdo */}
                          <div style={{ position: 'absolute', top: '0px', left: '20px', textAlign: 'left' }}>
                            <p style={{ margin: 0, fontSize: '22px', fontWeight: 'bold', color: '#6B6B6B' }}>
                              {outgoingSummary !== null && outgoingSummary !== undefined
                                ? outgoingSummary.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                : 'N/A'}
                            </p>
                            <h4 style={{ margin: 0, fontSize: '14px', fontWeight: 'normal', color: '#6B6B6B' }}>Notas de saída</h4>
                          </div>

                          {/* Título e subtítulo no lado direito */}
                          <div style={{ position: 'absolute', top: '0px', right: '20px', textAlign: 'right' }}>
                          <p style={{ margin: 0, fontSize: '22px', fontWeight: 'bold', color: '#6B6B6B' }}>
                              {outgoingSummary !== null && outgoingSummary !== undefined
                                ? outgoingSummary.totalCancelled?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                : 'N/A'}
                            </p>
                            <h4 style={{ margin: 0, fontSize: '14px', fontWeight: 'normal', color: '#6B6B6B' }}>Canceladas</h4>
                          </div>
                          <Chart
                            options={{
                              ...sparkOptions,
                              colors: ['#008FFB'],
                              xaxis: {
                                type: 'datetime',
                              },
                              title: {
                                text: outgoingSummary !== null && outgoingSummary !== undefined 
                                  ? outgoingSummary.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
                                  : 'N/A',
                                offsetX: 30,
                                style: { fontSize: '24px', fontWeight: 'bold', color: 'transparent' },
                              },
                              subtitle: {
                                text: 'Notas de saída',
                                offsetX: 30,
                                style: { fontSize: '14px', color: 'transparent' },
                              },
                              tooltip: {
                                x: {
                                  formatter: (val, opts) => {
                                    const index = opts.dataPointIndex;
                                    const isoDate = outgoingSummary.data[index]?.isoDate;
                                    if (isoDate) {
                                      const date = new Date(isoDate);
                                      return date.toLocaleDateString('pt-BR', {
                                        day: '2-digit',
                                        month: 'short',
                                      });
                                    }
                                    return val;
                                  },
                                },
                                y: {
                                  formatter: (val) => {
                                    return val.toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL',
                                    });
                                  },
                                },
                              },
                            }}
                            series={[
                              {
                                name: 'Notas de saída',
                                data: outgoingSummary.data.map(d => [d.isoDate, d.value]),
                              },
                            ]}
                            type="area"
                            height={160}
                          />
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 col-md-6 mt-3 mt-md-0">
                  <div className="box box2">
                  {loadingIncomingSummary ? (
                        <div className="spinner-container-dash-invoice-header">
                          <div className="spinner-dash-invoice-header"></div>
                          <p>Carregando dados...</p>
                        </div>
                      ) : (
                        <div style={{ position: 'relative', width: '100%' }}>
                          {/* Título e subtítulo no lado esquerdo */}
                          <div style={{ position: 'absolute', top: '0px', left: '20px', textAlign: 'left' }}>
                            <p style={{ margin: 0, fontSize: '22px', fontWeight: 'bold', color: '#6B6B6B' }}>
                              {incomingSummary !== null && incomingSummary !== undefined
                                ? incomingSummary.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                : 'N/A'}
                            </p>
                            <h4 style={{ margin: 0, fontSize: '14px', fontWeight: 'normal', color: '#6B6B6B' }}>Notas de entrada</h4>
                          </div>

                          {/* Título e subtítulo no lado direito */}
                          <div style={{ position: 'absolute', top: '0px', right: '20px', textAlign: 'right' }}>
                          <p style={{ margin: 0, fontSize: '22px', fontWeight: 'bold', color: '#6B6B6B' }}>
                              {incomingSummary !== null && incomingSummary !== undefined
                                ? incomingSummary.totalCancelled?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                : 'N/A'}
                            </p>
                            <h4 style={{ margin: 0, fontSize: '14px', fontWeight: 'normal', color: '#6B6B6B' }}>Canceladas</h4>
                          </div>
                          <Chart
                            options={{
                              ...sparkOptions,
                              colors: ['#008FFB'],
                              xaxis: {
                                type: 'datetime',
                              },
                              title: {
                                text: incomingSummary !== null && incomingSummary !== undefined 
                                  ? incomingSummary.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
                                  : 'N/A',
                                offsetX: 30,
                                style: { fontSize: '24px', fontWeight: 'bold', color: 'transparent'},
                              },
                              subtitle: {
                                text: 'Notas de entrada',
                                offsetX: 30,
                                style: { fontSize: '14px', color: 'transparent' },
                              },
                              tooltip: {
                                x: {
                                  formatter: (val, opts) => {
                                    const index = opts.dataPointIndex;
                                    const isoDate = incomingSummary.data[index]?.isoDate;
                                    if (isoDate) {
                                      const date = new Date(isoDate);
                                      return date.toLocaleDateString('pt-BR', {
                                        day: '2-digit',
                                        month: 'short',
                                      });
                                    }
                                    return val;
                                  },
                                },
                                y: {
                                  formatter: (val) => {
                                    return val.toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL',
                                    });
                                  },
                                },
                              },
                            }}
                            series={[
                              {
                                name: 'Notas de entrada',
                                data: incomingSummary.data.map(d => [d.isoDate, d.value]),
                              },
                            ]}
                            type="area"
                            height={160}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
  
              {/* Gráfico de vendas por categoria e funil de produtos mais vendidos */}
              <div className="row mt-4 mb-4">
                <div className="col-md-6">
                  <div className="box chart-container">
                    {loadingSalesCategorySummary ? (
                        <div className="spinner-container-dash-invoice-400">
                          <div className="spinner-dash-invoice-header"></div>
                          <p>Carregando dados...</p>
                        </div>
                      ) : categorySalesSeries.length > 0 ? (
                        <Chart
                          options={categorySalesOptions}
                          series={categorySalesSeries}
                          type="donut"
                          height={400}
                        />
                      ) : (
                        <div className="spinner-container-dash-invoice-400">
                          <p>Não há dados para exibir</p>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="box chart-container">
                    {loadingBestSellersSummary ? (
                      <div className="spinner-container-dash-invoice-400">
                        <div className="spinner-dash-invoice-header"></div>
                        <p>Carregando dados...</p>
                      </div>
                    ) : bestSellersSeries[0]?.data?.length > 0 ? (
                      <Chart
                        options={bestSellersOptions}
                        series={bestSellersSeries}
                        type="bar"
                        height={400}
                      />
                    ) : (
                      <div className="spinner-container-dash-invoice-400">
                        <p>Não há dados para exibir</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
  
              {/* Gráfico de top 10 clientes e fornecedores */}
              <div className="row mt-4 mb-4">
                <div className="col-md-6">
                  <div className="box">
                    {loadingTop10CustomersSummary ? (
                      <div className="spinner-container-dash-invoice-350">
                        <div className="spinner-dash-invoice-header"></div>
                        <p>Carregando dados...</p>
                      </div>
                    ) : top10CustomersSeries[0]?.data?.length > 0 ? (
                      <Chart
                        options={top10CustomersOptions}
                        series={top10CustomersSeries}
                        type="bar"
                        height={350}
                      />
                    ) : (
                      <div className="spinner-container-dash-invoice-350">
                        <p>Não há dados para exibir</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="box">
                    {loadingTop10SuppliersSummary ? (
                      <div className="spinner-container-dash-invoice-350">
                        <div className="spinner-dash-invoice-header"></div>
                        <p>Carregando dados...</p>
                      </div>
                    ) : top10SuppliersSeries[0]?.data?.length > 0 ? (
                      <Chart
                        options={top10SuppliersOptions}
                        series={top10SuppliersSeries}
                        type="bar"
                        height={350}
                      />
                    ) : (
                      <div className="spinner-container-dash-invoice-350">
                        <p>Não há dados para exibir</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </CSSTransition>
    );
  }
  
  export default Home;
  