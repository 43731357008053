import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from './AxiosInstance';
import '../assets/styles/CompanyName.css';
import { AuthContext } from './AuthContext';
import '../assets/styles/App.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons'; // Placeholder icon for companies

function CompanySelection() {
  const [companies, setCompanies] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { auth, setAuth, axiosConfigured } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    // Aguarda até que o contexto de autenticação esteja totalmente carregado
    if (!isAuthChecked && axiosConfigured) {
      if (!auth.token || !auth.loginUser) {
        navigate('/login');
      } else {
        setIsAuthChecked(true);
      }
      return;
    }

    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`/api/users/companies/${auth.loginUser}`);
        setCompanies(response.data);
      } catch (error) {
        setErrorMessage('Erro ao carregar as empresas. Por favor, tente novamente mais tarde.');
        console.error('Erro ao buscar empresas', error);
      }
    };

    if (isAuthChecked) {
      fetchCompanies();
    }
  }, [auth, navigate, isAuthChecked, axiosConfigured]);

  const handleCompanySelection = async (companyId) => {
    try {
      const response = await axios.post(`/api/users/companies/selection/${companyId}`);
      const token = response.data.token;

      localStorage.setItem('token', token);
      const decodedToken = jwtDecode(token);

      setAuth({
        token,
        roles: decodedToken.roles || [],
        loginUser: decodedToken.loginUser || '',
        cellPhone: decodedToken.cellPhone || '',
        userName: decodedToken.userName || '',
        companyId: decodedToken.companyId || '',
        companyName: decodedToken.companyName || '',
        expired: false,
        intentionalLogout: false,
      });

      navigate('/home');
    } catch (error) {
      setErrorMessage('Erro ao selecionar a empresa. Por favor, tente novamente.');
      console.error('Erro ao selecionar empresa', error);
    }
  };

  return (
    <section className="vh-100">
      <div className="container-login py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: '1rem', height: '100%' }}>
              <div className="row g-0 h-100">
                <div className="col-md-12 col-lg-12 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    <div className="d-flex align-items-center mb-3 pb-1">
                      <span className="h4 fw-bold mb-0">Seleção de Conta/Loja</span>
                    </div>

                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                    <div className="company-list mb-4">
                      {companies.map(company => (
                        <div 
                          key={company.id} 
                          className="company-item d-flex align-items-center p-2 mb-2 border rounded"
                          onClick={() => handleCompanySelection(company.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon icon={faBuilding} size="2x" className="me-3" /> {/* Placeholder icon */}
                          <span className="company-name-selection">{company.legalName}</span>
                        </div>
                      ))}
                    </div>

                    <div className="pt-1 mb-4">
                      <p className="text-muted">Clique em uma empresa para selecionar.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CompanySelection;
