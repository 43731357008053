import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyInput from 'react-currency-input-field';
import { faSave, faPlus, faPencil, faTrash, faCopy, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import ReactInputMask from 'react-input-mask';
import InfoPopover from './InfoPopover';
import AsyncSelect2 from './AsyncSelect2';
import '../assets/styles/TaxRule.css';

function TaxRuleEdit() {
    const {setAlert, axiosConfigured, auth, hasRequiredRoleDfe} = useContext(AuthContext);
    const readOnlyMode = auth.readOnlyMode;
    const [formData, setFormData] = useState({
        name: '',
        active: 'true',
        taxRuleDetails: []
    });
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formDetailErrors, setFormDetailErrors] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { taxRule: initialTaxRule, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    //const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [taxRule, setTaxRule] = useState(initialTaxRule || {});
    const [taxRuleDetails, setTaxRuleDetails] = useState(taxRule.taxRuleDetails || []);
    const [editingTaxRuleDetail, setEditingTaxRuleDetail] = useState(null);
    const [taxRuleDetailRestore, setTaxRuleDetailRestore] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);
    const natureOperationDescriptions = {
        'TODAS': 'Todas as Naturezas de Operações',
        'VENDA_MERCADORIA': 'Venda de mercadoria adquirida ou recebida de terceiros',
        'VENDA_PROD_ESTABELECIMENTO': 'Venda de produção do estabelecimento',
        'VENDA_ATIVO_IMOBILIZADO': 'Venda de ativo imobilizado',
        'VENDA_MERCADORIA_ENTREGA_FUTURA': 'Venda de mercadoria para entrega futura',
        'VENDA_ORDEM': 'Venda à ordem',
        'VENDA_CONSUMIDOR_FINAL': 'Venda para consumidor final',
        'REMESSA_ATIVO_IMOBILIZADO': 'Remessa de ativo imobilizado',
        'RETORNO_ATIVO_IMOBILIZADO': 'Retorno de ativo imobilizado',
        'REMESSA_INDUSTRIALIZACAO': 'Remessa para industrialização',
        'RETORNO_INDUSTRIALIZACAO': 'Retorno de remessa da industrialização',
        'REMESSA_DEMONSTRACAO': 'Remessa para demonstração',
        'RETORNO_DEMONSTRACAO': 'Retorno de remessa de demonstração',
        'REMESSA_CONSIGNACAO': 'Remessa para consignação',
        'RETORNO_CONSIGNACAO': 'Retorno de remessa de consignação',
        'REMESSA_CONSERTO_REPARO': 'Remessa para conserto ou reparo',
        'RETORNO_CONSERTO_REPARO': 'Retorno de remessa de conserto ou reparo',
        'NOTA_ENTRADA_POR_AJUSTE': 'Nota de entrada para ajuste',
        'VENDA_EXPORTACAO': 'Venda para exportação',
        'DEVOLUCAO_VENDA': 'Devolução de venda',
        'DEVOLUCAO_COMPRA': 'Devolução de compra',
        'BONIFICACAO_DOACAO_BRINDES': 'Bonificação, doação ou brindes',
        'VENDA_ZONA_FRANCA_MANAUS': 'Venda para Zona Franca de Manaus'
    };
    const provinceDescriptions = {
        'TODOS': 'Todos os estados',
        'AC': 'AC',
        'AL': 'AL',
        'AP': 'AP',
        'AM': 'AM',
        'BA': 'BA',
        'CE': 'CE',
        'DF': 'DF',
        'ES': 'ES',
        'GO': 'GO',
        'MA': 'MA',
        'MT': 'MT',
        'MS': 'MS',
        'MG': 'MG',
        'PA': 'PA',
        'PB': 'PB',
        'PR': 'PR',
        'PE': 'PE',
        'PI': 'PI',
        'RJ': 'RJ',
        'RN': 'RN',
        'RS': 'RS',
        'RO': 'RO',
        'RR': 'RR',
        'SC': 'SC',
        'SP': 'SP',
        'SE': 'SE',
        'TO': 'TO'
    };
    let havePermission = hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_MANAGER');

    //Tratamento para a página, como a página não é visivel mas a api responde no get devidos aos campos de seleção, existe esse tratamento
    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current) {
            if (!havePermission && !readOnlyMode) {
                navigate("/home");
            }
        }
    }, [axiosConfigured, havePermission, readOnlyMode, navigate]);

    havePermission = havePermission && !readOnlyMode;

    const initializeForm = useCallback((taxRule) => {
        setTaxRule(taxRule);

        const taxRuleDetails = taxRule.taxRuleDetails || [];

        const updatedTaxRuleDetails = taxRuleDetails.map(detail => ({
            ...detail,
            csosn: extractCodeFromDescription(detail.csosn),
            icmsOrigin: extractCodeFromDescription(detail.icmsOrigin),
            pisSituation: extractCodeFromDescription(detail.pisSituation),
            cofinsSituation: extractCodeFromDescription(detail.cofinsSituation),
            ipiSituation: extractCodeFromDescription(detail.ipiSituation),
            icmsStModality: extractCodeFromDescription(detail.icmsStModality),
            icmsModality: extractCodeFromDescription(detail.icmsModality)
        }));

        setTaxRuleDetails(updatedTaxRuleDetails);

        setFormData({
            name: taxRule.name,
            active: taxRule.active ? 'true' : 'false'
        });
    }, []);

    const loadTaxRule = useCallback(async () => {
        try {
            const response = await axios.get(`/api/tax-rules/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar regra fiscal:', error);
            if (error.response && error.response.status === 404) {
                navigate('/tax-rules', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, searchTerm, page, rows, sortField, sortOrder, initializeForm]);

    useEffect(() => {
        if ((havePermission || readOnlyMode) && axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadTaxRule();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setShowForm(true);
        }
    }, [axiosConfigured, id, havePermission, readOnlyMode, loadTaxRule]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let parsedValue;

        if (name === 'active') {
            parsedValue = value === 'true';
        } else {
            parsedValue = value;
        }
    
        setFormData((prevData) => {
            /*const isChanged = prevData[name] !== parsedValue;
            if (isChanged) {
                setIsFormChanged(true);
            }*/
    
            const updatedData = { ...prevData, [name]: parsedValue };
    
            return updatedData;
        });
    };

    const getFieldsByCsosn = (csosnValue) => {
        switch (csosnValue) {
            case '101':
                return ['icmsCreditPercentage'];
            case '201':
                return ['icmsCreditPercentage', 'icmsStModality', 'icmsStAliquota', 'icmsStBaseReduction', 'icmsStMarginValueAdded', 'fcpStPercentage'];
            case '202':
            case '203':
                return ['icmsStModality', 'icmsStAliquota', 'icmsStBaseReduction', 'icmsStMarginValueAdded', 'fcpStPercentage'];
            case '500':
                return ['icmsEffectivePercentage', 'fcpRetainedPercentage', 'icmsStRetainedPercentage', 'icmsEffectiveBaseReduction'];
            case '900':
                return ['icmsCreditPercentage', 'icmsModality', 'icmsAliquota', 'icmsBaseReduction', 'icmsMarginValueAdded', 'icmsFixedPrice', 'icmsStModality', 'icmsStAliquota', 'icmsStBaseReduction', 'icmsStMarginValueAdded', 'icmsStFixedPrice', 'fcpStPercentage'];
            default:
                return [];
        }
    };
    
    const clearUnusedFields = (data, csosn) => {
        const fieldsToKeep = getFieldsByCsosn(csosn);
        const allFields = [
            'icmsCreditPercentage',
            'icmsModality',
            'icmsAliquota',
            'icmsBaseReduction',
            'icmsStMarginValueAdded',
            'icmsFixedPrice',
            'icmsStModality',
            'icmsStAliquota',
            'icmsStBaseReduction',
            'icmsStMarginValueAdded',
            'icmsStFixedPrice',
            'fcpStPercentage',
            'icmsEffectivePercentage',
            'fcpRetainedPercentage',
            'icmsStRetainedPercentage',
            'icmsEffectiveBaseReduction'
        ];
        const fieldsToClear = allFields.filter(field => !fieldsToKeep.includes(field));
        const clearedData = { ...data };
        fieldsToClear.forEach(field => {
            clearedData[field] = '';
        });
        return clearedData;
    };

    const validateRequiredFields = () => {
        const errors = {};
    
        if (!formData.name) errors.name = true;

        const countErros = Object.keys(errors).length;

        if (countErros > 0) {
            setAlert({ type: 'danger', message: 'Campos obrigatórios não preenchidos' });
        }
        
        if (taxRuleDetails.length === 0) {
            if (Object.keys(errors).length === 0) {
                errors.emptyConfig = true;
                setAlert({ type: 'danger', message: 'Adicione pelo menos uma configuração para salvar' });
            }
        }
    
        return errors;
    };

    const isInvalidValue = (value) => {
        return value === null || value === undefined || value === "" || isNaN(value);
    };
    
    const validateRequiredDetailFields = (taxRuleDetail, taxRuleDetails) => {
        const errors = {};
    
        if (!taxRuleDetail.natureOperation) errors.natureOperation = true;
        if (!taxRuleDetail.province) errors.province = true;
        if (!taxRuleDetail.cfop) errors.cfop = true;
        if (!taxRuleDetail.ncm) errors.ncm = true;
        if (!taxRuleDetail.csosn) errors.csosn = true;
        if (!taxRuleDetail.icmsOrigin) errors.icmsOrigin = true;
        if (!taxRuleDetail.pisSituation) errors.pisSituation = true;
        if (!taxRuleDetail.cofinsSituation) errors.cofinsSituation = true;
        if (!taxRuleDetail.ipiSituation) errors.ipiSituation = true;
        if (!taxRuleDetail.ipiCode) errors.ipiCode = true;
        if (isInvalidValue(taxRuleDetail.pisAliquota)) errors.pisAliquota = true;
        if (isInvalidValue(taxRuleDetail.ipiAliquota)) errors.ipiAliquota = true;
        if (isInvalidValue(taxRuleDetail.cofinsAliquota)) errors.cofinsAliquota = true;

        if (taxRuleDetail.csosn) {
            switch (taxRuleDetail.csosn) {
                case '101':
                    if (isInvalidValue(taxRuleDetail.icmsCreditPercentage)) errors.icmsCreditPercentage = true;
                    break;
                case '201':
                    if (isInvalidValue(taxRuleDetail.icmsCreditPercentage)) errors.icmsCreditPercentage = true;
                    if (isInvalidValue(taxRuleDetail.icmsStModality)) errors.icmsStModality = true;
                    if (isInvalidValue(taxRuleDetail.icmsStAliquota)) errors.icmsStAliquota = true;
                    if (isInvalidValue(taxRuleDetail.icmsStBaseReduction)) errors.icmsStBaseReduction = true;
                    if (isInvalidValue(taxRuleDetail.icmsStMarginValueAdded)) errors.icmsStMarginValueAdded = true;
                    if (isInvalidValue(taxRuleDetail.fcpStPercentage)) errors.fcpStPercentage = true;
                    break;
                case '202':
                case '203':
                    if (isInvalidValue(taxRuleDetail.icmsStModality)) errors.icmsStModality = true;
                    if (isInvalidValue(taxRuleDetail.icmsStAliquota)) errors.icmsStAliquota = true;
                    if (isInvalidValue(taxRuleDetail.icmsStBaseReduction)) errors.icmsStBaseReduction = true;
                    if (isInvalidValue(taxRuleDetail.icmsStMarginValueAdded)) errors.icmsStMarginValueAdded = true;
                    if (isInvalidValue(taxRuleDetail.fcpStPercentage)) errors.fcpStPercentage = true;
                    break;
                case '500':
                    if (isInvalidValue(taxRuleDetail.icmsEffectivePercentage)) errors.icmsEffectivePercentage = true;
                    if (isInvalidValue(taxRuleDetail.fcpRetainedPercentage)) errors.fcpRetainedPercentage = true;
                    if (isInvalidValue(taxRuleDetail.icmsStRetainedPercentage)) errors.icmsStRetainedPercentage = true;
                    if (isInvalidValue(taxRuleDetail.icmsEffectiveBaseReduction)) errors.icmsEffectiveBaseReduction = true;
                    break;
                case '900':
                    if (isInvalidValue(taxRuleDetail.icmsCreditPercentage)) errors.icmsCreditPercentage = true;
                    if (isInvalidValue(taxRuleDetail.icmsModality)) errors.icmsModality = true;
                    if (isInvalidValue(taxRuleDetail.icmsAliquota)) errors.icmsAliquota = true;
                    if (isInvalidValue(taxRuleDetail.icmsBaseReduction)) errors.icmsBaseReduction = true;
                    if (isInvalidValue(taxRuleDetail.icmsMarginValueAdded)) errors.icmsMarginValueAdded = true;
                    //if (isInvalidValue(taxRuleDetail.icmsFixedPrice)) errors.icmsFixedPrice = true;
                    if (isInvalidValue(taxRuleDetail.icmsStModality)) errors.icmsStModality = true;
                    if (isInvalidValue(taxRuleDetail.icmsStAliquota)) errors.icmsStAliquota = true;
                    if (isInvalidValue(taxRuleDetail.icmsStBaseReduction)) errors.icmsStBaseReduction = true;
                    if (isInvalidValue(taxRuleDetail.icmsStMarginValueAdded)) errors.icmsStMarginValueAdded = true;
                    //if (isInvalidValue(taxRuleDetail.icmsStFixedPrice)) errors.icmsStFixedPrice = true;
                    if (isInvalidValue(taxRuleDetail.fcpStPercentage)) errors.fcpStPercentage = true;
                    break;
                default:
                    break;
            }
        }

        const countErros = Object.keys(errors).length;
    
        if (countErros > 0) {
            setAlert({ type: 'danger', message: 'Campos obrigatórios não preenchidos' });
        } else {
            const duplicate = taxRuleDetails.some(detail =>
                detail.natureOperation === taxRuleDetail.natureOperation && detail.province === taxRuleDetail.province && detail.id !== taxRuleDetail.id
            );
    
            if (duplicate) {
                errors.duplicate = true;
                errors.natureOperation = true;
                errors.province = true;
                setAlert({ type: 'danger', message: 'Já existe uma configuração com a mesma Natureza da Operação e Estado' });
            }
        }

        //console.log(errors);
        
        return errors;
    };

    const handleCurrencyChange = (value, name, percent) => {
        if (percent === true) {
            if (value < 0 || value > 100) {
                return;
            }
        }

        setEditingTaxRuleDetail((prevDetail) => {
            const updatedDetail = { ...prevDetail, [name]: value };
            return updatedDetail;
        });
    };

    const adjustDecimal = (event, prefix) => {
        const { name, value } = event.target;
        setEditingTaxRuleDetail((prevDetail) => {
            let parsedValue = value.toString();

            if (prefix) {
                parsedValue = parsedValue? parsedValue.replace(prefix, ''): '';
            }

            parsedValue = parsedValue? parsedValue.replaceAll('.', ''): '';
            parsedValue = parsedValue? parsedValue.replace(',', '.'): '';
            parsedValue = parsedValue? parseFloat(parsedValue).toFixed(2): '';

            const updatedDetail = { ...prevDetail, [name]: parsedValue? parsedValue: '' };
            return updatedDetail;
        });
    }

    const handleTaxRuleDetailInputChange = (e) => {
        const { name, value } = e.target;
        let cleanIcmsFixedPrice = false;
        let cleanIcmsStFixedPrice = false;

        // Limitar o valor de 'quantidadeSelo' a 12 dígitos
        if (name === 'quantidadeSelo' && value.length > 12) {
            return;
        }

        if (name === 'icmsModality' && value !== '2') {
            cleanIcmsFixedPrice = true;
        }

        if (name === 'icmsStModality' && value !== '0') {
            cleanIcmsStFixedPrice = true;
        }
    
        let parsedValue;
        if (name === 'active') {
            parsedValue = value === 'true';
        } else {
            parsedValue = value;
        }
    
        setEditingTaxRuleDetail((prevDetail) => {
            let updatedDetail = { ...prevDetail, [name]: parsedValue };
    
            if (name === 'csosn') {
                return clearUnusedFields(updatedDetail, parsedValue);
            } else if (cleanIcmsFixedPrice) {
                updatedDetail.icmsFixedPrice = '';
            } else if (cleanIcmsStFixedPrice) {
                updatedDetail.icmsStFixedPrice = '';
            }
    
            return updatedDetail;
        });
    };

    const extractCodeFromDescription = (description) => {
        if (description) {
            const codeMatch = description.match(/^\d+/);
            return codeMatch ? codeMatch[0] : '';
        }
        return '';
    };

    const extractDescription = (description) => {
        if (description) {
            const index = description.indexOf('-');
            return index !== -1 ? description.substring(index + 1).trim() : '';
        }
        return '';
    };

    const handleNumber = (e) => {
        if (isNaN(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Tab') {
            e.preventDefault();
        }
    };

    const handleCfopChange = (selectedOption) => {
        const selectedCfop = selectedOption ? {
            cfopCode: selectedOption.value,
            description: extractDescription(selectedOption.label)
        } : null;
    
        setEditingTaxRuleDetail((prevDetail) => {
            /*const isChanged = prevDetail.cfop !== selectedCfop;
            if (isChanged) {
                setIsFormChanged(true);
            }*/
            return { ...prevDetail, cfop: selectedCfop };
        });
    };
    
    const handleNcmChange = (selectedOption) => {
        const selectedNcm = selectedOption ? {
            ncmCode: selectedOption.value,
            description: extractDescription(selectedOption.label)
        } : null;
    
        setEditingTaxRuleDetail((prevDetail) => {
            /*const isChanged = prevDetail.ncm !== selectedNcm;
            if (isChanged) {
                setIsFormChanged(true);
            }*/
            return { ...prevDetail, ncm: selectedNcm };
        });
    };
    
    const handleCestChange = (selectedOption) => {
        const selectedCest = selectedOption ? {
            cestCode: selectedOption.value,
            description: extractDescription(selectedOption.label)
        } : null;
    
        setEditingTaxRuleDetail((prevDetail) => {
            /*const isChanged = prevDetail.cest !== selectedCest;
            if (isChanged) {
                setIsFormChanged(true);
            }*/
            return { ...prevDetail, cest: selectedCest };
        });
    };   

    function removeFormattingCnpj(value) {
        if (!value) {
            return '';
        }
        return value.replace(/[^\d]/g, '');
    }

    const handleSave = async () => {
        setIsSaving(true);

        try {
            const errors = validateRequiredFields();
    
            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
                return;
            }
        
            setFormErrors({});

            const taxRuleDetailsToSend = taxRuleDetails.map(taxRuleDetail => {
                const isTemporaryId = typeof taxRuleDetail.id === 'number' && taxRuleDetail.id > 1e12;
        
                if (isTemporaryId) {
                    const { id, ...rest } = taxRuleDetail;
                    return rest;
                }

                taxRuleDetail.cnpjProdutor = removeFormattingCnpj(taxRuleDetail.cnpjProdutor);

                return taxRuleDetail;
            });
    
            const dataToSend = {
                ...formData,
                taxRuleDetails: taxRuleDetailsToSend
            };

            if (taxRule.id) {
                await axios.put(`/api/tax-rules/${taxRule.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Regra fiscal editada com sucesso.' });
            } else {
                await axios.post('/api/tax-rules', dataToSend);
                setAlert({ type: 'success', message: 'Regra fiscal adicionada com sucesso.' });
            }
            setInProp(false);
            setTimeout(() => {
                navigate('/tax-rules', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            //setIsFormChanged(true);
            console.error('Erro ao salvar regra fiscal', error);
        } finally {
            setTimeout(() => {
                setIsSaving(false);
            }, 1000);
        }
    };

    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/tax-rules', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    const handleAddNewTaxRuleDetail = () => {
        setEditingTaxRuleDetail({
            natureOperation: 'TODAS',
            province: 'TODOS',
            csosn: '',
            icmsOrigin: '',
            icmsCreditPercentage: '',
            icmsModality: '',
            icmsAliquota: '',
            icmsBaseReduction: '',
            icmsMarginValueAdded: '',
            icmsFixedPrice: '',
            icmsStModality: '',
            icmsStAliquota: '',
            icmsStBaseReduction: '',
            icmsStMarginValueAdded: '',
            icmsStFixedPrice: '',
            fcpStPercentage: '',
            icmsEffectivePercentage: '',
            fcpRetainedPercentage: '',
            icmsStRetainedPercentage: '',
            icmsEffectiveBaseReduction: '',
            pisAliquota: '',
            pisSituation: '',
            cofinsAliquota: '',
            cofinsSituation: '',
            ipiSituation: '',
            ipiAliquota: '',
            ipiCode: '',
            cnpjProdutor: '',
            codigoSelo: '',
            quantidadeSelo: '',
            codigoBeneficioFiscal: '',
            cfop: null,
            ncm: null,
            cest: null
        });
        setIsEditing(true);
    };

    const handleSaveTaxRuleDetail = () => {
        const errors = validateRequiredDetailFields(editingTaxRuleDetail, taxRuleDetails);
    
        if (Object.keys(errors).length > 0) {
            setFormDetailErrors(errors);
            return;
        }
    
        setFormDetailErrors({});

        if (editingTaxRuleDetail.id) {
            setTaxRuleDetails((prevTaxRuleDetails) =>
                prevTaxRuleDetails.map((taxRuleDetail) =>
                    taxRuleDetail.id === editingTaxRuleDetail.id ? editingTaxRuleDetail : taxRuleDetail
                )
            );
        } else {
            const tempId = Date.now();
            setTaxRuleDetails((prevTaxRuleDetails) => [
                ...prevTaxRuleDetails,
                { ...editingTaxRuleDetail, id: tempId }
            ]);
        }
        setEditingTaxRuleDetail(null);
        setIsEditing(false);
        //setIsFormChanged(true);
    };

    const handleEditTaxRuleDetail = (taxRuleDetail) => {
        const taxRuleDetailCopy = structuredClone(taxRuleDetail);
        setEditingTaxRuleDetail(taxRuleDetail);
        setTaxRuleDetailRestore(taxRuleDetailCopy);
        setIsEditing(true);
        setFormDetailErrors({});
    };
    
    const handleDeleteTaxRuleDetail = (taxRuleDetailId) => {
        setTaxRuleDetails((prevTaxRuleDetails) => prevTaxRuleDetails.filter((taxRuleDetail) => taxRuleDetail.id !== taxRuleDetailId));
        //setIsFormChanged(true);
    };

    const handleCopyConfig = (taxRuleDetail) => {
        const { id, natureOperation, province, ...newTaxRuleDetailCopied } = taxRuleDetail;
    
        setEditingTaxRuleDetail({
            natureOperation: 'TODAS',
            province: 'TODOS',
            csosn: newTaxRuleDetailCopied.csosn || '',
            icmsOrigin: newTaxRuleDetailCopied.icmsOrigin || '',
            icmsCreditPercentage: newTaxRuleDetailCopied.icmsCreditPercentage || '',
            icmsModality: newTaxRuleDetailCopied.icmsModality || '',
            icmsAliquota: newTaxRuleDetailCopied.icmsAliquota || '',
            icmsBaseReduction: newTaxRuleDetailCopied.icmsBaseReduction || '',
            icmsMarginValueAdded: newTaxRuleDetailCopied.icmsMarginValueAdded || '',
            icmsFixedPrice: newTaxRuleDetailCopied.icmsFixedPrice || '',
            icmsStModality: newTaxRuleDetailCopied.icmsStModality || '',
            icmsStAliquota: newTaxRuleDetailCopied.icmsStAliquota || '',
            icmsStBaseReduction: newTaxRuleDetailCopied.icmsStBaseReduction || '',
            icmsStMarginValueAdded: newTaxRuleDetailCopied.icmsStMarginValueAdded || '',
            icmsStFixedPrice: newTaxRuleDetailCopied.icmsStFixedPrice || '',
            fcpStPercentage: newTaxRuleDetailCopied.fcpStPercentage || '',
            icmsEffectivePercentage: newTaxRuleDetailCopied.icmsEffectivePercentage || '',
            fcpRetainedPercentage: newTaxRuleDetailCopied.fcpRetainedPercentage || '',
            icmsStRetainedPercentage: newTaxRuleDetailCopied.icmsStRetainedPercentage || '',
            icmsEffectiveBaseReduction: newTaxRuleDetailCopied.icmsEffectiveBaseReduction || '',
            pisAliquota: newTaxRuleDetailCopied.pisAliquota || '',
            pisSituation: newTaxRuleDetailCopied.pisSituation || '',
            cofinsAliquota: newTaxRuleDetailCopied.cofinsAliquota || '',
            cofinsSituation: newTaxRuleDetailCopied.cofinsSituation || '',
            ipiSituation: newTaxRuleDetailCopied.ipiSituation || '',
            ipiAliquota: newTaxRuleDetailCopied.ipiAliquota || '',
            ipiCode: newTaxRuleDetailCopied.ipiCode || '',
            cnpjProdutor: newTaxRuleDetailCopied.cnpjProdutor || '',
            codigoSelo: newTaxRuleDetailCopied.codigoSelo || '',
            quantidadeSelo: newTaxRuleDetailCopied.quantidadeSelo || '',
            codigoBeneficioFiscal: newTaxRuleDetailCopied.codigoBeneficioFiscal || '',
            cfop: newTaxRuleDetailCopied.cfop || null,
            ncm: newTaxRuleDetailCopied.ncm || null,
            cest: newTaxRuleDetailCopied.cest || null
        });
        setIsEditing(true);
    };
    

    const renderGeneralData = () => (
        <div>
            <div className="mb-3 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                <div className={formErrors.name ? 'mb-3 has-error' : 'mb-3'}>
                    <label className="form-label">Nome {formErrors.name} <span className="text-danger">*</span></label>
                    <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleInputChange}
                        maxLength={60}
                        required
                        autoComplete="off"
                        disabled={!havePermission}
                    />
                </div>
                <div className="mb-0">
                    <label className="form-label">
                        Ativo
                        <InfoPopover
                                content={
                                    <div style={{ textAlign: 'justify' }}>
                                        Se desativado, não permitirá o uso em dessa regra em novas categorias de vendas.
                                    </div>
                                }
                            />{'\u00A0'}<span className="text-danger">*</span>
                    </label>
                    <select 
                        className="form-select"
                        name="active"
                        value={formData.active}
                        onChange={handleInputChange}
                        disabled={!havePermission}
                    >
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>
        </div>
    );

    const renderConfigFields = () => {
        const fields = getFieldsByCsosn(editingTaxRuleDetail?.csosn || '');

        return (taxRuleDetails.length === 0 && !isEditing ? (
            <div className="mb-3 p-3 border rounded">
                <p>Nenhuma configuração adicionada.<br/>Adicione pelo menos uma para Salvar.</p>
                <button type="button" className="btn btn-primary" onClick={handleAddNewTaxRuleDetail} disabled={!havePermission}>
                    Adicionar Configuração
                </button>
            </div>
        ) : (
            <>
                {taxRuleDetails.length > 0 && !isEditing && (
                    <div className="mb-3 p-3 border rounded">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">
                                Configurações
                                <InfoPopover
                                    content={
                                        <div>
                                            Você pode adicionar várias configurações fiscais conforme a Natureza da Operação e o Estado de Destino. Ao optar por "Todos os Estados" ou "Todas as Naturezas", o sistema aplicará automaticamente a configuração mais específica disponível, priorizando combinações exatas de natureza da operação e estado destino. Essa funcionalidade oferece flexibilidade e precisão, garantindo que as regras fiscais sejam aplicadas corretamente para cada cenário operacional do seu negócio.
                                        </div>
                                    }
                                />
                            </h5>
                            <button 
                                type="button" 
                                className="btn btn-primary btn-sm" 
                                onClick={handleAddNewTaxRuleDetail}
                                style={{ marginLeft: 'auto' }}
                                disabled={!havePermission}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>
                        <div className='taxrule-config'>
                            <ul className="list-group mb-3 mt-3">
                                {taxRuleDetails.map((taxRuleDetail, index) => (
                                    <li className="list-group-config d-flex justify-content-between align-items-center" key={taxRuleDetail.id || `new-${index}`}>
                                        {/*<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexGrow: 1 }}>*/}
                                        <div className="text-truncate">
                                            {provinceDescriptions[taxRuleDetail.province]}
                                        </div>
                                        <div className="text-truncate">
                                            {natureOperationDescriptions[taxRuleDetail.natureOperation]}
                                        </div>
                                        <div className="d-flex">
                                            <button
                                                type="button"
                                                className="btn btn-sm border-secondary custom-btn me-2"
                                                title="Alterar configuração"
                                                onClick={() => handleEditTaxRuleDetail(taxRuleDetail)}
                                                disabled={!havePermission}
                                            >
                                                <FontAwesomeIcon icon={faPencil} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-sm border-secondary custom-btn"
                                                title="Excluir configuração"
                                                onClick={() => handleDeleteTaxRuleDetail(taxRuleDetail.id)}
                                                disabled={!havePermission}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-sm border-secondary custom-btn"
                                                title="Copiar configuração"
                                                onClick={() => handleCopyConfig(taxRuleDetail)}
                                                disabled={!havePermission}
                                            >
                                                <FontAwesomeIcon icon={faCopy} />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                {isEditing && (
                    <div>
                        <div className="mb-3 p-3 border rounded">
                            <h5>{editingTaxRuleDetail.id ? 'Editar Configuração' : 'Nova Configuração'}</h5>
                            <div className="mb-3 p-3 border rounded">
                                <h5 style={{marginBottom: 1 + 'em'}}>
                                    Agrupador de operações
                                    <InfoPopover
                                            content={
                                                <div style={{ textAlign: 'justify' }}>
                                                    O agrupador de operações define qual configuração será utilizada pelo sistema conforme a natureza da operação e o estado do cliente/destinatário da nota fiscal.
                                                </div>
                                            }
                                        />
                                </h5>
                                <div className={formDetailErrors.natureOperation ? 'mb-3 has-error' : 'mb-3'}>
                                    <label className="form-label">Natureza da Operação <span className="text-danger">*</span></label>
                                    <select 
                                        className="form-select"
                                        name="natureOperation"
                                        value={editingTaxRuleDetail.natureOperation}
                                        onChange={handleTaxRuleDetailInputChange}
                                        required={true}
                                        disabled={!havePermission}
                                    >
                                        <option value="TODAS">Todas as naturezas</option>
                                        <optgroup label="Vendas">
                                            <option value="VENDA_MERCADORIA">Venda de mercadoria adquirida ou recebida de terceiros</option>
                                            <option value="VENDA_PROD_ESTABELECIMENTO">Venda de produção do estabelecimento</option>
                                            <option value="VENDA_ATIVO_IMOBILIZADO">Venda de ativo imobilizado</option>
                                            <option value="VENDA_MERCADORIA_ENTREGA_FUTURA">Venda de mercadoria para entrega futura</option>
                                            <option value="VENDA_ORDEM">Venda à ordem</option>
                                            <option value="VENDA_CONSUMIDOR_FINAL">Venda para consumidor final</option>
                                            <option value="VENDA_EXPORTACAO">Venda para exportação</option>
                                            <option value="VENDA_ZONA_FRANCA_MANAUS">Venda para Zona Franca de Manaus</option>
                                        </optgroup>
                                        <optgroup label="Remessas">
                                            <option value="REMESSA_ATIVO_IMOBILIZADO">Remessa de ativo imobilizado</option>
                                            <option value="REMESSA_INDUSTRIALIZACAO">Remessa para industrialização</option>
                                            <option value="REMESSA_DEMONSTRACAO">Remessa para demonstração</option>
                                            <option value="REMESSA_CONSIGNACAO">Remessa para consignação</option>
                                            <option value="REMESSA_CONSERTO_REPARO">Remessa para conserto ou reparo</option>
                                        </optgroup>
                                        <optgroup label="Retornos">
                                            <option value="RETORNO_ATIVO_IMOBILIZADO">Retorno de ativo imobilizado</option>
                                            <option value="RETORNO_INDUSTRIALIZACAO">Retorno de remessa da industrialização</option>
                                            <option value="RETORNO_DEMONSTRACAO">Retorno de remessa de demonstração</option>
                                            <option value="RETORNO_CONSIGNACAO">Retorno de remessa de consignação</option>
                                            <option value="RETORNO_CONSERTO_REPARO">Retorno de remessa de conserto ou reparo</option>
                                        </optgroup>
                                        <optgroup label="Devoluções">
                                            <option value="DEVOLUCAO_VENDA">Devolução de venda</option>
                                            <option value="DEVOLUCAO_COMPRA">Devolução de compra</option>
                                        </optgroup>
                                        <optgroup label="Outros">
                                            <option value="NOTA_ENTRADA_POR_AJUSTE">Nota de entrada para ajuste</option>
                                            <option value="BONIFICACAO_DOACAO_BRINDES">Bonificação, doação ou brindes</option>
                                        </optgroup>
                                    </select>
                                </div>
                                <div className={formDetailErrors.province ? 'mb-0 has-error' : 'mb-0'}>
                                    <label className="form-label">Estado Destino da Operação<span className="text-danger">*</span></label>
                                    <select 
                                        className="form-select"
                                        name="province"
                                        value={editingTaxRuleDetail.province}
                                        onChange={handleTaxRuleDetailInputChange}
                                        required={['3'].includes(formData.deliveryMode)}
                                        disabled={!havePermission}
                                    >
                                        <option value="TODOS">Todos</option>
                                        <option value="AC">Acre (AC)</option>
                                        <option value="AL">Alagoas (AL)</option>
                                        <option value="AP">Amapá (AP)</option>
                                        <option value="AM">Amazonas (AM)</option>
                                        <option value="BA">Bahia (BA)</option>
                                        <option value="CE">Ceará (CE)</option>
                                        <option value="DF">Distrito Federal (DF)</option>
                                        <option value="ES">Espírito Santo (ES)</option>
                                        <option value="GO">Goiás (GO)</option>
                                        <option value="MA">Maranhão (MA)</option>
                                        <option value="MT">Mato Grosso (MT)</option>
                                        <option value="MS">Mato Grosso do Sul (MS)</option>
                                        <option value="MG">Minas Gerais (MG)</option>
                                        <option value="PA">Pará (PA)</option>
                                        <option value="PB">Paraíba (PB)</option>
                                        <option value="PR">Paraná (PR)</option>
                                        <option value="PE">Pernambuco (PE)</option>
                                        <option value="PI">Piauí (PI)</option>
                                        <option value="RJ">Rio de Janeiro (RJ)</option>
                                        <option value="RN">Rio Grande do Norte (RN)</option>
                                        <option value="RS">Rio Grande do Sul (RS)</option>
                                        <option value="RO">Rondônia (RO)</option>
                                        <option value="RR">Roraima (RR)</option>
                                        <option value="SC">Santa Catarina (SC)</option>
                                        <option value="SP">São Paulo (SP)</option>
                                        <option value="SE">Sergipe (SE)</option>
                                        <option value="TO">Tocantins (TO)</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 p-3 border rounded">
                                <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                                <div className={formDetailErrors.cfop ? 'mb-3 has-error' : 'mb-3'}>
                                    <label className="form-label">
                                        CFOP
                                        <InfoPopover
                                            content={
                                                <div style={{ textAlign: 'justify' }}>
                                                    O CFOP identifica a natureza da circulação da mercadoria ou a prestação de serviço. Ele define se a operação envolve compra, venda, transferência, devolução, importação, exportação, entre outros. O código CFOP é essencial para o cálculo correto dos tributos.
                                                </div>
                                            }
                                        />{'\u00A0'}<span className="text-danger">*</span>
                                    </label>
                                    <AsyncSelect2
                                        url="/api/cfops"
                                        value={editingTaxRuleDetail.cfop ? {
                                            label: `${editingTaxRuleDetail.cfop.cfopCode} - ${editingTaxRuleDetail.cfop.description}`,
                                            value: editingTaxRuleDetail.cfop.cfopCode
                                        } : null}
                                        onChange={handleCfopChange}
                                        valueField="cfopCode"
                                        labelField="description"
                                        placeholder="Selecione o CFOP"
                                        initialOptions={editingTaxRuleDetail.cfop ? [editingTaxRuleDetail.cfop] : []}
                                        labelFormatter={(item) => `${item.cfopCode} - ${item.description}`}
                                        showClear
                                        isDisabled={!havePermission}
                                    />
                                </div>
                                <div className={formDetailErrors.ncm ? 'mb-3 has-error' : 'mb-3'}>
                                    <label className="form-label">
                                        NCM
                                        <InfoPopover
                                                content={
                                                    <div style={{ textAlign: 'justify' }}>
                                                        O NCM é um código utilizado para classificar mercadorias, padronizado para os países membros do Mercosul. Ele serve para identificar a natureza do produto em operações fiscais, como vendas e importações, e é fundamental para determinar a tributação de cada produto.
                                                    </div>
                                                }
                                            />{'\u00A0'}<span className="text-danger">*</span>
                                    </label>
                                    <AsyncSelect2
                                        url="/api/ncms"
                                        value={editingTaxRuleDetail.ncm ? {
                                            label: `${editingTaxRuleDetail.ncm.ncmCode} - ${editingTaxRuleDetail.ncm.description}`,
                                            value: editingTaxRuleDetail.ncm.ncmCode
                                        } : null}
                                        onChange={handleNcmChange}
                                        valueField="ncmCode"
                                        labelField="description"
                                        placeholder="Selecione o NCM"
                                        initialOptions={formData.ncm ? [formData.ncm] : []}
                                        labelFormatter={(item) => `${item.ncmCode} - ${item.description}`}
                                        showClear
                                        isDisabled={!havePermission}
                                    />
                                </div>
                                <div className={formDetailErrors.cest ? 'mb-0 has-error' : 'mb-0'}>
                                    <label className="form-label">
                                        CEST
                                        <InfoPopover
                                                content={
                                                    <div style={{ textAlign: 'justify' }}>
                                                        O CEST é um código que uniformiza e identifica as mercadorias e bens passíveis de sujeição aos regimes de substituição tributária e de antecipação do recolhimento do ICMS. Ele é obrigatório em operações com produtos sujeitos à substituição tributária.
                                                    </div>
                                                }
                                            />
                                    </label>
                                    <AsyncSelect2
                                        url="/api/cests"
                                        value={editingTaxRuleDetail.cest ? {
                                            label: `${editingTaxRuleDetail.cest.cestCode} - ${editingTaxRuleDetail.cest.description}`,
                                            value: editingTaxRuleDetail.cest.cestCode
                                        } : null}
                                        onChange={handleCestChange}
                                        valueField="cestCode"
                                        labelField="description"
                                        placeholder="Selecione o CEST"
                                        initialOptions={formData.cest ? [formData.cest] : []}
                                        labelFormatter={(item) => `${item.cestCode} - ${item.description}`}
                                        showClear
                                        isDisabled={!havePermission}
                                    />
                                </div>
                            </div>

                            <div className="mb-3 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Configuração do ICMS</h5>
                                <div className={formDetailErrors.csosn ? 'mb-3 has-error' : 'mb-3'}>
                                    <label className="form-label">
                                        CSOSN
                                        <InfoPopover
                                            content={
                                                <div>
                                                    <p><strong>Código de Situação da Operação do Simples Nacional:</strong></p>
                                                    <ul>
                                                        <li><strong>101</strong> - Tributada com permissão de crédito: Utilizada quando a operação permite a apropriação de crédito de ICMS.</li>
                                                        <li><strong>102</strong> - Tributada sem permissão de crédito: Quando a operação não permite a apropriação de crédito.</li>
                                                        <li><strong>103</strong> - Isenção do ICMS no Simples Nacional para faixa de receita bruta.</li>
                                                        <li><strong>201</strong> - Tributada com permissão de crédito e cobrança ICMS-ST: Utilizada em operações sujeitas ao regime de Substituição Tributária com permissão de crédito.</li>
                                                        <li><strong>202</strong> - Tributada sem permissão de crédito e cobrança ICMS-ST: Quando a operação é tributada sob ICMS-ST sem direito a crédito.</li>
                                                        <li><strong>203</strong> - Tributação específica para faixas de receita bruta.</li>
                                                        <li><strong>300</strong> - Imune: Quando a mercadoria ou serviço é imune ao ICMS.</li>
                                                        <li><strong>400</strong> - Não tributada: Quando a operação não é tributada pelo ICMS.</li>
                                                        <li><strong>500</strong> - ICMS cobrado anteriormente por ST: Operações em que o ICMS foi cobrado em etapa anterior por substituição tributária.</li>
                                                        <li><strong>900</strong> - Outros: Usado para situações não especificadas nos códigos anteriores.</li>
                                                    </ul>
                                                </div>
                                            }
                                        />{'\u00A0'}<span className="text-danger">*</span>
                                    </label>
                                    <select
                                        className="form-select"
                                        name="csosn"
                                        value={editingTaxRuleDetail.csosn || ''}
                                        onChange={handleTaxRuleDetailInputChange}
                                        disabled={!havePermission}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="101">101 - Tributada com permissão de crédito</option>
                                        <option value="102">102 - Tributada sem permissão de crédito</option>
                                        <option value="103">103 - Isenção do ICMS para faixa de receita bruta</option>
                                        <option value="201">201 - Tributada com permissão de crédito e cobrança ICMS-ST</option>
                                        <option value="202">202 - Tributada sem permissão de crédito e cobrança ICMS-ST</option>
                                        <option value="203">203 - Tributada para faixa receita bruta e cobrança ICMS-ST</option>
                                        <option value="300">300 - Imune</option>
                                        <option value="400">400 - Não tributada</option>
                                        <option value="500">500 - ICMS cobrado anteriormente por ST</option>
                                        <option value="900">900 - Outros</option>
                                    </select>
                                </div>
                                <div className={formDetailErrors.icmsOrigin ? 'mb-3 has-error' : 'mb-3'}>
                                    <label className="form-label">
                                        Origem do ICMS 
                                        <InfoPopover
                                            content={
                                                <div style={{ textAlign: 'justify' }}>
                                                    Este campo indica a procedência da mercadoria e é essencial para o cálculo correto do imposto.
                                                </div>
                                            }
                                        />{'\u00A0'}<span className="text-danger">*</span>
                                    </label>
                                    <select
                                        className="form-select"
                                        name="icmsOrigin"
                                        value={editingTaxRuleDetail.icmsOrigin || ''}
                                        onChange={handleTaxRuleDetailInputChange}
                                        disabled={!havePermission}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="0">0 - Nacional</option>
                                        <option value="1">1 - Estrangeira importação direta</option>
                                        <option value="2">2 - Estrangeira adquirida no mercado interno</option>
                                        <option value="3">3 - Nacional mercadoria ou bem conteúdo importação superior 40%</option>
                                        <option value="4">4 - Nacional produção em conformidade com processos produtivos básicos</option>
                                        <option value="5">5 - Nacional mercadoria ou bem conteúdo importação inferior 40%</option>
                                        <option value="6">6 - Estrangeira importação direta sem similar nacional constante em lista Camex</option>
                                        <option value="7">7 - Estrangeira adquirida mercado interno sem similar nacional constante em lista Camex</option>
                                        <option value="8">8 - Nacional mercadoria ou bem conteúdo importação superior 70%</option>
                                    </select>
                                </div>
                                {fields.includes('icmsCreditPercentage') && (
                                    <div className={formDetailErrors.icmsCreditPercentage ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Percentual de Crédito de ICMS <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="icmsCreditPercentage"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.icmsCreditPercentage}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}

                                {/* ICMS */}
                                {fields.includes('icmsModality') && (
                                    <div className={formDetailErrors.icmsModality ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Modalidade da Base de Cálculo do ICMS <span className="text-danger">*</span></label>
                                        <select
                                            className="form-select"
                                            name="icmsModality"
                                            value={editingTaxRuleDetail.icmsModality || ''}
                                            onChange={handleTaxRuleDetailInputChange}
                                            required
                                            disabled={!havePermission}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="0">Margem de valor agregado</option>
                                            <option value="1">Pauta</option>
                                            <option value="2">Preço tabelado ou máximo</option>
                                            <option value="3">Valor da operação</option>
                                        </select>
                                    </div>
                                )}
                                {(fields.includes('icmsFixedPrice') && editingTaxRuleDetail.icmsModality === "2") && (
                                    <div className={formDetailErrors.icmsAliquota ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Preço tabelado ou máximo<span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            id="input-icmsFixedPrice"
                                            name="icmsFixedPrice"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name)}
                                            onBlur={(event) => adjustDecimal(event, 'R$ ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='R$ '
                                            value={editingTaxRuleDetail.icmsFixedPrice}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('icmsAliquota') && (
                                    <div className={formDetailErrors.icmsAliquota ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Alíquota do ICMS <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            id="input-icmsAliquota"
                                            name="icmsAliquota"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.icmsAliquota}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('icmsBaseReduction') && (
                                    <div className={formDetailErrors.icmsBaseReduction ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Percentual de Redução da Base do ICMS <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="icmsBaseReduction"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.icmsBaseReduction}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('icmsMarginValueAdded') && (
                                    <div className={formDetailErrors.icmsMarginValueAdded ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Percentual de Margem de Valor Agregado (MVA) <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="icmsMarginValueAdded"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.icmsMarginValueAdded}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}

                                {/* ICMS ST */}
                                {fields.includes('icmsStModality') && (
                                    <div className={formDetailErrors.icmsStModality ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Modalidade da Base de Cálculo do ICMS-ST <span className="text-danger">*</span></label>
                                        <select
                                            className="form-select"
                                            name="icmsStModality"
                                            value={editingTaxRuleDetail.icmsStModality || ''}
                                            onChange={handleTaxRuleDetailInputChange}
                                            required
                                            disabled={!havePermission}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="0">Preço Tabelado</option>
                                            <option value="1">Lista Negativa</option>
                                            <option value="2">Lista Positiva</option>
                                            <option value="3">Lista Neutra</option>
                                            <option value="4">Margem Valor Agregado</option>
                                            <option value="5">Pauta</option>
                                            <option value="6">Valor Operação</option>
                                        </select>
                                    </div>
                                )}
                                {(fields.includes('icmsStFixedPrice') && editingTaxRuleDetail.icmsStModality === "0") && (
                                    <div className={formDetailErrors.icmsAliquota ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Preço tabelado<span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            id="input-icmsStFixedPrice"
                                            name="icmsStFixedPrice"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name)}
                                            onBlur={(event) => adjustDecimal(event, 'R$ ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='R$ '
                                            value={editingTaxRuleDetail.icmsStFixedPrice}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('icmsStAliquota') && (
                                    <div className={formDetailErrors.icmsStAliquota ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Alíquota do ICMS-ST <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="icmsStAliquota"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.icmsStAliquota}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('icmsStBaseReduction') && (
                                    <div className={formDetailErrors.icmsStBaseReduction ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Percentual de Redução da Base do ICMS-ST <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="icmsStBaseReduction"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.icmsStBaseReduction}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('icmsStMarginValueAdded') && (
                                    <div className={formDetailErrors.icmsStMarginValueAdded ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Percentual de Margem de Valor Agregado (MVA) ST <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="icmsStMarginValueAdded"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.icmsStMarginValueAdded}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('fcpStPercentage') && (
                                    <div className={formDetailErrors.fcpStPercentage ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Percentual do FCP ST <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="fcpStPercentage"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.fcpStPercentage}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('icmsEffectivePercentage') && (
                                    <div className={formDetailErrors.icmsEffectivePercentage ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Percentual Efetivo do ICMS <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="icmsEffectivePercentage"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.icmsEffectivePercentage}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('fcpRetainedPercentage') && (
                                    <div className={formDetailErrors.fcpRetainedPercentage ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Percentual Retido do FCP ST <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="fcpRetainedPercentage"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.fcpRetainedPercentage}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('icmsStRetainedPercentage') && (
                                    <div className={formDetailErrors.icmsStRetainedPercentage ? 'mb-3 has-error' : 'mb-3'}>
                                        <label className="form-label">Percentual Retido do ICMS-ST <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="icmsStRetainedPercentage"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.icmsStRetainedPercentage}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                                {fields.includes('icmsEffectiveBaseReduction') && (
                                    <div className={formDetailErrors.icmsEffectiveBaseReduction ? 'mb-0 has-error' : 'mb-0'}>
                                        <label className="form-label">Redução Efetiva da Base do ICMS <span className="text-danger">*</span></label>
                                        <CurrencyInput
                                            name="icmsEffectiveBaseReduction"
                                            onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                            onBlur={(event) => adjustDecimal(event, '% ')}
                                            decimalSeparator="," 
                                            groupSeparator="."
                                            decimalScale={2}
                                            prefix='% '
                                            value={editingTaxRuleDetail.icmsEffectiveBaseReduction}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="mb-3 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Configuração do PIS</h5>
                                <div className={formDetailErrors.pisAliquota ? 'mb-3 has-error' : 'mb-3'}>
                                    <label className="form-label">Alíquota <span className="text-danger">*</span></label>
                                    <CurrencyInput
                                        name="pisAliquota"
                                        onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                        onBlur={(event) => adjustDecimal(event, '% ')}
                                        decimalSeparator="," 
                                        groupSeparator="."
                                        decimalScale={2}
                                        prefix='% '
                                        value={editingTaxRuleDetail.pisAliquota}
                                        className="form-control"
                                        autoComplete="off"
                                        disabled={!havePermission}
                                    />
                                </div>
                                <div className={formDetailErrors.pisSituation ? 'mb-0 has-error' : 'mb-0'}>
                                    <label className="form-label">Situação Tributária <span className="text-danger">*</span></label>
                                    <select
                                        className="form-select"
                                        name="pisSituation"
                                        value={editingTaxRuleDetail.pisSituation || ''}
                                        onChange={handleTaxRuleDetailInputChange}
                                        required
                                        disabled={!havePermission}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="01">01 - Operação tributável cumulativo/não cumulativo</option>
                                        <option value="02">02 - Operação tributável alíquota diferenciada</option>
                                        <option value="03">03 - Operação tributável quantidade vendida por alíquota por unidade produto</option>
                                        <option value="04">04 - Operação tributável monofásica alíquota zero</option>
                                        <option value="05">05 - Operação tributável ST</option>
                                        <option value="06">06 - Operação tributável alíquota zero</option>
                                        <option value="07">07 - Operação isenta de contribuição</option>
                                        <option value="08">08 - Operação sem incidência de contribuição</option>
                                        <option value="09">09 - Operação com suspensão de contribuição</option>
                                        <option value="49">49 - Outras operações de saída</option>
                                        <option value="50">50 - Operação direito crédito vinculada exclusivamente receita tributada mercado interno</option>
                                        <option value="51">51 - Operação direito crédito vinculada exclusivamente receita não tributada mercado interno</option>
                                        <option value="52">52 - Operação direito crédito vinculada exclusivamente receita exportação</option>
                                        <option value="53">53 - Operação direito crédito vinculada receitas tributada e não tributada mercado interno</option>
                                        <option value="54">54 - Operação direito crédito vinculada receitas tributadas no mercado interno exportação</option>
                                        <option value="55">55 - Operação direito crédito vinculada receitas não tributada no mercado interno exportação</option>
                                        <option value="56">56 - Operação direito crédito vinculada receitas tributadas e não tributadas mercado interno exportação</option>
                                        <option value="60">60 - Crédito presumido operação aquisição vinculada exclusivamente receita tributada mercado interno</option>
                                        <option value="61">61 - Crédito presumido operação aquisição vinculada exclusivamente à receita não tributada mercado interno</option>
                                        <option value="62">62 - Crédito presumido operação aquisição vinculada exclusivamente receita exportação</option>
                                        <option value="63">63 - Crédito presumido operação aquisição vinculada receitas tributadas mercado interno</option>
                                        <option value="64">64 - Crédito presumido operação aquisição vinculada receitas tributadas mercado interno exportação</option>
                                        <option value="65">65 - Crédito presumido operação aquisição vinculada receitas não tributadas mercado interno exportação</option>
                                        <option value="66">66 - Crédito presumido operação aquisição vinculada receitas tributadas e não tributadas mercado interno exportação</option>
                                        <option value="67">67 - Crédito presumido outras operações</option>
                                        <option value="70">70 - Operação aquisição sem direito crédito</option>
                                        <option value="71">71 - Operação aquisição com isenção</option>
                                        <option value="72">72 - Operação aquisição com suspensão</option>
                                        <option value="73">73 - Operação aquisição alíquota zero</option>
                                        <option value="74">74 - Operação aquisição sem incidência de contribuição</option>
                                        <option value="75">75 - Operação aquisição por substituição tributária</option>
                                        <option value="98">98 - Outras operações de entrada</option>
                                        <option value="99">99 - Outras operações</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mb-3 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Configuração do COFINS</h5>
                                <div className={formDetailErrors.cofinsAliquota ? 'mb-3 has-error' : 'mb-3'}>
                                    <label className="form-label">Alíquota <span className="text-danger">*</span></label>
                                    <CurrencyInput
                                        name="cofinsAliquota"
                                        onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                        onBlur={(event) => adjustDecimal(event, '% ')}
                                        decimalSeparator="," 
                                        groupSeparator="."
                                        decimalScale={2}
                                        prefix='% '
                                        value={editingTaxRuleDetail.cofinsAliquota}
                                        className="form-control"
                                        autoComplete="off"
                                        disabled={!havePermission}
                                    />
                                </div>
                                <div className={formDetailErrors.cofinsSituation ? 'mb-0 has-error' : 'mb-0'}>
                                    <label className="form-label">Situação Tributária <span className="text-danger">*</span></label>
                                    <select
                                        className="form-select"
                                        name="cofinsSituation"
                                        value={editingTaxRuleDetail.cofinsSituation || ''}
                                        onChange={handleTaxRuleDetailInputChange}
                                        required
                                        disabled={!havePermission}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="01">01 - Operação tributável cumulativo/não cumulativo</option>
                                        <option value="02">02 - Operação tributável alíquota diferenciada</option>
                                        <option value="03">03 - Operação tributável quantidade vendida por alíquota por unidade produto</option>
                                        <option value="04">04 - Operação tributável monofásica alíquota zero</option>
                                        <option value="05">05 - Operação tributável ST</option>
                                        <option value="06">06 - Operação tributável alíquota zero</option>
                                        <option value="07">07 - Operação isenta de contribuição</option>
                                        <option value="08">08 - Operação sem incidência de contribuição</option>
                                        <option value="09">09 - Operação com suspensão de contribuição</option>
                                        <option value="49">49 - Outras operações de saída</option>
                                        <option value="50">50 - Operação direito crédito vinculada exclusivamente receita tributada mercado interno</option>
                                        <option value="51">51 - Operação direito crédito vinculada exclusivamente receita não tributada mercado interno</option>
                                        <option value="52">52 - Operação direito crédito vinculada exclusivamente receita exportação</option>
                                        <option value="53">53 - Operação direito crédito vinculada receitas tributada e não tributada mercado interno</option>
                                        <option value="54">54 - Operação direito crédito vinculada receitas tributadas no mercado interno exportação</option>
                                        <option value="55">55 - Operação direito crédito vinculada receitas não tributada no mercado interno exportação</option>
                                        <option value="56">56 - Operação direito crédito vinculada receitas tributadas e não tributadas mercado interno exportação</option>
                                        <option value="60">60 - Crédito presumido operação aquisição vinculada exclusivamente receita tributada mercado interno</option>
                                        <option value="61">61 - Crédito presumido operação aquisição vinculada exclusivamente à receita não tributada mercado interno</option>
                                        <option value="62">62 - Crédito presumido operação aquisição vinculada exclusivamente receita exportação</option>
                                        <option value="63">63 - Crédito presumido operação aquisição vinculada receitas tributadas mercado interno</option>
                                        <option value="64">64 - Crédito presumido operação aquisição vinculada receitas tributadas mercado interno exportação</option>
                                        <option value="65">65 - Crédito presumido operação aquisição vinculada receitas não tributadas mercado interno exportação</option>
                                        <option value="66">66 - Crédito presumido operação aquisição vinculada receitas tributadas e não tributadas mercado interno exportação</option>
                                        <option value="67">67 - Crédito presumido outras operações</option>
                                        <option value="70">70 - Operação aquisição sem direito crédito</option>
                                        <option value="71">71 - Operação aquisição com isenção</option>
                                        <option value="72">72 - Operação aquisição com suspensão</option>
                                        <option value="73">73 - Operação aquisição alíquota zero</option>
                                        <option value="74">74 - Operação aquisição sem incidência de contribuição</option>
                                        <option value="75">75 - Operação aquisição por substituição tributária</option>
                                        <option value="98">98 - Outras operações de entrada</option>
                                        <option value="99">99 - Outras operações</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mb-3 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Configuração do IPI</h5>
                                <div className={formDetailErrors.ipiCode ? 'mb-3 has-error' : 'mb-3'}>
                                    <label className="form-label">Código de Enquadramento <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="ipiCode"
                                        value={editingTaxRuleDetail.ipiCode || ''}
                                        onChange={handleTaxRuleDetailInputChange}
                                        onKeyDown={handleNumber}
                                        maxLength={3}
                                        autoComplete="off"
                                        required
                                        disabled={!havePermission}
                                    />
                                </div>
                                <div className={formDetailErrors.ipiAliquota ? 'mb-3 has-error' : 'mb-3'}>
                                    <label className="form-label">Alíquota <span className="text-danger">*</span></label>
                                    <CurrencyInput
                                        name="ipiAliquota"
                                        onValueChange={(value, name) => handleCurrencyChange(value, name, true)}
                                        onBlur={(event) => adjustDecimal(event, '% ')}
                                        decimalSeparator="," 
                                        groupSeparator="."
                                        decimalScale={2}
                                        prefix='% '
                                        value={editingTaxRuleDetail.ipiAliquota}
                                        className="form-control"
                                        autoComplete="off"
                                        disabled={!havePermission}
                                    />
                                </div>
                                <div className={formDetailErrors.ipiSituation ? 'mb-0 has-error' : 'mb-0'}>
                                    <label className="form-label">Situação Tributária <span className="text-danger">*</span></label>
                                    <select
                                        className="form-select"
                                        name="ipiSituation"
                                        value={editingTaxRuleDetail.ipiSituation || ''}
                                        onChange={handleTaxRuleDetailInputChange}
                                        required
                                        disabled={!havePermission}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="00">00 - Entrada recuperação crédito</option>
                                        <option value="01">01 - Entrada tributada alíquota zero</option>
                                        <option value="02">02 - Entrada isenta</option>
                                        <option value="03">03 - Entrada não tributada</option>
                                        <option value="04">04 - Entrada imune</option>
                                        <option value="05">05 - Entrada com suspensão</option>
                                        <option value="49">49 - Outras entradas</option>
                                        <option value="50">50 - Saída tributada</option>
                                        <option value="51">51 - Saída tributada com alíquota zero</option>
                                        <option value="52">52 - Saída isenta</option>
                                        <option value="53">53 - Saída não tributada</option>
                                        <option value="54">54 - Saída imune</option>
                                        <option value="55">55 - Saída com suspensão</option>
                                        <option value="99">99 - Outras saídas</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mb-0 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Produtos Especiais</h5>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label className="form-label">
                                            CNPJ do Produtor
                                            <InfoPopover
                                                content={
                                                    <div style={{ textAlign: 'justify' }}>
                                                        O CNPJ do Produtor é o número de registro no Cadastro Nacional da Pessoa Jurídica (CNPJ) do produtor rural ou empresa responsável pela produção de mercadorias em operações de venda ou transferência. Esse campo é obrigatório em operações que envolvem produtores rurais.
                                                    </div>
                                                }
                                            />
                                        </label>
                                        <ReactInputMask mask="99.999.999/9999-99" value={editingTaxRuleDetail.cnpjProdutor || ''} onChange={handleTaxRuleDetailInputChange} autoComplete="off" disabled={!havePermission}>
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cnpjProdutor" required disabled={!havePermission} />}
                                        </ReactInputMask>
                                    </div>
                                    <div className="col-md-6 mt-3 mt-md-0">
                                        <label className="form-label">
                                            Código do Selo
                                            <InfoPopover
                                                content={
                                                    <div style={{ textAlign: 'justify' }}>
                                                        O Código do Selo é utilizado para identificar o selo de controle fiscal de produtos sujeitos à regulamentação especial, como bebidas e produtos controlados. Esse código é exigido pela legislação tributária para garantir o controle e a procedência desses produtos.
                                                    </div>
                                                }
                                            />
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="codigoSelo"
                                            value={editingTaxRuleDetail.codigoSelo || ''}
                                            onChange={handleTaxRuleDetailInputChange}
                                            maxLength={60}
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">
                                            Quantidade de Selos
                                            <InfoPopover
                                                content={
                                                    <div style={{ textAlign: 'justify' }}>
                                                        Este campo indica a quantidade de selos de controle fiscal aplicados ao produto. Ele é necessário quando o produto exige a aplicação de selos fiscais para garantir a legalidade da mercadoria e o cumprimento das obrigações tributárias.
                                                    </div>
                                                }
                                            />
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="quantidadeSelo"
                                            value={editingTaxRuleDetail.quantidadeSelo || ''}
                                            onChange={handleTaxRuleDetailInputChange}
                                            max="999999999999"
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3 mt-md-0">
                                        <label className="form-label">
                                            Código do Benefício Fiscal
                                            <InfoPopover
                                                content={
                                                    <div style={{ textAlign: 'justify' }}>
                                                        O Código do Benefício Fiscal informa a existência de benefícios fiscais aplicáveis à operação, como isenções, reduções de base de cálculo ou diferimentos do ICMS. Esse código deve ser preenchido conforme a legislação estadual ou federal que rege o benefício fiscal aplicável.
                                                    </div>
                                                }
                                            />
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="codigoBeneficioFiscal"
                                            value={editingTaxRuleDetail.codigoBeneficioFiscal || ''}
                                            onChange={handleTaxRuleDetailInputChange}
                                            maxLength={10}
                                            autoComplete="off"
                                            disabled={!havePermission}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='mt-3'>
                                <button
                                    type="button"
                                    className="btn btn-primary me-2 mt-1 mt-md-0"
                                    onClick={handleSaveTaxRuleDetail}
                                    disabled={!havePermission}
                                >
                                    {editingTaxRuleDetail.id ? 'Atualizar Configuração' : 'Adicionar à Lista'}
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-secondary mt-1 mt-md-0"
                                    onClick={() => {
                                        setIsEditing(false);
                                        setEditingTaxRuleDetail(taxRuleDetailRestore);
                                        setEditingTaxRuleDetail(null);
                                    }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    )};
    

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setShowButtons(true)}>
            <div>
                <div className="form-limited-width mt-3">
                    {loading ? (
                        <div className="spinner-container">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : showForm ? (
                        <div>
                            <h3 className="mb-3">{taxRule.id ? 'Editar Regra Fiscal' : 'Nova Regra Fiscal'}</h3>
                            <form className="mt-3">
                                {renderGeneralData()}
                                {renderConfigFields()}
                            </form>
                        </div>
                    ) : null}
                </div>

                <div className={`button-bar ${showButtons && showForm? 'show' : ''}`} title={!havePermission ? "Sem permissão": ""}>
                    <div className="button-bar-container">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={handleSave}
                            disabled={!havePermission || isSaving}>
                            {isSaving && (<FontAwesomeIcon icon={faSpinner} spin className='me-2' />)}<FontAwesomeIcon icon={faSave} /> Salvar
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}

export default TaxRuleEdit;
