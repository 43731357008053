import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import axios from './AxiosInstance';

const customStyles = {
  // ... (seus estilos personalizados)
};

const AsyncSelect2 = ({
  url,
  value,
  onChange,
  valueField,
  labelField,
  placeholder,
  initialOptions = [],
  labelFormatter,
  showClear = false,
  onlyActive = false,
  isDisabled = false,
  fixedOption = null,
  extraFields = []
}) => {

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    try {
      const response = await axios.get(url, {
        params: {
          onlyActive: onlyActive,
          search: searchQuery,
          page: page,
          size: 10,
        },
      });

      // Mapear as novas opções
      let newOptions = response.data.content.map((item) => {
        const option = {
          label: labelFormatter ? labelFormatter(item) : item[labelField],
          value: item[valueField],
        };

        // Incluir propriedades extras no objeto de opções, se `extraFields` estiver definido
        extraFields.forEach(field => {
          option[field] = item[field];
        });

        return option;
      });

      // Filtrar duplicatas
      newOptions = newOptions.filter(
        (newOption) => !loadedOptions.some((loadedOption) => loadedOption.value === newOption.value)
      );

      let options = newOptions;

      // Adicionar fixedOption sem duplicatas
      if (
        fixedOption &&
        page === 0 &&
        !loadedOptions.some((opt) => opt.value === fixedOption.value) &&
        !newOptions.some((opt) => opt.value === fixedOption.value)
      ) {
        options = [fixedOption, ...options];
      }

      return {
        options,
        hasMore: response.data.totalElements > loadedOptions.length + newOptions.length,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error('Error fetching options', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  return (
    <>
      <AsyncPaginate
        value={value || fixedOption}
        loadOptions={loadOptions}
        onChange={onChange}
        placeholder={placeholder}
        debounceTimeout={300}
        additional={{
          page: 0,
        }}
        isClearable={showClear}
        noOptionsMessage={() => 'Nenhuma opção disponível'}
        loadingMessage={() => 'Carregando...'}
        styles={customStyles}
        classNamePrefix="react-select"
        isDisabled={isDisabled}
        defaultOptions={
          fixedOption
            ? [fixedOption, ...initialOptions.filter((option) => option.value !== fixedOption.value)]
            : initialOptions
        }
      />
      {/* Campo oculto para garantir que o valor seja enviado */}
      {!isDisabled && value?.value && (
        <input type="hidden" name={labelField} value={value || fixedOption} />
      )}
    </>
  );
};

export default AsyncSelect2;
