import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext, useNfeWebSocket } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSort, faSortUp, faSortDown, faPencil, faTrash, faInfoCircle, faEllipsisV, faEye } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Paginator } from 'primereact/paginator';
import ConfirmationModal from './ConfirmationModal';
import CancelInvoiceConfirmation from './CancelInvoiceConfirmation';
import AlertModal from './AlertModal';
import { CSSTransition } from 'react-transition-group';
import '../assets/styles/InvoiceStatus.css';

function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
        setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        // Limpa o listener quando o componente for desmontado
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
}

function InvoiceList() {
    const {auth, setAlert, axiosConfigured, hasRequiredRoleDfe } = useContext(AuthContext);
    const [invoices, setInvoices] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState(2);
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
    const [showInfoErrorModal, setShowInfoErrorModal] = useState(null);
    const [invoiceToDelete, setInvoiceToDelete] = useState(null);
    const [invoiceToCancel, setInvoiceToCancel] = useState(null);
    const hasFetched = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [inProp, setInProp] = useState(false);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const nfeData = useNfeWebSocket();
    const allowedStatuses = ["NAO_ENVIADA", "PROCESSADA_COM_ERRO"];
    const havePermission = hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_USER') && !auth.readOnlyMode;
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;
    
    const formatStatus = (status) => {
        const statusEnumMap = {
            "NAO_ENVIADA": { label: "Não Enviada", className: "status-nao-enviada" },
            "RECEBIDA": { label: "Recebida", className: "status-recebida" },
            "NFE_AUTORIZADA": { label: "NFe Autorizada", className: "status-autorizada" },
            "PROCESSADA_COM_ERRO": { label: "Processada com Erro", className: "status-com-erro" },
            "CANCELADA": { label: "Cancelada", className: "status-cancelada" }
        };
    
        return statusEnumMap[status] || { label: "STATUS DESCONHECIDO", className: "status-desconhecido" };
    };

    const formatStatusMobile = (status) => {
        const statusEnumMap = {
            "NAO_ENVIADA": { label: "N", className: "status-nao-enviada" },
            "RECEBIDA": { label: "R", className: "status-recebida" },
            "NFE_AUTORIZADA": { label: "A", className: "status-autorizada" },
            "PROCESSADA_COM_ERRO": { label: "E", className: "status-com-erro" },
            "CANCELADA": { label: "C", className: "status-cancelada" }
        };
    
        return statusEnumMap[status] || { label: "STATUS DESCONHECIDO", className: "status-desconhecido" };
    };

    const fetchInvoices = useCallback(async (page, rows, sortField, sortOrder, searchTerm) => {
        setLoading(true);
        try {
            const response = await axios.get('/api/invoices/outgoing', {
                params: {
                    search: searchTerm,
                    page: page,
                    size: rows,
                    sort: `${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`,
                },
            });
            const invoiceData = response.data.content || [];
            setInvoices(invoiceData);
            setTotalRecords(response.data.totalElements || 0);
        } catch (error) {
            console.error('Erro ao buscar notas fiscais', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            const state = location.state;
            if (state) {
                setSearchTerm(state.searchTerm || '');
                setPage(state.page || 0);
                setRows(state.rows || 10);
                setSortField(state.sortField || 'id');
                setSortOrder(state.sortOrder || 2);
            }
            fetchInvoices(state?.page || page, state?.rows || rows, state?.sortField || sortField, state?.sortOrder || sortOrder, state?.searchTerm || searchTerm);
            hasFetched.current = true;
        }
    }, [fetchInvoices, axiosConfigured, location.state, page, rows, searchTerm, sortField, sortOrder]);

    useEffect(() => {
        if (nfeData) {
            setInvoices((prevInvoices) => 
                prevInvoices.map((invoice) => 
                    invoice.id === nfeData.id
                        ? {
                            ...invoice,
                            accessKey: nfeData.accessKey,
                            status: nfeData.status,
                            dhIssue: nfeData.dhIssue,
                            docNumber: nfeData.docNumber,
                            invoiceProtocol: nfeData.invoiceProtocol,
                            invoiceCancelProtocol: nfeData.invoiceCancelProtocol,
                        }
                        : invoice
                )
            );
        }
    }, [nfeData]);

    const handleShowInfoErrorModal = (invoice) => {
        const hasProtocol = invoice.invoiceProtocol? true: false;
        const hasCancelProtocol = invoice.invoiceCancelProtocol? true: false;

        const protocol = {
            status: invoice.status,
            statusCode: hasProtocol? invoice.invoiceProtocol.statusCode: '',
            statusReason: hasProtocol? invoice.invoiceProtocol.statusReason: '',
            messageCode: hasProtocol? invoice.invoiceProtocol.messageCode: '',
            messageDescription: hasProtocol? invoice.invoiceProtocol.messageDescription: '',
            cancelCode: hasCancelProtocol? invoice.invoiceCancelProtocol.statusCode: '',
            cancelMessage: hasCancelProtocol? invoice.invoiceCancelProtocol.statusReason: ''
        }

        setShowInfoErrorModal(protocol);
    };

    const handleSearch = () => {
        setSelectedInvoices([]);
        fetchInvoices(page, rows, sortField, sortOrder, searchTerm);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 1 ? -1 : 1;
        setSortField(field);
        setSortOrder(order);
        fetchInvoices(page, rows, field, order, searchTerm);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 1 ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const onPageChange = (event) => {
        setPage(event.page);
        setRows(event.rows);
        fetchInvoices(event.page, event.rows, sortField, sortOrder, searchTerm);
    };

    const toggleSelectInvoice = (invoiceId) => {
        setSelectedInvoices((prevSelected) =>
            prevSelected.includes(invoiceId)
                ? prevSelected.filter((id) => id !== invoiceId)
                : [...prevSelected, invoiceId]
        );
    };

    const handleDownloadXML = async (invoice) => {
        try {
            const response = await axios.get(`/api/invoices/outgoing/${invoice.accessKey}/xml`, {
                responseType: 'blob',
            });

            const legalName = normalizeFileName(invoice.customer.legalName);
            const fileName = `NFE_${invoice.docNumber}_XML_${legalName}.xml`;
    
            // Cria um link temporário para baixar o arquivo
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/xml' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
        }
    };

    const normalizeFileName = (name) => {
        return name
            .normalize("NFD") // Normaliza para decompor os caracteres com acentos
            .replace(/[\u0300-\u036f]/g, "") // Remove os acentos
            .replace(/[^a-zA-Z0-9]/g, "_") // Substitui caracteres especiais e espaços por "_"
            .toUpperCase(); // Converte para letras minúsculas (opcional)
    };

    const handleDanfe = async (invoice) => {
        try {
            setAlert({ type: 'info', message: 'Gerando DANFE...', timeout: 5000 });
            const response = await axios.get(`/api/invoices/outgoing/${invoice.accessKey}/danfe`, {
                responseType: 'blob',
            });

            const legalName = normalizeFileName(invoice.customer.legalName);
            const fileName = `NFE_${invoice.docNumber}_DANFE_${legalName}.pdf`;

            // Cria um link temporário para baixar o arquivo
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Erro ao baixar o danfe:', error);
        }
    }

    /*const handleDanfe = async (invoice) => {
        try {
            const response = await axios.get(`/api/invoices/outgoing/${invoice.accessKey}/danfe`, {
                responseType: 'blob',
            });
    
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            const legalName = normalizeFileName(invoice.customer.legalName);
            const fileName = `NFE_${invoice.docNumber}_DANFE_${legalName}.pdf`;
    
            const newWindow = window.open();
            if (newWindow) {
                newWindow.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <title>DANFE</title>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">
                        <style>
                            body {
                                margin: 0;
                                font-family: Arial, sans-serif;
                                display: flex;
                                flex-direction: column;
                                height: 100vh;
                                overflow: hidden;
                            }
                            iframe {
                                flex-grow: 1;
                                width: 100%;
                                height: calc(100% - 50px);
                                border: none;
                            }
                            .download-bar {
                                background: #f8f9fa;
                                padding: 10px;
                                text-align: center;
                                box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
                            }
                            .download-button {
                                background: #007BFF;
                                color: white;
                                padding: 10px 15px;
                                text-decoration: none;
                                border-radius: 5px;
                                font-size: 14px;
                                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                                transition: background-color 0.3s ease;
                            }
                            .download-button:hover {
                                background: #0056b3;
                            }
                            @media (max-width: 600px) {
                                .download-bar {
                                    padding: 10px;
                                    font-size: 12px;
                                }
                                .download-button {
                                    padding: 8px 12px;
                                    font-size: 12px;
                                }
                            }
                        </style>
                    </head>
                    <body>
                        <iframe src="${url}" title="DANFE"></iframe>
                        <div class="download-bar">
                            <a href="${url}" download="${fileName}" class="download-button">Download</a>
                        </div>
                    </body>
                    </html>
                `);
                newWindow.document.close();
            } else {
                alert('Permita pop-ups para visualizar o DANFE.');
            }
    
            // Revoga a URL após um tempo para liberar memória
            //setTimeout(() => {
            //    window.URL.revokeObjectURL(url);
            //}, 10000);
        } catch (error) {
            console.error('Erro ao gerar o DANFE:', error);
        }
    };*/  

    const handleTransmitSelectedInvoices = async () => {
        try {
            await axios.post('/api/invoices/outgoing/transmit', { ids: selectedInvoices });
            setAlert({ type: 'success', message: 'Notas fiscais transmitidas com sucesso' });
            setSelectedInvoices([]);
        } catch (error) {
        }
    };    

    const handleTransmitInvoice = async (invoice) => {
        try {
            await axios.post('/api/invoices/outgoing/transmit', { ids: [invoice.id] });
            setAlert({ type: 'success', message: 'Nota fiscal transmitida com sucesso' });
        } catch (error) {
        }
    };

    const handleCancelInvoice = async (justification) => {
        try {
            await axios.post('/api/invoices/outgoing/cancel', { id: invoiceToCancel.id, justification: justification });
            setAlert({ type: 'success', message: 'Solicitação de cancelamento enviada' });
            setInvoiceToCancel('');
            setShowCancelConfirmModal(false);
        } catch (error) {
            setShowCancelConfirmModal(false);
        }
    };

    const handleEditInvoice = async (invoice) => {
        try {
            setInProp(false);
            setTimeout(() => {
                navigate(`/invoices/outgoing/${invoice.id}`, {
                    state: { invoice, searchTerm, page, rows, sortField, sortOrder },
                });
            }, 300);
        } catch (error) {
            console.error('Erro ao buscar nota fiscal atualizada', error);
            setAlert({ type: 'danger', message: 'Erro ao carregar a nota fiscal atualizada' });
        }
    };

    const handleDeleteInvoice = async () => {
        try {
            await axios.delete(`/api/invoices/outgoing/${invoiceToDelete.id}`);
            setAlert({ type: 'success', message: 'Nota fiscal excluída com sucesso' });
            setInvoiceToDelete('');
            setShowConfirmModal(false);
            fetchInvoices(page, rows, sortField, sortOrder, searchTerm);
        } catch (error) {
            console.error('Erro ao excluir nota fiscal', error);
            setShowConfirmModal(false);
        }
    };

    const confirmCancelInvoice = (invoice) => {
        setInvoiceToCancel(invoice);
        setShowCancelConfirmModal(true);
    };

    const confirmDeleteInvoice = (invoice) => {
        setInvoiceToDelete(invoice);
        setShowConfirmModal(true);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setInProp(true)}>
            <div className='container'>
                <h3 className="mb-3 mt-3">Notas Fiscais de Saída</h3>

                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div title={!havePermission? "Sem permissão": ""} style={{ display: "inline-block" }}>
                        <button className="btn btn-primary" onClick={() => navigate('/invoices/outgoing/new', { state: { searchTerm, page, rows, sortField, sortOrder } })} disabled={!havePermission}>
                            <span className="d-none d-md-inline">Nova Nota Fiscal</span>
                            <span className="d-inline d-md-none">Nova</span>
                        </button>
                    </div>

                    <div className="input-group search-size" style={{ maxWidth: '300px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                        />
                        <button className="btn btn-outline-secondary btn-search" onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>

                {selectedInvoices.length > 0 && (
                    <div className="d-flex justify-content-end mb-3">
                        <button
                            className="btn btn-success"
                            onClick={handleTransmitSelectedInvoices}
                        >
                            Transmitir Selecionadas
                        </button>
                    </div>
                )}

                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : invoices.length > 0 ? (
                    <>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className='d-none d-md-table-cell'>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    const allowedInvoices = invoices
                                                        .filter((inv) => allowedStatuses.includes(inv.status))
                                                        .map((inv) => inv.id);
                                                    setSelectedInvoices(allowedInvoices);
                                                } else {
                                                    setSelectedInvoices([]);
                                                }
                                            }}
                                            checked={
                                                invoices.every((inv) => 
                                                    selectedInvoices.includes(inv.id) || !allowedStatuses.includes(inv.status)
                                                ) &&
                                                invoices.some((inv) => allowedStatuses.includes(inv.status))
                                            }
                                            disabled={!havePermission}
                                        />
                                    </th>
                                    <th onClick={() => handleSort('docNumber')} className='sortable-column' style={{width: isMobile? "80px": ""}}>Número {getSortIcon('docNumber')}</th>
                                    <th onClick={() => handleSort('dhIssue')} className='sortable-column d-none d-lg-table-cell'>Data/Hora da Emissão {getSortIcon('dhIssue')}</th>
                                    <th onClick={() => handleSort('customer.legalName')} className='sortable-column'>Cliente {getSortIcon('customer.legalName')}</th>
                                    <th onClick={() => handleSort('totalInvoiceValue')} className='sortable-column d-none d-md-table-cell text-end me-20'>Valor R$ {getSortIcon('totalInvoiceValue')}</th>
                                    <th onClick={() => handleSort('status')} className='sortable-column d-none d-lg-table-cell text-end me-20'>Status {getSortIcon('status')}</th>
                                    <th className='text-end'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                            {invoices.map((invoice) => (
                                    <tr key={invoice.id}>
                                        <td className='d-none d-md-table-cell'>
                                            <input
                                                type="checkbox"
                                                checked={selectedInvoices.includes(invoice.id)}
                                                onChange={() => toggleSelectInvoice(invoice.id)}
                                                disabled={!havePermission || (invoice.status !== 'NAO_ENVIADA' && invoice.status !== 'PROCESSADA_COM_ERRO')}
                                            />
                                        </td>
                                        <td>{invoice.docNumber ? invoice.docNumber.toString().padStart(8, '0') : 'N/A'}</td>
                                        <td className="d-none d-lg-table-cell">{invoice.dhIssue ? `${new Date(invoice.dhIssue).toLocaleDateString('pt-BR')} ${new Date(invoice.dhIssue).toLocaleTimeString('pt-BR')}` : 'N/A'}</td>
                                        <td className="ellipsis">{invoice.customer.legalName.toUpperCase()}</td>
                                        <td className="d-none d-md-table-cell text-end me-20">{invoice.totalInvoiceValue ? invoice.totalInvoiceValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'}</td>
                                        <td className="d-none d-lg-table-cell text-end me-20">
                                            <span className={formatStatus(invoice.status).className} onClick={() => handleShowInfoErrorModal(invoice)}>
                                                {formatStatus(invoice.status).label}
                                                {!invoice.invoiceCancelProtocol?.id && invoice.invoiceProtocol && invoice.status === 'PROCESSADA_COM_ERRO' && (
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        className="ms-2 text-warning"
                                                        title="Ver detalhes"
                                                        onClick={() => handleShowInfoErrorModal(invoice)}
                                                    />
                                                )}
                                                {invoice.invoiceCancelProtocol && invoice.invoiceCancelProtocol.id && invoice.status !== 'CANCELADA' && (
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        className="ms-2 text-warning"
                                                        title="Ver detalhes"
                                                        onClick={() => handleShowInfoErrorModal(invoice)}
                                                    />
                                                )}
                                            </span>
                                        </td>
                                        <td className='text-end' style={{width: isMobile? "74px": "130px", whiteSpace: 'nowrap'}}>
                                            {isMobile && (
                                                    <span className={formatStatusMobile(invoice.status).className} onClick={() => handleShowInfoErrorModal(invoice)} style={{marginRight: '10px'}}>
                                                        {formatStatusMobile(invoice.status).label}
                                                    </span>
                                            )}
                                            <button className="btn btn-sm border-secondary custom-btn me-2" title={havePermission? "Editar": "Visualizar"} onClick={() => handleEditInvoice(invoice)} style={{display: isMobile? 'none': ''}}>
                                                <FontAwesomeIcon icon={havePermission? faPencil: faEye} />
                                            </button>
                                            <div title={!havePermission? "Sem permissão": ""} style={{ display: "inline-block" }}>
                                                <button className="btn btn-sm border-secondary custom-btn me-2" title="Excluir" onClick={() => confirmDeleteInvoice(invoice)} disabled={!havePermission} style={{display: isMobile? 'none': ''}}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                            <div className="dropdown d-inline">
                                                {havePermission && (
                                                    <>
                                                        <button className="btn btn-sm border-secondary custom-btn dropdown-toggle no-dropdown-icon" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <FontAwesomeIcon icon={faEllipsisV} />
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li style={{display: isMobile? '': 'none'}}>
                                                                <button className="dropdown-item" onClick={() => handleEditInvoice(invoice)}>{havePermission && invoice.status !== 'NFE_AUTORIZADA' && invoice.status !== 'CANCELADA'? 'Editar': 'Visualizar'}</button>
                                                            </li>
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => confirmCancelInvoice(invoice)} disabled={invoice.status !== 'NFE_AUTORIZADA' || !havePermission}>Cancelar Nota</button>
                                                            </li>
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => handleTransmitInvoice(invoice)} disabled={(invoice.status !== 'NAO_ENVIADA' && invoice.status !== 'PROCESSADA_COM_ERRO') || !havePermission}>Transmitir</button>
                                                            </li>
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => handleDownloadXML(invoice)} disabled={(invoice.status !== 'NFE_AUTORIZADA' && invoice.status !== 'CANCELADA') || !havePermission}>Download XML</button>
                                                            </li>
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => handleDanfe(invoice)} disabled={(invoice.status !== 'NFE_AUTORIZADA' && invoice.status !== 'CANCELADA') || !havePermission}>DANFE</button>
                                                            </li>
                                                            <li style={{display: isMobile? '': 'none'}}>
                                                                <button className="dropdown-item" onClick={() => confirmDeleteInvoice(invoice)} disabled={!havePermission}>Excluir</button>
                                                            </li>
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                        <Paginator
                            first={page * rows}
                            rows={rows}
                            totalRecords={totalRecords}
                            onPageChange={onPageChange}
                            pageLinkSize={isMobile ? 2 : 5}
                            {...(!isMobile && { rowsPerPageOptions: [10, 20, 50] })}
                        />
                    </>
                ) : (
                    <div className="no-data">
                        <p>Ainda não há dados por aqui, ou sua pesquisa não retornou resultados.</p>
                    </div>
                )}

                <ConfirmationModal
                    show={showConfirmModal}
                    onHide={() => setShowConfirmModal(false)}
                    onConfirm={handleDeleteInvoice}
                    title="Confirmar Exclusão"
                    message={`Tem certeza que deseja excluir a nota fiscal?`}
                />

                <CancelInvoiceConfirmation
                    show={showCancelConfirmModal}
                    onHide={() => setShowCancelConfirmModal(false)}
                    onConfirm={handleCancelInvoice}
                />

                <AlertModal
                    show={!!showInfoErrorModal}
                    onHide={() => setShowInfoErrorModal(null)}
                    title="Informações"
                    message={
                        showInfoErrorModal && (
                            <>
                                <p><strong>Status atual:</strong> {formatStatus(showInfoErrorModal.status).label}</p>
                                {(showInfoErrorModal.status !== 'NAO_ENVIADA' && showInfoErrorModal.status !== 'RECEBIDA') && (
                                    <>
                                        <br/>
                                        <p><strong><u>Emissão</u></strong></p>
                                        <p><strong>Código do Status:</strong> {showInfoErrorModal.statusCode}</p>
                                        <p><strong>Motivo:</strong> {showInfoErrorModal.statusReason}</p>
                                        {showInfoErrorModal.messageCode && (
                                            <p><strong>Código da Mensagem:</strong> {showInfoErrorModal.messageCode}</p>
                                        )}
                                        {showInfoErrorModal.messageDescription && (
                                            <p><strong>Descrição da Mensagem:</strong> {showInfoErrorModal.messageDescription}</p>
                                        )}
                                        {showInfoErrorModal.messageCode && (
                                            <p><strong>Código da Mensagem:</strong> {showInfoErrorModal.messageCode}</p>
                                        )}
                                        {showInfoErrorModal.cancelCode && (
                                            <>
                                                <br/>
                                                <p><strong><u>{showInfoErrorModal.status === 'CANCELADA'? 'Cancelamento': 'Tentativa de cancelamento'}</u></strong></p>
                                                <p><strong>Código:</strong> {showInfoErrorModal.cancelCode}</p>
                                                <p><strong>Mensagem:</strong> {showInfoErrorModal.cancelMessage}</p>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )
                    }
                />
            </div>
        </CSSTransition>
    );
}

export default InvoiceList;
