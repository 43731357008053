import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from './AxiosInstance';
import { AuthContext } from './AuthContext';
import '../assets/styles/App.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import loginBackground from '../assets/images/register.webp';
import logo from '../assets/images/logo-white.webp';

function NewPassword() {
  const [successMessage, setSuccessMessage] = useState('');
  const [email] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userConfirmPassword, setUserConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { auth, setAuth, axiosConfigured } = useContext(AuthContext);
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');


  useEffect(() => {
    if (axiosConfigured && !hasFetched.current) {
      hasFetched.current = true;
    }
  }, [axiosConfigured, hasFetched, auth.token]);

  useEffect(() => {

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setLoading(true);

    try {
        await axios.post('/api/users/reset-password', { email, userPassword, token });
        setSuccessMessage('Senha redefinida com sucesso! Você será redirecionado para o login.');
        // Redireciona para o login
        setTimeout(() => {
            navigate('/login');
        }, 3000);
    } catch (error) {
        setAuth((prevAuth) => ({ ...prevAuth, expired: false }));
        if (error.response) {
            if (error.response.status === 400 && error.response.headers['x-error-message']) {
                setErrorMessage(error.response.headers['x-error-message']);
            } else if (error.response.status === 500) {
                setErrorMessage('Erro interno do servidor. Por favor, tente novamente mais tarde.');
            } else if (error.response.status === 503) {
                setErrorMessage('Serviço indisponível. Por favor, tente novamente mais tarde.');
            } else {
                setErrorMessage('Falha ao redefinir senha. Verifique os dados e tente novamente.');
            }
        } else if (error.request) {
            setErrorMessage('Serviço indisponível. Por favor, tente novamente mais tarde.');
        } else {
            setErrorMessage('Erro desconhecido. Por favor, tente novamente.');
        }
    } finally {
        setLoading(false);
    }
  };

  const isResetDisabled = !userPassword || !userConfirmPassword || userPassword !== userConfirmPassword;

  return (
    <section className="vh-100">
      <div className="container-login py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: '1rem', height: '100%' }}>
              <div className="row g-0 h-100">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src={loginBackground}
                    alt="login form"
                    className="img-fluid h-100"
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    {loading ? (
                      <div className="spinner-container">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="centered-logo-container">
                            <img
                              src={logo}
                              alt="Simpletize"
                              className="img-fluid"
                              style={{ width: "44px", height: "auto" }}
                            />Simpletize
                        </div>

                        <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: '1px' }}>Redefina sua senha</h5>

                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}

                        <div className="form-outline mb-3">
                            <label className="form-label" htmlFor="password">Senha <span className="text-danger">*</span></label>
                            <input
                            type="password"
                            id="userPassword"
                            className="form-control form-control-lg"
                            value={userPassword}
                            onChange={(e) => setUserPassword(e.target.value)}
                            required
                            maxLength={20}
                            autoComplete="new-password"
                            />
                        </div>

                        <div className="form-outline mb-3">
                            <label className="form-label" htmlFor="confirmPassword">Confirme sua Senha <span className="text-danger">*</span></label>
                            <input
                            type="password"
                            id="userConfirmPassword"
                            className="form-control form-control-lg"
                            value={userConfirmPassword}
                            onChange={(e) => setUserConfirmPassword(e.target.value)}
                            required
                            maxLength={20}
                            autoComplete="new-password"
                            />
                        </div>

                        <div className="pt-1 mb-4">
                          <button id="btnLogin" className="btn btn-dark btn-lg btn-block" type="submit" disabled={isResetDisabled}>Redefinir senha</button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewPassword;
