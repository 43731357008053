import React, {useContext} from 'react';
import {AuthContext} from './AuthContext';
import { Link } from 'react-router-dom';
import '../assets/styles/CompanyName.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange } from '@fortawesome/free-solid-svg-icons';

function CompanyName() {
    const { auth } = useContext(AuthContext);

    return (
        <div className="company-name">
        {auth.companyName}
        <Link to="/company/selection" className="company-link" title='Selecionar outra conta'>
            <FontAwesomeIcon icon={faExchange} className='ms-3' />
        </Link>
        </div>
    );
}

export default CompanyName;
