// Navbar.js

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faCircleUser, faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/logo-transparent.webp';
import '../assets/styles/NavBar.css';

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    // Limpa o listener quando o componente for desmontado
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

function Navbar() {
  const { auth, logout, hasRequiredRoleEstoque, hasRequiredRoleDfe } = useContext(AuthContext);
  const havePermissionManager = hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_MANAGER') || hasRequiredRoleEstoque(auth.roles, 'ROLE_ESTOQUE_MANAGER');
  const havePermissionAdmin = hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_ADMIN') || hasRequiredRoleEstoque(auth.roles, 'ROLE_ESTOQUE_ADMIN');
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const navbarRef = useRef(null);
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login' || location.pathname === '/recovery' || location.pathname === '/reset-password' || location.pathname === '/register';
  const isCompanySelectionRoute = location.pathname === '/company/selection';
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 992;

  const [openMenu, setOpenMenu] = useState(null);

  const toggleMenu = () => setShowMenu(!showMenu);

  const handleLogout = () => {
    logout(true, false);
    window.location.href = '/login';
  };

  const handleMenuItemClick = () => {
    setOpenMenu(null);
    setShowMenu(false);
  };

  const handleNavigation = (route) => {
    setOpenMenu(null);
    setShowMenu(false);
    navigate(route);
  };

  // Extrair o primeiro nome do usuário
  const getFirstName = (fullName) => fullName?.split(' ')[0];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        openMenu
      ) {
        setOpenMenu(null); // Fecha o menu ao clicar fora
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openMenu]);

  return (
    <nav className="navbar navbar-expand-lg navbar-custom" ref={navbarRef}>
      <div className="container-fluid">
        {isCompanySelectionRoute || isMobile ? (
          <span className="navbar-brand disabled">
            <img src={logo} alt="Simpletize" width="35" height="auto" className="d-inline-block align-top" />
            Simpletize
          </span>
        ) : (
          <Link className="navbar-brand" to="/home">
            <img src={logo} alt="Simpletize" width="35" height="auto" className="d-inline-block align-top" />
            Simpletize
          </Link>
        )}
        {!isLoginRoute && !isCompanySelectionRoute && (
          <>
            <button className="navbar-toggler" type="button" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <div className={`collapse navbar-collapse ${showMenu ? 'show' : ''}`} id="navbarSupportedContent">
              <ul className="navbar-nav ms-3">
                <li className="nav-item">
                  <Link className="nav-link" to="/home" onClick={handleMenuItemClick}>Dashboard</Link>
                </li>
                <li className="nav-item dropdown">
                  <div
                    className={`nav-link dropdown-toggle ${openMenu === 'documents' ? 'show' : ''}`}
                    role="button"
                    onClick={() => setOpenMenu(openMenu === 'documents' ? null : 'documents')}
                    style={{ position: 'relative', '::after': { content: 'none' } }}
                  >
                    Documentos Fiscais
                    <FontAwesomeIcon icon={faChevronDown} className="ms-1" style={{ fontSize: '65%' }} />
                  </div>
                  {openMenu === 'documents' && (
                    <div className="dropdown-menu dropdown-menu-simpletize-dark show">
                      <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/invoices/incoming')}>Notas de Entrada</button>
                      <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/invoices/outgoing')}>Notas de Saída</button>
                    </div>
                  )}
                </li>
                {/* EXEMPLO COM SUBMENU
                <li className="nav-item dropdown">
                  <div
                    className={`nav-link dropdown-toggle ${openMenu === 'cadastros' ? 'show' : ''}`}
                    role="button"
                    onClick={() => setOpenMenu(openMenu === 'cadastros' ? null : 'cadastros')}
                  >
                    Cadastros
                    <FontAwesomeIcon icon={faChevronDown} className="ms-1" style={{ fontSize: '65%' }} />
                  </div>
                  {openMenu === 'cadastros' && (
                    <div className="dropdown-menu dropdown-menu-simpletize-dark show">
                      <div className="dropdown-submenu">
                        <Link className="dropdown-item" to="/customers" onClick={handleMenuItemClick}>
                          Clientes
                          <FontAwesomeIcon icon={faChevronRight} className="ms-1 text-end" style={{ fontSize: '65%' }} />
                        </Link>
                        <div className="dropdown-submenu-content">
                          <Link className="dropdown-item" to="/customers/new" onClick={handleMenuItemClick}>Novo Cliente</Link>
                          <Link className="dropdown-item" to="/customers/groups" onClick={handleMenuItemClick}>Grupos de Clientes</Link>
                        </div>
                      </div>
                      <Link className="dropdown-item" to="/suppliers" onClick={handleMenuItemClick}>Fornecedores</Link>
                      <Link className="dropdown-item" to="/tax-rules" onClick={handleMenuItemClick}>Regras Fiscais</Link>
                      <Link className="dropdown-item" to="/items/groups" onClick={handleMenuItemClick}>Grupos de Itens</Link>
                      <Link className="dropdown-item" to="/sales-category" onClick={handleMenuItemClick}>Categorias de Vendas</Link>
                      <Link className="dropdown-item" to="/items" onClick={handleMenuItemClick}>Itens</Link>
                      <Link className="dropdown-item" to="/unit-measurements" onClick={handleMenuItemClick}>Unidades de Medida</Link>
                      <Link className="dropdown-item" to="/carriers" onClick={handleMenuItemClick}>Transportadoras</Link>
                    </div>
                  )}
                </li>*/}
                <li className="nav-item dropdown">
                  <div
                    className={`nav-link dropdown-toggle ${openMenu === 'cadastros' ? 'show' : ''}`}
                    role="button"
                    onClick={() => setOpenMenu(openMenu === 'cadastros' ? null : 'cadastros')}
                  >
                    Cadastros
                    <FontAwesomeIcon icon={faChevronDown} className="ms-1" style={{ fontSize: '65%' }} />
                  </div>
                  {openMenu === 'cadastros' && (
                    <div className="dropdown-menu dropdown-menu-simpletize-dark show">
                      <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/customers')}>Clientes</button>
                      <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/suppliers')}>Fornecedores</button>
                      {havePermissionManager && (
                        <>
                          <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/tax-rules')}>Regras Fiscais</button>
                        </>
                      )}
                      <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/items/groups')}>Grupos de Itens</button>
                      <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/sales-category')}>Categorias de Vendas</button>
                      <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/items')}>Itens</button>
                      {havePermissionManager && (
                        <>
                          <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/unit-measurements')}>Unidades de Medida</button>
                        </>
                      )}
                      <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/carriers')}>Transportadoras</button>
                    </div>
                  )}
                </li>
                <li className="nav-item dropdown">
                  {havePermissionAdmin && (
                    <>
                      <div
                        className={`nav-link dropdown-toggle ${openMenu === 'admin' ? 'show' : ''}`}
                        role="button"
                        onClick={() => setOpenMenu(openMenu === 'admin' ? null : 'admin')}
                      >
                        Administração
                        <FontAwesomeIcon icon={faChevronDown} className="ms-1" style={{ fontSize: '65%' }} />
                      </div>
                      {openMenu === 'admin' && (
                        <div className="dropdown-menu dropdown-menu-simpletize-dark show">
                          <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/company-settings')}>Conta/Loja</button>
                          <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/users')}>Usuários</button>
                        </div>
                      )}
                    </>
                  )}
                </li>
              </ul>
              <ul className="navbar-nav ms-auto">
                <li className="nav-item dropdown">
                  <div
                    className={`nav-link dropdown-toggle ${openMenu === 'user' ? 'show' : ''}`}
                    role="button"
                    onClick={() => setOpenMenu(openMenu === 'user' ? null : 'user')}
                  >
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      size={isMobile? "1x": "2x"}
                      className="user-icon ms-3 me-2"
                      style={{ cursor: 'pointer' }}
                    />
                    {isMobile && (
                      <>
                        {getFirstName(auth.userName)}
                        <FontAwesomeIcon icon={faChevronDown} className="ms-1" style={{ fontSize: '65%' }} />
                      </>
                    )}
                    {/*{getFirstName(auth.userName)}
                    <FontAwesomeIcon icon={faChevronDown} className="ms-1" style={{ fontSize: '65%' }} />*/}
                  </div>
                  {openMenu === 'user' && (
                    <div
                      className="dropdown-menu dropdown-menu-end dropdown-menu dropdown-menu-simpletize-dark show"
                      style={{ right: '0', left: 'auto' }} // Garantir alinhamento correto
                    >
                      <span style={{marginLeft: '10px', color: "#FFFFFF"}}><strong>{getFirstName(auth.userName)}</strong></span>
                      <button type="button" className="btn btn-link-menu-dark mt-2" onClick={() => handleNavigation('/profile')}>Configuração do Usuário</button>
                      {havePermissionManager && (
                        <button type="button" className="btn btn-link-menu-dark" onClick={() => handleNavigation('/billings')}>Pagamentos e Faturas</button>
                      )}
                      <button type="button" className="btn btn-link-menu-dark" onClick={() => handleLogout()}>
                        <FontAwesomeIcon icon={faSignOutAlt} style={{ cursor: 'pointer', color: '#fff !important' }} /> Sair
                      </button>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;

